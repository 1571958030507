/*
 * Localstorage names
 */
export const ID_TOKEN = 'id_token'
export const USER_DATA = 'user_data'
export const TOKEN_EXP = 'token_exp'

/**
 * Keys
 */
// export const RECAPTCHA = '6Lf8YcIZAAAAAHeorgWYmCXoqPmZp6DHxZyPqipd'// v3 - not works
// export const RECAPTCHA = '6Lf4D-QZAAAAAPpy1XUlJ4rWPcOZa9j2m0rlrubu'// v2 - works
//export const RECAPTCHA = '6LfUMOoZAAAAAGRvri_ImPsRTmIt5WcMnhMj27yP' // v2 invisible

/**
 * Intervals
 */
export const GET_BALANCE_INTERVAL = 1400
export const GET_MENU_INTERVAL = 30000
export const GET_BALANCE_SETTINGS_INTERVAL = 60000
export const GET_MESSAGE_INTERVAL = 120000
export const GET_INPLAY_POPULAR_INTERVAL = 1200
export const GET_STATS_INTERVAL = 1200
export const GET_MARKET_DATA_INTERVAL = 1200
// export const KEEP_ALIVE_POPUP = 3300000 // captcha - 55 min
// export const KEEP_ALIVE_POPUP = 3590000 // captcha - 59 min
export const KEEP_ALIVE_POPUP = 900000 //captcha - 15 min

/**
 * pages showing the go-back button
 */
export const goBackPages = [
  '/rules_regs',
  '/time_settings',
  '/settings',
  '/change_password',
  '/search',
  '/responsible_gambling',
  '/exclusion_policy',
  '/competition',
  '/betting_profit_and_loss',
  '/betting_profit_and_loss_details',
  '/account_statement',
  '/account_statement_details',
  '/transfer_statement',
  '/event-page',
  '/virtual-event-page',
  '/kyc',
  '/my-open-bets',
  '/rules_page',
  '/scorecard',
  '/transactions',
  '/detailedDepositTransaction',
  '/withdrawal',
  '/deposit',
  '/exchange-game-page',
  '/profile',
  
  // '/default-page',
]



const statusMapping ={
  "Initiated": "Initiated \n (Confirmation of payment has not received yet, or If Transaction is not successful, You will receive refund from bank within 24-48 hrs.)",
  "Exported": "In Progress (Your transfer has been initiated, but your bank may take 24 hrs to accept the deposit.)",
  "Complete": "Transfer successful (Transfer confirmation has been received from bank, Your bank may take 24 hrs to deposit the amount.)",
  "Pending": "Pending (Confirmation of payment has not received from bank yet)"
}

export const getCustomStatus = (status) =>{

  try {
    console.log(status);
    let customStatus = statusMapping[status];
    console.log(customStatus);

    if(customStatus) return customStatus;
    else return status;
  }
  catch (e) {
    return status;
  }

}
