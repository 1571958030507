export const StaticStakes = [
    {
       "label1":"1K",
       "price1":1000
    },
    {
       "label2":"5K",
       "price2":5000
    },
    {
       "label3":"10K",
       "price3":10000
    },
    {
       "label4":"25K",
       "price4":25000
    },
    {
       "label5":"50K",
       "price5":50000
    },
    {
       "label6":"100K",
       "price6":100000
    },
    {
       "label7":"200K",
       "price7":200000
    },
    {
       "label8":"500K",
       "price8":500000
    },
    {
       "label9":"1M",
       "price9":1000000
    },
    {
       "label10":"2M",
       "price10":2000000
    }
]