import React from 'react';

const HeaderTabs = ({ selectedIndex, setSelectedIndex}) => {
    return (
        <ul role="tablist" classNameName="nav nav-pills" aria-label="Tabs">
            <li className="nav-item">
                <a 
                    href="javascript:void(0);" 
                    role="tab" 
                    className={`nav-link ${selectedIndex === 0? 'selected' : ''}`} 
                    aria-controls="" 
                    aria-selected="false" 
                    id=""
                    onClick={() => {setSelectedIndex(0)}}
                >
                    <span></span><span id={`parentTab-0`}>LIVE CASINO</span>
                </a>
            </li>
            <li className="nav-item">
                <a 
                    href="javascript:void(0);" 
                    role="tab" 
                    className={`nav-link ${selectedIndex === 1? 'selected' : ''}`} 
                    aria-controls="" 
                    aria-selected="false" 
                    id=""
                    onClick={() => {setSelectedIndex(1)}}
                >
                    <span></span><span id={`parentTab-1`}>VIRTUAL CASINO</span>
                </a>
            </li>
        </ul>
    )
}

export default HeaderTabs;