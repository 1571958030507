import { createSlice } from '@reduxjs/toolkit'

export const sportEventSlice = createSlice({
    name: 'sportEvent',
    initialState: {
        sportData: null,
        sportStats: null,
        sportLadder: {},
        sportVideo: null,         //iframe
        sportScore: null,         //iframe
        sportVisualisation: null,
        ladderTitle: null,
        ladderId: null,
        ladderShow: null,
        sportDataMarkets: null,
    },
    reducers: {
        getSportEventSuccess: (state, action) => ({ ...state, sportData: action.payload }),
        getSportEventError: (state) => ({ ...state, sportData: null }),
        getSportEventMarketsSuccess: (state, action) => ({ ...state, sportDataMarkets: action.payload }),
        getSportEventMarketsError: (state) => ({ ...state, sportDataMarkets: null }),
        getSportStatsSuccess: (state, action) => ({ ...state, sportStats: action.payload }),
        getSportStatsError: (state) => ({ ...state, sportStats: null }),
        getSportLadderSuccess: (state, action) => ({ ...state, sportLadder: action.payload }),
        getSportLadderError: (state) => ({ ...state, sportLadder: {} }),
        setSportLadderTitle: (state, action) => ({ ...state, ladderTitle: action.payload }),
        setSportLadderId: (state, action) => ({ ...state, ladderId: action.payload }),
        setSportLadderShow: (state, action) => ({ ...state, ladderShow: action.payload }),
        getSportVideoSuccess: (state, action) => ({ ...state, sportVideo: action.payload }),
        getSportVideoError: (state, action) => ({ ...state, sportVideo: null }),
        getSportScoreSuccess: (state, action) => ({ ...state, sportScore: action.payload }),
        getSportScoreError: (state, action) => ({ ...state, sportScore: null }),
        getSportVisualisationSuccess: (state, action) => ({ ...state, sportVisualisation: action.payload }),
        getSportVisualisationError: (state, action) => ({ ...state, sportVisualisation: null }),
    },
})

export const {
    getSportEventSuccess,
    getSportEventError,
    getSportStatsSuccess,
    getSportStatsError,
    getSportLadderSuccess,
    getSportLadderError,
    setSportLadderTitle,
    setSportLadderId,
    setSportLadderShow,
    getSportVideoError,
    getSportVideoSuccess,
    getSportScoreSuccess,
    getSportScoreError,
    getSportVisualisationError,
    getSportVisualisationSuccess,
    getSportEventMarketsError,
    getSportEventMarketsSuccess,
} = sportEventSlice.actions

export default sportEventSlice.reducer
