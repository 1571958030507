import "./BettingProfitAndLossItem.scss";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import { connectBettingProfitAndLoseItem } from "./BettingProfitAndLossItem.connect";
import { getCurrencyFormat } from "../../helpers/getCurrencyFormat.helper";
import moment from "moment";

const BettingProfitAndLossItem = ({data, dateFrom, dateTo, user, actions, setSelectedItemDetails, elementsParams}) => {

    //get window with
    const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth
    //end get window with

    const history = useHistory();
    // const [title, setTitle] = useState("");
    // const [startTime, setStartTime] = useState(new Date().toDateString());
    // const [settledTime, setSettledTime] = useState(new Date().toDateString());
    // const [comm, setComm] = useState(0);
    // const [netWin, setNetWin] = useState(0);

    // const fetchPNLData = (providerId) => {
    //     switch (providerId) {
    //     case 1: {
    //         user && actions
    //                 .requestOrderListSuccess(user.memberCode, "settled", data?.marketId)
    //                 .then(() => goToDetailsPage())
    //                 .catch((e) => console.log("requestOrderListError = ", e));

    //         break;
    //     }

    //     case 7: {
    //         actions
    //                 .requestBetHistorySuccess(data?.marketId)
    //                 .then(() => goToDetailsPage())
    //                 .catch((e) => console.log("requestBetHistoryError = ", e));

    //         break;
    //     }
    //     }
    // };

    const selectPNLItem = (eventTypeId) => {
        const data = {
            from: moment(dateFrom).format('YYYY-MM-DD'),
            to: moment(dateTo).format('YYYY-MM-DD'),
            eventTypeId: eventTypeId,
        }

        // console.log("Required DATA: ", data);
        actions.requestMarketPL(data)
        .then(() => goToDetailsPage())
        .catch((e) => console.log("requestMarketPLError = ", e));


        // fetchPNLData(data);
    };

    const goToDetailsPage = () => {
        if (width < 1024) {
            history.push({
                pathname: "/betting_profit_and_loss_details",
                state: {itemData: data},
            });
        }
        else {
           setSelectedItemDetails(data);
        }

    };

    const getValueColor = (value) => {
        return  value >= 0 ? "pnl-item-value--green" : "pnl-item-value--red";
    };

    const parseData = (data) => {
        // const eventName = data.eventName;
        const eventName = data?.event;
        // const marketName = data.marketName;
        const marketName = data?.eventType;
        // const settledTime = data.settledTime;
        const settledTime = data.date;
        // const marketTime = data.marketTime;
        const marketTime = data.date;
        // const memberComm = data.memberComm;
        const memberComm = data?.amount;
        // const memberWin = data.memberWin;
        // const memberWin = data.pnl;
        const memberWin = data?.netWin;
        // setTitle(`${eventName} - ${marketName}`);
        // setStartTime(new Intl.DateTimeFormat("en-GB", {dateStyle: "short", timeStyle: "medium"}).format(new Date(marketTime)));
        // setSettledTime(new Intl.DateTimeFormat("en-GB", {dateStyle: "short", timeStyle: "medium"}).format(new Date(settledTime)));
        // setStartTime(new Intl.DateTimeFormat("en-GB", {dateStyle: "short", timeStyle: "medium"}).format(new Date()));
        // setSettledTime(new Intl.DateTimeFormat("en-GB", {dateStyle: "short", timeStyle: "medium"}).format(new Date()));
        // setComm(memberComm);
        // setNetWin(memberWin);
    };


    const getMarketName = (name) => {
        let market = '';
        switch (name) {
            case '4':
                market = 'Cricket';
                break;
            case '2':
                market = 'Tennis';
                break;
            case '1':
                market = 'Football';
                break;
            case 'c1':
                market = 'Casino [Aura]';
                break;
            case 'c9':
                market = 'Casino [QTech]';
                break;
            case 'v9':
                market = 'Virtual';
                break;
            case 'b9':
                market = 'Ball By Ball';
                break;
            default:
                market = '';
                break;
        }
        return market;
    }

    useEffect(() => {
        parseData(data);
    }, [data]);

    if (width < 1024) {
        return (
          <div className="betting-pnl-item">
              <div className="betting-pnl-item__left-side">
                <span className="betting-pnl-item__title" onClick={() => selectPNLItem(data?.eventTypeId)}>
                    {getMarketName(data?.eventTypeId)}
                </span>
                {/* <span className="start-time">
                    <span className="start-time__title">
                        Start Time:
                    </span>
                    <span className="start-time__value">
                        {startTime}
                    </span>
                </span>
                  <span className="settled-time">
                    <span className="settled-time__title">
                        Settled Time:
                    </span>
                    <span className="settled-time__value">
                        {settledTime}
                    </span>
                </span> */}
              </div>
              <div className="betting-pnl-item__right-side">
                <span className="comm">
                    <span className="comm__title">
                        Comm:
                    </span>
                    <span className={`comm__value ${getValueColor(data?.commision)}`}>
                        {getCurrencyFormat(data?.commision)}
                    </span>
                </span>
                  <span className="net-win">
                    <span className="net-win__title">
                        Net Win:
                    </span>
                    <span className={`net-win__value ${getValueColor(data?.totalpl)}`}>
                        {getCurrencyFormat(data?.totalpl)}
                    </span>
                </span>
              </div>
          </div>
        )
    }

    return (
        <ul className="betting-pnl-item">
            <li>
                <span className="betting-pnl-item__cell__value betting-pnl-item__title" onClick={() => selectPNLItem(data?.eventTypeId)}>{getMarketName(data?.eventTypeId)}</span>
            </li>
            {/* <li>
                <span className="betting-pnl-item__cell__value start-time__value">{startTime}</span>
            </li>
            <li>
                <span className="betting-pnl-item__cell__value settled-time__value">{settledTime}</span>
            </li> */}
            <li>
                <span className={`betting-pnl-item__cell__value comm__value ${getValueColor(data?.commision)}`}>{(data?.commision).toFixed(2)}</span>
            </li>
            <li> 
                <span className={`betting-pnl-item__cell__value net-win__value ${getValueColor(data?.totalpl)}`}>{getCurrencyFormat(data?.totalpl)}</span>
            </li>
        </ul>
    );
};

export default connectBettingProfitAndLoseItem()(BettingProfitAndLossItem);
