import NewHttpAuth from '../../services/NewHttpAuth';
import { getStakesSuccess, getStakesError } from './stakes.slice';
import { StaticStakes } from '../../constants/StaticStakes';
import { toastr } from 'react-redux-toastr'



export const requestStakeButtons = () => async (dispatch, getState) => {
    const checkAuth = getState().auth

    try {
        const response = await NewHttpAuth.post('getStackButton', null);
        if(response.status == 200 && response?.data?.success) {
            dispatch(getStakesSuccess(response?.data?.data?.priceArray))
        }else{
            dispatch(getStakesSuccess(StaticStakes))
        }
        
    }catch (e) {
        dispatch(getStakesSuccess(StaticStakes))
        //   dispatch(getStakesError())
    }
}


export const requestUpdateStakeButtons = (data) => async (dispatch, getState) => {
    const auth = getState().auth

    try {
        if(auth) {
            const response = await NewHttpAuth.post('updateButton', data);
            
            if(response.status == 200) {
                if(response?.data?.success) {
                    dispatch(getStakesSuccess(response?.data?.data))
                    toastr.success('', response.data.message);
                }else{
                    toastr.error('', response.data.message);
                }
            }

        }else{
            toastr.error('', 'Please Login In to Change Stakes');
        }
    }catch(e) {
        toastr.success('', e?.message);
        // return e;
    }


    try {
        const response = await NewHttpAuth.post('getStackButton', null);
        if(response.status == 200 && response?.data?.success) {
            dispatch(getStakesSuccess(response?.data?.data?.priceArray))
        }else{
            dispatch(getStakesSuccess(StaticStakes))
        }
        
    }catch (e) {
        dispatch(getStakesSuccess(StaticStakes))
        //   dispatch(getStakesError())
    }
}