import React, { useEffect, useMemo, useRef, useState } from 'react'
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/fixed";
import {
  useIonViewWillEnter,
  IonContent,
  IonHeader,
  IonLabel,
  IonPage,
  IonImg,
} from '@ionic/react'
import './EventPage.scss'
import { connectEventPage } from './eventpage.connect'
import Toolbar from '../../components/Toolbar/Toolbar'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Timeline } from 'react-twitter-widgets'
import RaceListEventDetails from '../../components/RaceListEventDetails'
import SportEvent from '../../components/SportEvent/SportEvent'
import LiveSection from '../../components/LiveSection/LiveSection'
import OpenBets from '../../components/OpenBets/OpenBets'
import { RaceEventDropdownList } from '../../components/RaceEventDropdown'
import LoaderContent from '../../components/LoaderContent/LoaderContent'
import {
  GET_STATS_INTERVAL,
  GET_MARKET_DATA_INTERVAL,
} from '../../config/app.config'
import broadcastImg from '../../assets/images/inplay-logo-iconSport.svg'
import httpAuth from '../../services/httpAuth'
import httpNotAuth from '../../services/httpNotAuth'
import UpcomingRacesAccordion from '../../components/UpcomingRacesAccordion/UpcomingRacesAccordion'
import { formatTime } from '../../helpers/date.helper'
// import { EventPageData } from '../../constants/EventPageData';
import { Socket } from '../../services/socket';

const EventPage = ({
  actions,
  lastActivityDate,
  twitterSettings,
  sportEventStats,
  eventOpenBets,
  orders,
  sportData,
  sportVideo,
  sportScore,
  rulesPopUp,
  auth,
  user,
  elementsParams
}) => {

  const history = useHistory()

  const { eventTypeId, eventId } = useParams()
  const [activeTab, setActiveTab] = useState(0)
  const [contentScrolled, setContentScrolled] = useState(false)
  const [style, setStyle] = useState({})
  const [racingEventHeader, setRacingEventHeader] = useState(null)
  const [isAccordionOpen, setIsAccordionOpen] = useState(1)
  const [upcomingRaces, setUpcomingRaces] = useState(null)
  const scrollRef = useRef(null);
  // const [sportData, setSportData]  = useState([]); //only for testing.. data coming from above SportData.. 
  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth


  useEffect(() => {
    Socket.on(`event-pulse-${eventId}`, handleEventPulse);
    if(width<1024 || true) Socket.on(`get-bets-success`, getBetsSuccess);
    Socket.on("get-runner-profit-success", getRunnerProfit)
    Socket.on("disconnect", disconnectEmit);
    
    return() => {
      Socket.off(`event-pulse-${eventId}`, handleEventPulse);
      if(width<1024 || true) Socket.off(`get-bets-success`, getBetsSuccess);
      Socket.off("get-runner-profit-success", getRunnerProfit);
      Socket.off("disconnect", disconnectEmit);
      removeFromRoom();
      actions.getSportEventError();
      actions.getSportVideoError();
      actions.getSportScoreError();
      actions.getEventPageOpenBetsError();
      actions.getSportLadderError();
    }
  }, []);


  useEffect(() => {
    if(!user?.verifytoken) return;

    const data = {
      token: user?.verifytoken,
      eventId: eventId,
    };
    Socket.emit('add-to-room', data);

    if(width<1024 || true) {
      const betData = {
        token: user?.verifytoken,
        filter: {
          eventId: eventId,
          username: user?.details?.username,
          deleted: false,
          result: "ACTIVE",
        },
        sort: { placedTime: -1 },
      }
      Socket.emit("get-bets", betData);
    }
  }, []);

  const handleEventPulse = (...args) => {
    // console.log("Listen: ", args[0]);
    const data = args[0];
    
    const sortedData = data.sort((a, b) => {
      if (a.marketName === "Match Odds") return -1;
      if (b.marketName === "Match Odds") return 1;
      return 0;
    })
    actions.getSportEventSuccess(sortedData);
  }

  const getBetsSuccess = (...args) => {
    // console.log("Bet Success: ", args[0]);
    let allBets = args[0].filter((item) => {
      return item.eventId == eventId;
    });
    actions.getEventPageOpenBetsSuccess(allBets);
  }


  const removeFromRoom = () => {
    if(user?.verifytoken) {
      const data = {
        token: user?.verifytoken,
        eventId: eventId,
      }

      Socket.emit('remove-from-room', data);
    }
  }

  const disconnectEmit = () => {
    if(!user?.verifytoken) return;

    const data = {
      token: user?.verifytoken,
      eventId: eventId,
    };
    Socket.emit('add-to-room', data);
  }

  useEffect(() => {
    if(sportData && (!sportVideo || !sportScore)) {
      const matchOddsData = sportData.find(
        (item) => item[0]?.marketType === "MATCH_ODDS"
      );

      if (matchOddsData) {
        actions.getSportVideoSuccess(matchOddsData[0]?.url);
        actions.getSportScoreSuccess(matchOddsData[0]?.score);
      }
    }
  }, [sportData, sportVideo, sportScore])

  useEffect(() => {
    if(scrollRef && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [])

  const onScroll = (e) => {
    if (e.detail.deltaY < 0 || e.detail.scrollTop <= 120) {
      if (contentScrolled) setContentScrolled(false)
    } else if (e.detail.deltaY > 0) {
      if (!contentScrolled && e.detail.scrollTop > 120) setContentScrolled(true)
    }
  }

  const getRunnerProfit = (...args) => {
    // console.log("Runner Profit: ", args);
    actions.getSportLadderSuccess(args[0]);
  };
  
  const isRacing = eventTypeId == 7 || eventTypeId == 4339
  // const isLoaded = isRacing
  //   ? true
  //   : ((sportData?.[0]?.event?.id === eventId &&
  //       sportData?.[0]?.eventTypeId === eventTypeId) ||
  //       sportData?.[0]?.groupById === eventId) &&
  //     sportEventStats?.eventId === eventId &&
  //     sportEventStats?.eventTypeId === eventTypeId
  const isLoaded = isRacing
    ? true
    : true

  const sportTabs = [
    {
      label: 'market',
      setTab: () => setActiveTab(0),
    },
    {
      label: 'news',
      setTab: () => setActiveTab(1),
    },
    // sportEventStats &&
    // (sportEventStats?.hasVideo || sportEventStats?.showVisualisation)
    (sportVideo || sportScore)
      ? {
          label: 'live',
          icon: <span className="LiveSection__tv-icon"></span>,
          setTab: () => setActiveTab(2),
        }
      : undefined,
    {
      // label: `open bets (${(orders && orders.count) || '0'})`,
      label: `open bets (${(eventOpenBets && eventOpenBets?.length) || '0'})`,
      setTab: () => setActiveTab(3),
    },
  ]

  const raceTabs = [
    {
      label: 'market',
      setTab: () => setActiveTab(0),
    },
    {
      label: 'news',
      setTab: () => setActiveTab(1),
    },
    undefined,
    {
      label: `open bets (${(orders && orders.count) || '0'})`,
      setTab: () => setActiveTab(3),
    },
  ]

  useEffect(() => {
      if (
        history.location.pathname.indexOf(
          `/event-page/${eventTypeId}/${eventId}`
        ) !== -1
      ){
        // actions.requestSportStart(eventId)    //Chalani hai
      }
      if (
        history.location.pathname.indexOf(
          `/event-page/${eventTypeId}/${eventId}`
        ) !== -1 &&
        activeTab == 3
      ){
        // actions.requestOpenBets(eventId)      //Chalani hai (maybe)
      }
    }, [
    actions,
    activeTab,
    eventId,
    eventTypeId,
    history.location.pathname,
    lastActivityDate,
  ])

  useEffect(() => {
    if (
      history.location.pathname.indexOf(
        `/event-page/${eventTypeId}/${eventId}`
      ) !== -1 && auth
    ) {
      setActiveTab(2)
    } else {
      if (sportEventStats?.scorecard || sportEventStats?.isBRScore) {
        setActiveTab(2)
      } else {
        setActiveTab(2)
      }
    }
  }, [eventTypeId, eventId, history.location.pathname, auth])

  useEffect(() => {
    if (
      (isRacing ||
        (sportEventStats &&
          !sportEventStats.hasVideo &&
          !sportEventStats.showVisualisation)) &&
      activeTab === 2
    )
      setActiveTab(0)
  }, [sportEventStats, activeTab, eventTypeId, isRacing])

  useEffect(() => {
    if (activeTab == 1) actions.requestTwitterSettings()
    if (activeTab == 3) actions.requestOpenBets(eventId)
  }, [actions, activeTab, eventId])

  /**
   * sport event
   */
  useEffect(() => {
    // sportData = undefined;
    // actions.requestSportData(eventTypeId, eventId).then();  //Chalani hai (maybe)
    const mainTimer = setIntervalAsync(async () => {
      if (
        history.location.pathname.indexOf(
          `/event-page/${eventTypeId}/${eventId}`
        ) !== -1
      ) {
        if (!isRacing) {
          // await actions.requestSportData(eventTypeId, eventId)  //Chalani hai (maybe)
        }
      }
    }, GET_MARKET_DATA_INTERVAL)

    return () => clearIntervalAsync(mainTimer)
  }, [eventTypeId, eventId, history.location.pathname, isRacing, actions])

  useEffect(() => {
    if (!isRacing) {
      if (
        history.location.pathname.indexOf(
          `/event-page/${eventTypeId}/${eventId}`
        ) !== -1
      ) {
        // actions.requestSportStats(eventTypeId, eventId)   //Chalani hai
      }
      const mainTimer = setIntervalAsync(async () => {
        if (
          history.location.pathname.indexOf(
            `/event-page/${eventTypeId}/${eventId}`
          ) !== -1
        ) {
          // await actions.requestSportStats(eventTypeId, eventId)  //Chalani hai
        }
      }, GET_STATS_INTERVAL)
      return () => clearIntervalAsync(mainTimer)
    }
  }, [eventTypeId, eventId, history.location.pathname, isRacing, actions])

  useIonViewWillEnter(async () => {
    actions.getSportEventError()
    actions.getSportStatsError()
    if (!isRacing) {
      await Promise.all([
        // actions.requestSportData(eventTypeId, eventId), //Chalani hai (maybe)
        // actions.requestSportStats(eventTypeId, eventId), //Chalani hai
      ])
    }
  })
  /**
   *
   */

  // request upcoming races api
  const raceDataRequest = async (Id) => {
    if (!Id) {
      Id = sessionStorage.getItem('navigationID')
    }
    if (auth) {
      try {
        const response = await httpAuth.get(`/exchange/odds/topRaces/${Id}`)
        if (response.data.success) {
          setUpcomingRaces(response.data.result)
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      try {
        const response = await httpNotAuth.get(`/exchange/odds/topRaces/${Id}`)
        if (response.data.success) {
          setUpcomingRaces(response.data.result)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
  
  useEffect(() => {
    if (width > 1024 && isRacing) {
      raceDataRequest(eventTypeId)
    }
  }, [eventTypeId, isRacing, width])
  // end request upcoming races api

  const renderPageContent = useMemo(() => {
    if (isLoaded) {
      // if (isRacing)
      //   return (
      //     <div className="market eventPage-racing">
      //       <RaceEventDropdownList
      //         isRacing={isRacing}
      //         oddId={eventId}
      //         isOpen={true}
      //         full
      //         showPageHeader={true}
      //         setRacingEventHeader={setRacingEventHeader}
      //         showJockeyName={true}
      //         showWinner={true}
      //       />
      //       <IonLabel className="EventPage__title">Upcoming races</IonLabel>
      //       {width < 1024 ? (
      //         <RaceListEventDetails
      //           exclude={eventId}
      //           eventsShow={5}
      //           eventTypeId={eventTypeId}
      //           showJockeyName={true}
      //         />
      //       ) : (
      //         upcomingRaces &&
      //         upcomingRaces.map((item, index) => {
      //           return (
      //             <UpcomingRacesAccordion
      //               key={item?.id + index}
      //               item={item}
      //               index={index}
      //               isAccordionOpen={isAccordionOpen}
      //               setIsAccordionOpen={setIsAccordionOpen}
      //               showHeader={false}
      //               showJockeyName={true}
      //             />
      //           )
      //         })
      //       )}
      //     </div>
      //   )

      return <SportEvent eventTypeId={eventTypeId} eventId={eventId} />
    }
    return (
      <>
        <LoaderContent />
      </>
    )
  }, [isLoaded, eventTypeId, eventId])

  const timeZoneName = useSelector((state) => state.timeSettings.timeZoneName)
  const getZonedTime = (timezone, time) => {
    let date = time ? new Date(time) : new Date()
    if (timezone === 'system')
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
    if (timezone === 'india')
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset() + 330)

    return date
  }
  const tsToDate = (ts, timezone) => {
    const date = getZonedTime(timezone, ts)

    return `${
      date.getDate().toString().length === 1
        ? '0' + date.getDate()
        : date.getDate()
    }/${
      (date.getMonth() + 1).toString().length === 1
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1
    }/${date.getFullYear()} ${date.getHours()}:${formatTime(
      date.getMinutes()
    )}:${formatTime(date.getSeconds())}`
  }
  const startDate = new Date(sportData && sportData[0] && sportData[0][0]?.openDate)

  const optimizedDate = tsToDate(startDate, timeZoneName)

  const hasVideo = sportData && sportData[0] && sportData[0][0]?.tv

  //// score logic

  const eventStats = () => {
    if (sportEventStats?.stats?.battingTeam) {
      const bt = sportEventStats?.stats?.battingTeam

      let scoreWicketsMessage = bt.score;
      10 !== bt.wickets && (scoreWicketsMessage += "-" + bt.wickets)

      let r = bt.overs ? bt.overs : 0
      let s = bt.balls ? bt.balls : 0
      const currentOversBalls = r + "." + s


      return <div className="scorecardTopInfo__eventStats">{bt.name}: {scoreWicketsMessage} ({currentOversBalls} Ovs)</div>
    }

    return null
  }

  const statuses = {
    "InPlay": "In play",
    "BetweenInnings": "Innings Break",
    "Review": "REVIEW",
    "Drinks": "Drinks",
    "Rain": "Rain",
    "BadLight": "Bad light stopped play",
    "Injury": "Injury timeout",
    "Other": "Delay",
  }

  const tossWinnerData = () => {
    const e = sportEventStats?.stats
    let a = e.status,
      i = {
        message: "",
        statusMessage: "",
        statusStyle: "Review" === a ? "review" : ""
      }

    if ("BetweenInnings" === a || 2 === e.currentInning) {
      if ("Completed" !== a) {
        if (null !== e.battingTeam.requiredRuns || e.battingTeam.target) {
          let r = {
            team: e.battingTeam?.name,
            requiredScore: null !== e.battingTeam?.requiredRuns ? e.battingTeam?.requiredRuns : e.battingTeam?.target
          }
          /*i.message = (e.battingTeam.name + ' need ' + r.requiredScore + ' runs',
            (null !== e.battingTeam.requiredRuns && e.battingTeam.requiredRuns > 0 && e.remainingBalls <= 60 && (i.message += " from " + e.remainingBalls + " balls")))*/
          i.message = e.battingTeam?.name + ' need ' + r.requiredScore + ' runs'
          null !== e.battingTeam?.requiredRuns && e.battingTeam?.requiredRuns > 0 && e.remainingBalls <= 60 && (i.message += " from " + e.remainingBalls + " balls")
        }
      } else e.result && "Won" === e.result.type && (i.message = 'Match won by ' + e.result.winnerName);
    } else e.tossWinner && e.tossWinner?.name && e.tossWinner?.decision && (i.message = e.tossWinner?.name + " " + (e.tossWinner.decision === 'optToBowl' ? "opt to bowl" : "opt to bat"))

    return (statuses.hasOwnProperty(a) && (i.statusMessage = statuses[a] + (i.message.length ? " - " : "")),
    e.adjustedOvers && "Completed" !== a && i.message.length && (i.message += ' - reduced to ' + (e.oversPerInning || 0) + ' overs'),
      i)
  }

  const tossWinner = () => {
    if (sportEventStats?.stats?.tossWinner?.name) {
      const data = tossWinnerData()
      // return <div className="EventHeader__details__additional">{sportStats.stats.tossWinner.name} {sportStats.stats.tossWinner.decision}</div>

      return <div className="scorecardTopInfo__tossWinner"><span className={`${data.statusStyle}`}>{data.statusMessage}</span> {data.message}</div>
    }
  }
  //// end score logic

  // console.log("Sports Data: ", sportData);


  return (
    <>
      {width < 1024 ? (
        <IonPage>
          <IonHeader className="onscrollHeader" style={style}>
            <Toolbar
              contentScrolled={contentScrolled}
              // tabs={isLoaded ? (isRacing ? raceTabs : sportTabs) : false}
              tabs={sportTabs}
              activeTab={activeTab}
              eventHeader={isLoaded}
              setStyle={setStyle}
            />
          </IonHeader>

          <IonContent
            className="EventPage"
            fullscreen
            onIonScroll={onScroll}
            scrollEvents={true}
          >
            {activeTab === 2 && isLoaded ? (
              <LiveSection
                eventTypeId={eventTypeId}
                eventId={eventId}
                historyVideo={history.location.pathname}
              />
            ) : null}
            {(activeTab === 0 || activeTab === 2) && renderPageContent}
            {activeTab === 1 && (
              <div className="news">
                {twitterSettings && (
                  <Timeline
                    dataSource={{
                      sourceType: twitterSettings['4'].sourceType,
                      slug: twitterSettings['4'].slug,
                      ownerScreenName: twitterSettings['4'].ownerScreenName,
                    }}
                    options={{
                      ...twitterSettings['4'].options,
                      tweetLimit: false,
                    }}
                  />
                )}
              </div>
            )}
            {activeTab === 3 && <OpenBets openBets={eventOpenBets} isEvent={true} />}
          </IonContent>
        </IonPage>
      ) : (
        <div
          className="EventPage"
          fullscreen
          ref={scrollRef}
          // onIonScroll={onScroll}
          // scrollEvents={true}
        >
            {/*<div style={{ marginBottom: '15px' }} className="favourites-header">*/}
            {/* {(racingEventHeader && !sportData && !sportData[0]?.event?.name) ? null : ""} */}

          {sportData && sportData[0] && sportData[0][0]?.eventName && !isRacing &&
            <div
              style={{ marginBottom: '15px' }}
              className={`favourites-header  ${isRacing ? 'racing-header' : ''} ${
              !hasVideo ? 'withPlay' : ''
            }`}
          >
              <div className={`favourites-header__inner-wrapper`}>
                {!hasVideo && (
                  <IonImg
                    className="inplay-item__broadcast-icon"
                    src={broadcastImg}
                  />
                )}
                  {/* {racingEventHeader && null} */}
                <h1 style={{ marginLeft: '12px' }}>
                  {sportData && sportData[0] && sportData[0][0]?.eventName}
                </h1>
                {sportData && sportData[0] && sportData[0][0]?.openDate && (
                  <div className="sportsPage-headerBet">
                    <span>{optimizedDate.slice(0, 10)}</span>&nbsp;
                    <span>
                      {optimizedDate.slice(11, 20).split(':', 2).join(':')}
                    </span>
                  </div>
                )}
              </div>
              { sportEventStats && ((sportEventStats?.stats && sportEventStats?.scorecard && sportEventStats?.stats?.battingTeam)  ||
                (sportEventStats?.stats && sportEventStats?.scorecard && sportEventStats?.stats?.tossWinner?.name)) &&
                <div className={`favourites-header__scoreCardInfo-content`}>
                  {sportEventStats?.stats?.battingTeam && eventStats()}
                  {sportEventStats?.stats?.tossWinner?.name && tossWinner()}
                </div>
              }
          </div>}
          
          {isLoaded ? (
            <LiveSection
              eventTypeId={eventTypeId}
              eventId={eventId}
              historyVideo={history.location.pathname}
            />
          ) : null}
          
          {(activeTab === 0 || activeTab === 2) && renderPageContent}
          {activeTab === 1 && (
            <div className="news">
              {twitterSettings && (
                <Timeline
                  dataSource={{
                    sourceType: twitterSettings['4'].sourceType,
                    slug: twitterSettings['4'].slug,
                    ownerScreenName: twitterSettings['4'].ownerScreenName,
                  }}
                  options={{
                    ...twitterSettings['4'].options,
                    tweetLimit: false,
                  }}
                />
              )}
            </div>
          )}
          {activeTab === 3 && <OpenBets openBets={eventOpenBets} isEvent={true} />}
        </div>
      )}
    </>
  )
}

// export default EventPage;
export default connectEventPage()(EventPage)
