import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setRulesPopUp } from '../../store/rulesPopUp/rulesPopUp.slice'
import { BallByBallData } from '../../constants/InplayVirtualObjects'

export const connectEventHeader = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      sportStats: state.sportEvent.sportStats,
      sportData: state.sportEvent.sportData,
      virtualData: state.virtualEvent.virtualData,
      raceOdds: state.raceOdds,
      timeSettings: state.timeSettings,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      setRulesPopUp
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
