import React from "react";
export const rulesRegulationsData = [
    {
        id: 'regulation_id-1',
        name: 'FOOTBALL FANCY',
        content: <div>
            <span className="text-danger">Company reserves the right to suspend/void any id/bets if the same is found to be illegitimate. For example incase of vpn/robot-use/multiple entry from same IP/ multiple bets at same time (Punching) and others. Note : only winning bets will be voided...</span>
            <span className="text-danger">Tournament Total Goals, Team Total Goals goals.scored in 90 minutes or in extra-time will count.Goals scored in penalty shootouts do not count.</span>
            <span className="text-danger">Tournament Corners - Only corners taken in 90 minutes count.</span>
            <span className="text-danger">Tournament Penalties Missed/Converted - Penalties taken in 90 minutes, extra-time and penalty shootouts all count. If a penalty has to be re-taken the previous disallowed penalty(ies) do not count.</span>
        </div>,
    },
    {
        id: 'regulation_id-2',
        name: 'BIG BASH LEAGUE',
        content: <div>
            <span>Total match 1st over run:- Average 6 runs will be given if total 20 overs is not played, only 1st innings will be considered as valid</span>
            <span>Highest innings run - Only first innings is valid</span>
            <span>Lowest innings run - Only first innings is valid</span>
            <span>Total 1st 6 over run:- Average 46 runs will be given if total 20 overs is not played, This event is valid only for the 1st innings.</span>
            <span>Total Fours - Average 25 fours will be given in case match abandoned or over reduced</span>
            <span>Total Sixes - Average 10 sixes will be given in case match abandoned or over reduced</span>
            <span>Total Wickets - Average will 12 Wickets be given in case match abandoned or over reduced</span>
            <span>Total Wides - Average 8 wides will be given in case match abandoned or over reduced</span>
            <span className="text-danger">Total Extras - Average 14 extras will be given in case match abandoned or over reduced</span>
            <span>Total Fifties - Average 2 fifties will be given in case match abandoned or over reduced</span>
            <span>Total Caught out - Average 8 catch out will be given in case match abandoned or over reduced</span>
            <span>Total Bowled out - Average 2 bowled out will be given in case match abandoned or over reduced</span>
            <span>Highest 6 over run: Both innings are valid</span>
            <span>Highest run in individual match: Both innings are valid</span>
            <span>Highest Fours in individual match: Both innings are valid</span>
            <span>Highest Sixes in individual match: Both innings are valid</span>
            <span>Total LBW:- Average 1 LBW will be given in case match abandoned or over reduced</span>
            <span>Highest Wickets in individual match: Both innings are valid</span>
            <span>Highest extras in individual match: Both innings are valid</span>
            <span>Highest match 1st over run in individual match: Only 1st inning will be considered as valid valid</span>
            <span className="text-danger">All events related to bowler are valid only for the league stages, both the innings will be considered as valid. A minimum of 32 overs has to be bowled else the same will be voided. If the mentioned bowler has bowled one legal delivery then it will be considered as 1 over even if the over is not completed</span>
            <span className="text-danger">All events based on ground are decided based on the initial fixture, in case of any changes in the venue between the series. Then average will be given based on the initial fixture for the number of games reduced, Similarly if any match is added newly to the venue will not be considered</span>
            <span className="text-danger">Average for wickets taken will be given in case match abandoned or over reduced or the player has not bowled single legal delivery before the over got reduced</span>
            <span className="text-danger">Fancy based on all individual teams/players/ground are valid only for league stage</span>
            <span className="text-danger">Management decision will be final</span>
            <span>Total 1st over run:- Average 6 runs will be given if total 20 overs is not played, only 1st innings will be considered as valid</span>
            <span>Total 6 over run:- Average 46 runs will be given if total 20 overs is not played, only 1st innings will be considered as valid</span>
            <span>Total Fours - Average 28 fours will be given in case match abandoned or over reduced</span>
            <span>Total Sixes - Average 11 Sixes will be given in case match abandoned or over reduced</span>
            <span>Total Wide – Average 8 Wide will be given in case match abandoned or over reduced</span>
            <span>Total Extras - Average 14 Extras will be given in case match abandoned or over reduced</span>
            <span>Total Run- Average 330 runs will be given in case match abandoned or over reduced</span>
            <span className="text-danger">Manuka Oval:- Canberra</span>
        </div>,
    },
    {
        id: 'regulation_id-3',
        name: 'LUNCH FAVOURITE',
        content: <div>
            <span className="text-danger">1. The team which is favourite at lunch will be considered as lunch favourite or the team which is favourite after first inning last ball will be considered as lunch favourite in our exchange.</span>
            <span className="text-danger">2. In any circumstances management decision will be final.</span>
            <span className="text-danger">3. In case of tie in T20 or one day in lunch favourite game , all bets will be deleted in our exchange.</span>
            <span className="text-danger">4. In case overs are reduced in a match, the team which favourite at lunch will be considered as lunch favourite.</span>
            <span>4.1 For example :- if match is reduced to 18 over per side in t20 or Oneday then after 18 over the team which is favourite at lunch will be considered as lunch favourite.</span>
            <span className="text-danger">5. In case of weather, 1st innings match overs are reduced and direct target is given to team which will bat in 2nd inning then lunch favourite will be considered after target is given at lunch.</span>
            <span>5.1 For example :- in T20 match rain comes at 14 over and match is interrupted due to rain and direct target is given to 2nd batting team, then team which is favourite in match odds after target is given in match, will be considered as lunch favourite.</span>
        </div>,
    },
    {
        id: 'regulation_id-4',
        name: 'BOOKMAKER',
        content: <div>
            <span className="text-danger">Company reserves the right to suspend/void any id/bets if the same is found to be illegitimate. For example incase of vpn/robot-use/multiple entry from same IP/ multiple bets at same time (Punching) and others. Note : only winning bets will be voided...</span>
            <span className="text-danger">Due to any reason any team will be getting advantage or disadvantage we are not concerned.</span>
            <span className="text-danger">Company reserves the right to suspend/void any id/bets if the same is found to be illegitimate. For example incase of vpn/robot-use/multiple entry from same IP/ multiple bets at the same time (Punching) and others. Note : only winning bets will be voided.</span>
            <span className="text-danger">We will simply compare both teams 25 overs score higher score team will be declared winner in ODI (25 over comparison)</span>
            <span className="text-danger">We will simply compare both teams 10 overs higher score team will be declared winner in T20 matches (10 over comparison)</span>
            <span className="text-danger">Any query about the result or rates should be contacted within 7 days of the specific event, the same will not be considered valid post 7 days from the event.</span>
            <span className="text-danger">If two team ends up with equal points, then result will be given based on the official point table</span>
            <span className="text-danger">Tennis:- Advance fancy market</span>
            <span>If the second set is not completed all bets regarding this market will be voided</span>
            <span>If a player retires after completion of second set, then the market will be settled as three sets</span>
            <span className="text-danger">Virtual Cricket</span>
            <span>At any situation if the video gets interrupted/stopped then the same cannot be continued due to any technical issues bookmaker market will be voided</span>
        </div>,
    },
    {
        id: 'regulation_id-5',
        name: 'POLITICS',
        content: <div>
            <span className="text-danger">Indian state legislative assembly elections.</span>
            <span>1. This event is to decide the winner of various legislative assemblies of india .</span>
            <span>2. The final result declared by election commission of india for assembly elections of various states of india for a particular year will be valid in our exchange ,The customers are entirely responsible for their bets at all times.</span>
            <span>3. All bets will be voided if the election doesn’t take place in given time by election commission or as per our exchange management decision.</span>
            <span>4. Company reserves the right to suspend/void any bets on this event at any time if we find the same not to be legitimate with the certainty of the outcome.</span>
            <span>5. Accidental issues during assembly elections will not be counted in our exchange ,If required Additional candidates may be added on request.</span>
            <span>6. Kindly be informed no candidates will be partially settled and will remain in the market until it is fully settled. This is to ensure that all customers can continue trading for the candidates that they have positions on, since each candidate is still a valid runner in this market.</span>
            <span>7. Please be informed that the transmissions described as “live” by few broadcasters may actually be delayed due to multiple scenarios.</span>
            <span>8. If any candidate withdraws for any reason, including death, all bets on the market will be valid and be settled as per the defined rules.</span>
        </div>,
    },
    {
        id: 'regulation_id-6',
        name: 'FANCY MARKET',
        content: <div>
            <span className="text-danger">1. Even odd game betting rate rules.</span>
            <span>1.1 Completed game is valid , in case due to rain over are reduced or match abandoned particular game will be deleted.</span>
            <span>1.2 All bets regarding to ODD/EVEN player/partnership are valid if one legal delivery is being played, else the bets will be deleted. Player odd/even all advance bets will be valid if one legal delivery is being played in match otherwise voided.</span>
            <span>1.3 All bets regarding Odd/Even sessions will be deleted if the particular session is incomplete, for example match got abandoned or finished before the end of particular session.</span>
            <span>1.4 In any circumstances management decision will be final.</span>
            <span className="text-danger">2 Top batsman rules:-</span>
            <span className="text-danger">2.1 If any player does not come as per playing eleven then all bets will be get deleted for the particular player.</span>
            <span>2.2 two players done the same run in a single match (M Agarwal 30 runs and A Rayudu 30 runs, whole inning top batsmen score also 30 run) then both player settlement to be get done 50 percent (50% , 50%)rate on their original value which given by our exchange.</span>
            <span>Suppose we have opened value of M Agarwal 3.75 back and customer place bets on 10000 @ 3.75 rates and A Rayudu 3.0 back and customer place bets on 10000 @ 3.0 rates.</span>
            <span>Whole inning result announces 30 run by both player then</span>
            <span className="text-danger">Rule of top batsman:-if you bet on M Agarwal you will be get half amount of this rate (10000*3.75/2=18750 you will get)</span>
            <span>Top batsman only 1st inning valid.</span>
            <span>For one day 50 over and for t-20 match 20 over must be played for top batsmen otherwise all bets will be deleted.</span>
            <span className="text-danger">Man of the Match Rules</span>
            <span>1. All bets will be deleted in case the match is abandoned or over reduced.</span>
            <span>2. All bets will be deleted if the mentioned player is not included in playing 11.</span>
            <span>3. In case Man of the Match is shared between two players then Dead heat rule will be applicable, For example K Perera and T Iqbal shares the Man of the Match, then the settlement will be done 50% of the rates accordingly.</span>
            <span>4. Rules similar to our Top Batsman rules.</span>
        </div>,
    },
    {
        id: 'regulation_id-7',
        name: 'IPL',
        content: <div>
            <span className="text-danger">IPL Special 2024 Valid Only For First 21 Matches. If IPL fixture of 21 matches gets reduced due to any reason, then all the special fancies will be voided (Match abandoned due to rain/bad light will not be considered in this)</span>
            <span className="text-danger">Management decision will be final</span>
            <span>Total 1st over runs: Average 6 runs will be given in case match abandoned or over reduced (only 1st innings valid)</span>
            <span>Total 1st 6 over run:- Average 48 runs will be given in case match abandoned or over reduced (Only 1st Innings valid)</span>
            <span>Total fours: Average 28 fours will be given in case match abandoned or over reduced</span>
            <span>Total sixes: Average 14 sixes will be given in case match abandoned or over reduced</span>
            <span>Total Wickets - Average will 12 Wickets be given in case match abandoned or over reduced</span>
            <span>Total Extras - Average 17 extras will be given in case match abandoned or over reduced</span>
            <span>Total No ball :- Average 1 No ball will be given in case match abandoned or over reduced</span>
            <span>Total Fifties - Average 2 fifties will be given in case match abandoned or over reduced</span>
            <span>Total Caught outs: Average 8 caught out will be given in case match abandoned or over reduced</span>
            <span>Total Bowled:- Average 2 Bowled out will be given in case match abandoned or over reduced</span>
            <span>Total LBW:- Average 1 LBW will be given in case match abandoned or over reduced</span>
            <span>Total Run out:- Average 1 Run out will be given in case match abandoned or over reduced</span>
        </div>,
    },
    {
        id: 'regulation_id-8',
        name: `WOMEN'S PREMIERE LEAGUE`,
        content: <div>
            <span>1. If WPL fixture of 22 matches gets reduced due to any reason, then all the special fancies will be voided (Match abandoned due to rain/bad light will not be considered in this).</span>
            <span>2. Total matches 1st over runs : Average 5 runs will be given in case match abandoned or over reduced (only 1st innings valid).</span>
            <span>3. Total matches 1st 6 over runs:- Average 40 runs will be given in case match abandoned or over reduced (Only 1st Innings valid).</span>
            <span>4. Total 4's : Average 32 fours will be given in case match abandoned or over reduced.</span>
            <span>5. Total 30's: Average 2 sixes will be given in case match abandoned or over reduced.</span>
            <span>6. Total 50's - Average 1 fifties will be given in case match abandoned or over reduced.</span>
            <span>7. Total Wickets - Average will 12 Wickets be given in case match abandoned or over reduced.</span>
            <span>8. At any situation if result is given for any particular event based on the rates given for the same, then the particular result will be considered valid, similarly if the tournament gets canceled due to any reason the previously given result will be considered valid.</span>
            <span>9. Highest innings run - Both innings are valid.</span>
            <span>10. Lowest innings run - Only first innings is valid. </span>
            <span>11. Highest Match 1st over runs in the match: Only first innings is valid.</span>
            <span>12. Highest 1st 6 over runs: - Only first innings is valid.</span>
            <span>13. Highest 4's in individual match: Both innings are valid.</span>
            <span>14. Highest Wickets in individual match: Both innings are valid.</span>
            <span>15. Highest over runs: Both innings are valid.</span>
            <span>16. Most Balls Faced By a Batsman : Maximum Balls Faced by a batsman in one Innings.</span>
            <span>17. Most 4's by individual batsman in an Inning : Maximum Number of Fours Hit By A Batsman in one Innings.</span>
            <span>18. Most Dot balls By a Bowler in an Inning : Maximum Dot balls Bowled by a Bowler in his Quota of Innings.</span>
            <span>19. Most runs given by Bowler in an Inning : Maximum Runs conceded by a individual Bowler in an Innings.</span>
            <span>20. Most wickets by Bowler in an inning : Maximum Wickets taken by a individual Bowler in an Innings.</span>
            <span>21. In fastest fifty always the first 50 runs will be considered, for example of S Mandhana scores 1st fifty in 17 balls and scores 100 in next 14 balls, fastest 50 will be given based on the balls for the 1st fifty runs Super over will not be included.</span>
        </div>,
    },
    {
        id: 'regulation_id-10',
        name: `PAKISTAN SUPER LEAGUE (PSL)`,
        content: <div>
            <span>1. If PSL fixture of 34 matches gets reduced due to any reason, then all the special fancies will be voided (Match abandoned due to rain/bad light will not be considered in this).</span>
            <span>2. Total Matches 1st over runs : Average 6 runs will be given in case match abandoned or over reduced (only 1st innings valid).</span>
            <span>3. Total Matches 1st 6 over runs : Average 50 runs will be given in case match abandoned or over reduced (Only 1st Innings valid).</span>
            <span>4. Total fours: Average 32 fours will be given in case match abandoned or over reduced.</span>
            <span>5. Total sixes: Average 13 sixes will be given in case match abandoned or over reduced.</span>
            <span>6. Total 30's: Average 2 Thirties will be given in case match abandoned or over reduced.</span>
            <span>7. Total Fifties - Average 2 Fifties will be given in case match abandoned or over reduced.</span>
            <span>8. Total Wickets - Average 13 Wickets will be given in case match abandoned or over reduced.</span>
            <span>9. Total Wides - Average 11 Wides will be given in case match abandoned or over reduced.</span>
            <span>10. Total Extras - Average 18 Extras will be given in case match abandoned or over reduced.</span>
            <span>11. Total Caught outs: Average 8 Caught out will be given in case match abandoned or over reduced.</span>
            <span>12. Total Bowled:- Average 2 Bowled out will be given in case match abandoned or over reduced.</span>
            <span>13. Total LBW:- Average 1 LBW will be given in case match abandoned or over reduced.</span>
            <span>14. Total Run out:- Average 1 Run out will be given in case match abandoned or over reduced At any situation if result is given for any particular event based on the rates given for the same, then the particular result will be considered valid, similarly if the tournament gets canceled due to any reason the previously given result will be considered valid.</span>
            <span>15. Highest innings run - Both innings are valid.</span>
            <span>17. Highest Match 1st over runs in the match: Only first innings is valid.</span>
            <span>18. Highest 1st 6 over runs: - Only first innings is valid.</span>
            <span>19. Highest 4's in individual match: Both innings are valid.</span>
            <span>20. Highest 6's in individual match: Both innings are valid.</span>
            <span>21. Highest Wickets in individual match: Both innings are valid.</span>
            <span>22. Highest Extras in individual match: Both innings are valid.</span>
            <span>23. Highest over runs: Both innings are valid.</span>
            <span>24. Most Balls Faced By a Batsman : Maximum Balls Faced by a batsman in one Innings.</span>
            <span>25. Most 4's by individual batsman in an Inning : Maximum Number of Fours Hit By A Batsman in one Innings.</span>
            <span>26. Most 6's by individual batsman in an Inning : Maximum Number of Sixes Hit By A Batsman in one Innings.</span>
            <span>27. Most Dot balls By a Bowler in an Inning : Maximum Dot balls Bowled by a Bowler in his Quota of Innings.</span>
            <span>28. Most runs given by Bowler in an Inning : Maximum Runs conceded by a individual Bowler in an Innings.</span>
            <span>29. Most wickets by Bowler in an inning : Maximum Wickets taken by a individual Bowler in an Innings.</span>
            <span>30. Total 50 Plus Partnership runs- 50 and above 50 runs partnership will be counted in this event..</span>
            <span>31. In fastest fifty always the first 50 runs will be considered, for example of R Sharma scores 1st fifty in 17 balls and scores 100 in next 14 balls, fastest 50 will be given based on the balls for the 1st fifty runs Super over will not be included.</span>
        </div>,
    },
    {
        id: 'regulation_id-11',
        name: `KABADDI`,
        content: <div>
            <span>In any circumstances management decision will be final related to all Fancy of kabaddi of our exchange.</span>
            <span>All fancy bets will be validated when match has been tied.</span>
            <span>Result of individual player of fancy will be validated only when player play that match.</span>
            <span>In any case wrong rate has been given in fancy that particular bets will be deleted.</span>
            <span>For Playoffs Final Result Of 40 Minutes Of Two Halves Will Be Valid In Our Exchange</span>
        </div>,
    },
    {
        id: 'regulation_id-12',
        name: `KABADDI`,
        content: <div>
            <span>In any circumstances management decision will be final related to all Fancy of kabaddi of our exchange.</span>
            <span>All fancy bets will be validated when match has been tied.</span>
            <span>Result of individual player of fancy will be validated only when player play that match.</span>
            <span>In any case wrong rate has been given in fancy that particular bets will be deleted.</span>
            <span>For Playoffs Final Result Of 40 Minutes Of Two Halves Will Be Valid In Our Exchange</span>
        </div>,
    },
    {
        id: 'regulation_id-13',
        name: `BINARY`,
        content: <div>
            <span className="text-danger">1. All session's bets will be confirmed at market rate only.</span>
            <span className="text-danger">2. All session's settlement price means result can be checked from exchange's official sites.</span>
            <span className="text-danger">3. All session's result will be settlement price provided by exchange after market close.</span>
            <span className="text-danger">4. Every product has two types of prices SPOT and FUTURE. We provide only near month's FUTURE price in Binary Session. You can check it from the official website of that product.</span>
            <span className="text-danger">5. Session's timings : NFY, B-NFY, AXS, ICI, RIL, SBI, TT STL - Monday to Friday 10:00 a.m. to 2:30 p.m. GOLD, SILVER, CRUDE - Monday to Friday 11:30 a.m. to 10:30 p.m. CMX CRUDE, DOWJONES, NASDAQ, SNP - Monday to Friday 7:30 p.m. to 12:00 a.m.</span>
            <span>Same bets same time from multiple id not allowed.</span>
            <span>Operating and market making bets (cheating/line/chamka bets) are not allowed.</span>
            <span>if any case wrong rate has been given in fancy that particular bets will be cancelled.</span>
            <span>Deleted bets will remove under 24hr and clients will be notified.</span>
        </div>,
    },
    {
        id: 'regulation_id-14',
        name: `MATCH`,
        content: <div>
            <span>Company reserves the right to suspend/void any id/bets if the same is found to be illegitimate. For example incase of vpn/robot-use/multiple entry from same IP/ multiple bets at same time (Punching) and others. Note : only winning bets will be voided, For example: If we find such entries (above mentioned) from any id and their bets are (200000 lay in a 6 over session for the rate 40 and 200000 back for the rate of 48) and the actual score is 38, bets of 40 lay will be voided and the bets for 48 back will be considered valid.</span>
            <span>TENNIS Match Odds :- If 1st set has been not completed at the time of the retirement or disqualification, then all bets relating to that individual match will be void.</span>
            <span>FOOTBALL Match Odds :- All bets apply to the relevant full 'regular time' period including stoppage time. Any extra-time and/or penalty shoot-out is not included. For the cancellation of a goal, due to VAR, bets matched between the time of the goal being scored and the time at which the video assistant referee finishes the review will be voided. For the cancellation of a red card, due to VAR, bets matched after the time at which the video assistant referee commences the review will be voided.</span>
            <span>FOOTBALL Under_Over Goals :- In the event of a match starting but not being completed, all bets will be void, unless the specific market outcome is already determined,</span>
        </div>,
    },
    {
        id: 'regulation_id-15',
        name: `MATCH`,
        content: <div>
            <span>Only First inning valid for T20 and one day matches.</span>
            <span>Same will be work like Lambi. If match abandoned or over reduced, all bets will be deleted.</span>
            <span>You can choose your own value in this event.</span>
        </div>,
    },
    {
        id: 'regulation_id-16',
        name: `ELECTION`,
        content: <div>
            <span  className="text-danger">1. The final result declared by election commission of India for Loksabha election 2019 will be valid in our exchange.</span>
            <span  className="text-danger">2. Accidental issues during Loksabha election 2019 will not be counted in our exchange.</span>
        </div>,
    },
    {
        id: 'regulation_id-17',
        name: `VIRTUAL TENNIS`,
        content: <div>
            <span>1. If streaming stops or some technical issue occurs, the match will be abandoned.</span>
            <span>2. If there is any technical interference in the match then also the match will be abandoned.</span>
            <span>3. *There will be 3 sets in the match. There are 3 games in 1 set.</span>
            <span>4. *In the match, within any set, there are 3-3 games between the two players (level game) till a tie break of 5 points is played, according to which the one who gets 2 points more than the difference of points will win the set.</span>
        </div>,
    },
    {
        id: 'regulation_id-18',
        name: `CASINO PAYOUT RESTRICTIONS`,
        content: <div>
            <span>1. Restriction of payout is applicable for all Crash games (include Aviator).</span>
            <span>2. In Single round, User is eligible for a max payout of 500 times his bet amount, example if the bet is 100 then max payout shall be 100 X 500= 50000, any winning above this multiplier shall not be paid out by the company.</span>
            <span>3. Another restriction is max payout amount is capped at 2,00,00,000 (2 Crore points) , if net winning amount is beyond this amount then user shall be paid only this amount as max winning in Casino games.</span>
        </div>,
    },
];



