import React, { useState, useEffect } from 'react'
import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonList,
  IonPage,
  IonHeader,
  IonLabel,
  IonImg,
} from '@ionic/react'
import './LeftSideMenuDesktop.scss'
import Toolbar from '../../components/Toolbar/Toolbar'
import ToolbarDesktop from '../../components/ToolbarDesktop/ToolbarDesktop'
import leftSideMenuMapper from '../../helpers/getLeftSideMenuMapperDesktop.helper'
// import leftSideMenuMapper from '../../helpers/getLeftSideMenuMapper.helper'
import { connectLeftSideMenuDesktop } from './LeftSideMenuDesktop.connect'
import { useHistory } from 'react-router'
import { goBackPages } from '../../config/app.config'

import StarIcon from '../../assets/images/sideBarStar.svg'
import CricketIcon from '../../assets/images/sideBarCricket.svg'
import FootballIcon from '../../assets/images/sideBarFooltball.svg'
import TennisIcon from '../../assets/images/sideBarTennis.svg'
import HomepageIcon from '../../assets/images/detailedHomepage.svg'
import horseIcon from '../../assets/images/sidebar/horse-riding.svg';
import PoliticsIcon from '../../assets/images/sidebar/politics-icon.png';
import GreyhoundIcon from '../../assets/images/sidebar/greyhound-racing.svg';
import KabaddiIcon from '../../assets/images/sidebar/kabaddi-icon.png';
import BinaryIcon from '../../assets/images/sidebar/binary-icon.svg';
import IntCasinoIcon from '../../assets/images/sidebar/intcasino-icon.png';
import SportsbookIcon from '../../assets/images/sidebar/sportsbook-icon.svg';
import MartialArtsIcon from '../../assets/images/sidebar/martial-arts-icon.png';
import VolleyballIcon from '../../assets/images/sidebar/volleyball-icon.png';
import IceHockeyIcon from '../../assets/images/sidebar/ice-hockey.png';
import BasketballIcon from '../../assets/images/sidebar/basketball-icon.png';
import BaseballIcon from '../../assets/images/sidebar/baseball-icon.png';
import DartsIcon from '../../assets/images/sidebar/darts.png';
import FutsalIcon from '../../assets/images/sidebar/futsal-icon.png';
import TableTennisIcon from '../../assets/images/sidebar/tabletennis-icon.png';
import RugbyIcon from '../../assets/images/sidebar/rugby-icon.png';
import lIVECasinoIcon from '../../assets/images/sidebar/livecasino-icon.png';

import CricketIconWhite from '../../assets/images/detailedCricketWhite.svg'
import FootballIconWhite from '../../assets/images/detailedFootballWhite.svg'
import TennisIconWhite from '../../assets/images/detailedTennisWhite.svg'
import HorseIconWhite from '../../assets/images/detailedHorseWhite.svg'
import GreyhoundIconWhite from '../../assets/images/detailedGreyhoundWhite.svg'
import ExchangeIconWhite from '../../assets/images/detailedExchangeWhite.svg'
import SlotsgamesIcon from '../../assets/images/sideBarSlotsgames.svg'
import CasinoIcon from '../../assets/images/casino.png'

import SubtractCricket from '../../assets/images/detailedSubtractCricket.svg'
import SubtractFootball from '../../assets/images/detailedSubtractFootball.svg'
import SubtractTennis from '../../assets/images/detailedSubtractTennis.svg'
import SubtractHorse from '../../assets/images/detailedSubtractHorse.svg'
import SubtractGreyhound from '../../assets/images/detailedSubtractGreyhound.svg'

import ArrowCricket from '../../assets/images/rightArrowCricket.svg'
import ArrowFootball from '../../assets/images/rightArrowFootball.svg'
import ArrowTennis from '../../assets/images/rightArrowTennis.svg'
import ArrowHorse from '../../assets/images/rightArrowHorse.svg'
import ArrowGreyhound from '../../assets/images/rightArrowGreyhound.svg'
import ArrowWhite from '../../assets/images/rightArrowWhite.svg'
import BlastOff from '../../assets/images/menu-icons/blastoff-icon.svg';
import aviator from '../../assets/images/menu-icons/aviator.png';
import { useSelector } from 'react-redux'
import { tsToDate } from '../../helpers/date.helper'
import { useParams } from 'react-router'
import menuSlice from '../../store/menu/menu.slice'
import NewHttpAuth from '../../services/NewHttpAuth'

const LeftSideMenuDesktop = ({ menu, homeMarketEvents, actions, timeSettings, auth, sportPageEvent, sportItemData, openSport, moreDetailed, eventDetailed, sportsName }) => {
  const history = useHistory()
  const eventID = history.location.pathname.substring(history.location.pathname.lastIndexOf('/') + 1)
  // console.log('eventID', eventID);


  // let arr = []
  //   arr = menu && menu?.find((item) => item.id == oneDetailItem)?.children?.slice()

  const onOpenDetailedMenu = () => {
    setDetailedSideBar(!detailedSideBar)
  }

  const sportsGroupName = useSelector(
    (state) => state.navigationHeaderMenu.sportsGroupName
  )

  const {eventId} = useParams()

  const getMapMenu = (menuState) => {
    return menuState
      .map((menuItem) => {
        const menuHelperItem = Object.entries(leftSideMenuMapper)
          .map(([key, value]) => value)
          .find((menuItemWrapper) => menuItem.id == menuItemWrapper.id)
        return {
          ...menuItem,
          img: menuHelperItem ? menuHelperItem.img : '',
          isSvg: menuHelperItem ? menuHelperItem.isSvg : '',
          name: menuHelperItem ? menuHelperItem.name : menuItem.name,
        }
      })
      .sort((a, b) => a.sortOrder - b.sortOrder)
  }

  useEffect(() => {
    setMenuItemsMap(getMapMenu(menu || []))
  }, [menu])

  const [menuItemsMap, setMenuItemsMap] = useState([])
  const [detailedSideBar, setDetailedSideBar] = useState(false)
  const [moreDeatails, setMoreDetails] = useState(false)
  const [oneMoreDetaile, setOneMoreDetaile] = useState()
  const [oneDetailItem, setOneDetailItem] = useState()
  const [oneDetailName, setOneDetailName] = useState()
  const [sideMainMenu, setSideMainMenu] = useState(true)
  const [displayNone, setDisplayNone] = useState(false)
  const [togleActive, setTogleActive] = useState()
  const [prevGame, setPrevGame] = useState()
  const [sport, setSport] = useState(null)
  const [altName, setAltName] = useState()
  const [detailedItemName, setDetailedItemName] = useState([])
  const [itemSport, setItemSport] = useState([])
  const [competitions, setCompetitions] = useState([]);
  const isNavigationOpen = useSelector(
    (state) => state.navigationHeaderMenu.isOpen
  )
  const isNavigationID = useSelector(
    (state) => state.navigationHeaderMenu.navigationID
  )
  const isNavigationName = useSelector(
    (state) => state.navigationHeaderMenu.navigationName
  )
  const isNavigationReset = useSelector(
    (state) => state.navigationHeaderMenu.navigationReset
  )
  const isAnimatedMenu = useSelector(
    (state) => state.navigationHeaderMenu.animatedMenu
  )

  const isOpenSport = useSelector(
    (state) => state.navigationHeaderMenu.openSport
  )

  const isMoreDetailed = useSelector(
    (state) => state.navigationHeaderMenu.moreDetailed
  )

  const user = useSelector((state) => state.user);


  // console.log('menu', isAnimatedMenu);

  useEffect(() => {
    if (isNavigationOpen) {
      setDetailedSideBar(true)
      setTogleActive('')
    }
  }, [isNavigationOpen])

  useEffect(() => {
    if(isOpenSport){
      setTimeout(() => {
        onOpenDetailedMenu(!detailedSideBar)
      }, 1000)
    }
  }, [isOpenSport])

  // useEffect(() => {
  //   if (sideMainMenu) {
  //     setDisplayNone(false)
  //     setTogleActive('')
  //     actions.setIsOpenSport(false)
  //   }
  // }, [sideMainMenu])


  useEffect(() => {
    if (isNavigationReset) {
      if (eventDetailed) {
        onMoreDetails(!eventDetailed)
        onDisplayNone(!displayNone)
        setSideMainMenu(!sideMainMenu)
        setTogleActive('')
      } else if (detailedSideBar) {
        onOpenDetailedMenu(!detailedSideBar)
      }
    }
  }, [isNavigationReset])
    
    let arr = []
    arr = menu && menu?.find((item) => item.id == oneDetailItem)?.children?.slice() 

    useEffect(()=> {
      if(isOpenSport){

        // console.log("item Sport: ", arr);

        setItemSport(arr)
      } else if(!isOpenSport) {
        setItemSport([])
      }
    }, [isOpenSport])

    // console.log('itemSport', itemSport);
    // console.log('isOpenSport', isOpenSport)
  
    // console.log('itemSport', itemSport);


  useEffect(() => {
    setOneDetailItem(isNavigationID)
    setOneDetailName(isNavigationName)
    // setMoreDetails(false)
    actions.setIsEventDetailed(false)
    setTogleActive('')
  }, [isNavigationID, isNavigationOpen])

  // console.log('one', oneDetailItem);

  const onMoreDetails = () => {
    // setMoreDetails(!moreDeatails)
    // console.log("Event Detailed: ", eventDetailed);
    actions.setIsEventDetailed(!eventDetailed)
  }

  const onDisplayNone = () => {
    setDisplayNone(!displayNone)
  }

  useEffect(() => {
    setSport(sportItemData)
  }, [sportItemData])
  // console.log("menu from state in left", menu)
  const getMenu = (data) => {
    // console.log("GetMenu: ", data);
    // actions.requestMenu(data).catch((e) => console.log("menu error = ", e));
    actions.requestMenu().catch((e) => console.log("menu error = ", e));
  };

  useEffect(() => {
    if(menu?.length === 0) {
      getMenu();
    }

    // console.log("Menu: ", menu);
  }, [menu])


  const FetchhomeAllCompetition = async(id) => {
    const data = {
      eventTypeId: id
    };

    setTimeout(() => {
      actions.requestHomeAllCompetition(data);
    }, 500)
  }

  const FetchHomeAllEvents = async(eventTypeId, competitionId) => {
    const data = {
      eventTypeId,
      competitionId
    }

    setTimeout(() => {
      actions.requestHomeAllEvents(data);
    }, 500)
  }


  const detailedMenu = () => {
    if (!detailedSideBar || !menu.length || !oneDetailItem) return [];
  
    switch (oneDetailItem) {
      case '4':
        return menu[0] || [];
      case '1':
        return menu[1] || [];
      case '2':
        return menu[2] || [];
      default:
        return [];
    }
  };

  const menuList = detailedMenu();


  const getMenuItems = () => {
    return menuItemsMap.map((value, index) => {
      return (
        <div
          className="content-list__item"
          key={index}
          onClick={() => {
            setOneDetailItem(value.id)
            setOneDetailName(value.name)
            actions.setIsNavigationID(value.id)
            actions.setIsNavigationName(value.name)
            onOpenDetailedMenu(!detailedSideBar)
            setSideMainMenu(false)
            setDetailedItemName(value?.children)
            actions.setRenderIonContent(false)
            setDisplayNone(false)

            let page
            if (value.id == '1444000') {
              // page = '/casino_live/1444000'
              // page = '/livecasino-lobby'
              // actions.setSlotCasinoPopUp(true)
              actions.openSlotCasinoPopUp();
              history.push('/livecasino-lobby')
            } else if (value.id == '1444005') {
              page = `/sportsbook/${value.id}`
              setDetailedSideBar(false)
              // auth ?
               page = `/sportsbook/${value.id}`
              //  : page=""
            } else if (value.id == '7') {
              // actions.setIsNavigationOpen(true)
              // actions.setIsNavigationID('7')
              // actions.setIsNavigationName('Horse Racing')
              history.push('/racing-sports-page')
            } else if (value.id == '4339') {
              // actions.setIsNavigationOpen(true)
              // actions.setIsNavigationID('4339')
              // actions.setIsNavigationName('Greyhound Racing')
              history.push('/racing-sports-page')
            } else if (value.id == '1444001') {
              history.push('/exchange-games')
              // console.log(value);
            // } else if (value.id == '1444002') {
            //   history.push('/slotsgames-lobby')
            } else {
              // page = `/sports-page/`
              history.push('/sports-page')
            }


          //   if(!page && !auth) {
          //   actions.setLoginNew(true)

          // } else {
            history.push({
              pathname: page,
              state: { id: value.id }
            })

          }}
        >
          {value.isSvg ? (
            // ? <img className="left-side-menu-item__icon eventIcon_desk svg" src={value.img} />
            <img className="content-list__item-icon" src={value.img} />
          ) : (
            <span
              className={`left-side-menu-item__icon eventIcon ${value.name.replace(
                /\s/g,
                ''
              )}`}
            >
              {value.img}
            </span>
          )}
          <span className="content-list__item-text">{value.name}</span>
        </div>
      )
    })
  }

  // console.log('detailedItemName', detailedItemName);


  return (
    <div className={`sideBarMenu__wrapper`}>
      <div className="sideBarMenu">
        <div className="sideBarMenu-content">
          <div
            className={`content-list ${
              detailedSideBar
                ? 'inactiveDet'
                : eventDetailed
                ? 'inactiveDet'
                : sideMainMenu
                ? 'active'
                : ''
            }`}
          >
            <div
              className="content-list__item"
              key="mymarket"
              onClick={() => {
                history.push('/favouritesPage')
              }}
            >
              <img className="content-list__item-icon" src={StarIcon} alt="" />
              <IonLabel className="content-list__item-text">
                Favourites
              </IonLabel>
            </div>
            <div
              className="content-list__item"
              key="_cricket_"
              onClick={() => {
                const cricket = {'sport_id': '4', 'step': '1'}
                // getMenu(cricket);
                setOneDetailItem('4')
                setOneDetailName('Cricket')
                actions.setIsNavigationID('4')
                actions.setIsNavigationName('Cricket')
                onOpenDetailedMenu(!detailedSideBar)
                setSideMainMenu(false)
                setDetailedItemName(menu)
                actions.setRenderIonContent(false)
                setDisplayNone(false);
                FetchhomeAllCompetition('4');
              }}
            >
              <img className="content-list__item-icon" src={CricketIcon} alt="" />
              <IonLabel className="content-list__item-text">
                Cricket
              </IonLabel>
            </div>
            <div
              className="content-list__item"
              key="tennis"
              onClick={() => {
                const tennis = {'sport_id': '2', 'step': '1'}
                // getMenu(tennis);
                setOneDetailItem('2')
                setOneDetailName('Tennis')
                actions.setIsNavigationID('2')
                actions.setIsNavigationName('Tennis')
                onOpenDetailedMenu(!detailedSideBar)
                setSideMainMenu(false)
                setDetailedItemName(menu)
                actions.setRenderIonContent(false)
                setDisplayNone(false)
                FetchhomeAllCompetition('2');
              }}
            >
              <img className="content-list__item-icon" src={TennisIcon} alt="" />
              <IonLabel className="content-list__item-text">
                Tennis
              </IonLabel>
            </div>
            <div
              className="content-list__item"
              key="football"
              onClick={() => {
                const soccer = {'sport_id': '1', 'step': '1'}
                // getMenu(soccer);
                setOneDetailItem('1')
                setOneDetailName('Football')
                actions.setIsNavigationID('1')
                actions.setIsNavigationName('Football')
                onOpenDetailedMenu(!detailedSideBar)
                setSideMainMenu(false)
                setDetailedItemName(menu)
                actions.setRenderIonContent(false)
                setDisplayNone(false)
                FetchhomeAllCompetition('1');
              }}
            >
              <img className="content-list__item-icon" src={FootballIcon} alt="" />
              <IonLabel className="content-list__item-text">
                Soccer
              </IonLabel>
            </div>
            <div
              className="content-list__item"
              key="football_1"
              onClick={() => {
                const soccer = {'sport_id': '1', 'step': '1'}
                // getMenu(soccer);
                setOneDetailItem('1')
                setOneDetailName('Football')
                actions.setIsNavigationID('1')
                actions.setIsNavigationName('Football')
                onOpenDetailedMenu(!detailedSideBar)
                setSideMainMenu(false)
                setDetailedItemName(menu)
                actions.setRenderIonContent(false)
                setDisplayNone(false)
                FetchhomeAllCompetition('1');
              }}
            >
              <img className="content-list__item-icon" src={horseIcon} alt="" />
              <IonLabel className="content-list__item-text">
                Horse Racing
              </IonLabel>
            </div>
            <div
              className="content-list__item"
              key="tennis_1"
              onClick={() => {
                const tennis = {'sport_id': '2', 'step': '1'}
                // getMenu(tennis);
                setOneDetailItem('2')
                setOneDetailName('Tennis')
                actions.setIsNavigationID('2')
                actions.setIsNavigationName('Tennis')
                onOpenDetailedMenu(!detailedSideBar)
                setSideMainMenu(false)
                setDetailedItemName(menu)
                actions.setRenderIonContent(false)
                setDisplayNone(false)
                FetchhomeAllCompetition('2');
              }}
            >
              <img className="content-list__item-icon" src={PoliticsIcon} alt="" />
              <IonLabel className="content-list__item-text">
                Politics
              </IonLabel>
            </div>
            <div
              className="content-list__item"
              key="cricket_1"
              onClick={() => {
                const cricket = {'sport_id': '4', 'step': '1'}
                // getMenu(cricket);
                setOneDetailItem('4')
                setOneDetailName('Cricket')
                actions.setIsNavigationID('4')
                actions.setIsNavigationName('Cricket')
                onOpenDetailedMenu(!detailedSideBar)
                setSideMainMenu(false)
                setDetailedItemName(menu)
                actions.setRenderIonContent(false)
                setDisplayNone(false)
                FetchhomeAllCompetition('4');
              }}
            >
              <img className="content-list__item-icon" src={GreyhoundIcon} alt="" />
              <IonLabel className="content-list__item-text">
                Greyhound Racing
              </IonLabel>
            </div>
            <div
              className="content-list__item"
              key="cricket_2"
              onClick={() => {
                const cricket = {'sport_id': '4', 'step': '1'}
                // getMenu(cricket);
                setOneDetailItem('4')
                setOneDetailName('Cricket')
                actions.setIsNavigationID('4')
                actions.setIsNavigationName('Cricket')
                onOpenDetailedMenu(!detailedSideBar)
                setSideMainMenu(false)
                setDetailedItemName(menu)
                actions.setRenderIonContent(false)
                setDisplayNone(false)
                FetchhomeAllCompetition('4');
              }}
            >
              <img className="content-list__item-icon" src={KabaddiIcon} alt="" />
              <IonLabel className="content-list__item-text">
                Kabaddi
              </IonLabel>
            </div>
            <div
              className="content-list__item"
              key="tennis_2"
              onClick={() => {
                const tennis = {'sport_id': '2', 'step': '1'}
                // getMenu(tennis);
                setOneDetailItem('2')
                setOneDetailName('Tennis')
                actions.setIsNavigationID('2')
                actions.setIsNavigationName('Tennis')
                onOpenDetailedMenu(!detailedSideBar)
                setSideMainMenu(false)
                setDetailedItemName(menu)
                actions.setRenderIonContent(false)
                setDisplayNone(false)
                FetchhomeAllCompetition('2');
              }}
            >
              <img className="content-list__item-icon" src={BinaryIcon} alt="" />
              <IonLabel className="content-list__item-text">
                Binary
              </IonLabel>
            </div>
            <div className="content-list__item" onClick={() => {
                if(user?.verifytoken) {
                  history.push('/livecasino-lobby');
                  actions.openSlotCasinoPopUp();
                }else {
                  actions.setLoginNew(true)
                }
              }}>
              <img className="content-list__item-icon" src={IntCasinoIcon} alt="" />
              <IonLabel className="content-list__item-text">Int Casino</IonLabel>
            </div>
            <div className="content-list__item" onClick={() => {
                if(user?.verifytoken) {
                  history.push('/livecasino-lobby/LIVECASINO');
                  actions.openSlotCasinoPopUp();
                }else {
                  actions.setLoginNew(true)
                }
              }}>
              <img className="content-list__item-icon" src={CasinoIcon} alt="" />
              <IonLabel className="content-list__item-text">Live Casino</IonLabel>
            </div>
            <div className="content-list__item" onClick={() => {
                if(user?.verifytoken) {
                  history.push('/livecasino-lobby');
                  actions.openSlotCasinoPopUp();
                }else {
                  actions.setLoginNew(true)
                }
              }}>
              <img className="content-list__item-icon" src={SportsbookIcon} alt="" />
              <IonLabel className="content-list__item-text">Sports book</IonLabel>
            </div>
            <div
              className="content-list__item"
              key="football_2"
              onClick={() => {
                const soccer = {'sport_id': '1', 'step': '1'}
                // getMenu(soccer);
                setOneDetailItem('1')
                setOneDetailName('Football')
                actions.setIsNavigationID('1')
                actions.setIsNavigationName('Football')
                onOpenDetailedMenu(!detailedSideBar)
                setSideMainMenu(false)
                setDetailedItemName(menu)
                actions.setRenderIonContent(false)
                setDisplayNone(false)
                FetchhomeAllCompetition('1');
              }}
            >
              <img className="content-list__item-icon" src={MartialArtsIcon} alt="" />
              <IonLabel className="content-list__item-text">
                Mixed Martial Arts
              </IonLabel>
            </div>
            <div
              className="content-list__item"
              key="cricket_3"
              onClick={() => {
                const cricket = {'sport_id': '4', 'step': '1'}
                // getMenu(cricket);
                setOneDetailItem('4')
                setOneDetailName('Cricket')
                actions.setIsNavigationID('4')
                actions.setIsNavigationName('Cricket')
                onOpenDetailedMenu(!detailedSideBar)
                setSideMainMenu(false)
                setDetailedItemName(menu)
                actions.setRenderIonContent(false)
                setDisplayNone(false)
                FetchhomeAllCompetition('4');
              }}
            >
              <img className="content-list__item-icon" src={VolleyballIcon} alt="" />
              <IonLabel className="content-list__item-text">
                Volleyball
              </IonLabel>
            </div>
            <div
              className="content-list__item"
              key="tennis_3"
              onClick={() => {
                const tennis = {'sport_id': '2', 'step': '1'}
                // getMenu(tennis);
                setOneDetailItem('2')
                setOneDetailName('Tennis')
                actions.setIsNavigationID('2')
                actions.setIsNavigationName('Tennis')
                onOpenDetailedMenu(!detailedSideBar)
                setSideMainMenu(false)
                setDetailedItemName(menu)
                actions.setRenderIonContent(false)
                setDisplayNone(false)
              }}
            >
              <img className="content-list__item-icon" src={IceHockeyIcon} alt="" />
              <IonLabel className="content-list__item-text">
                Ice Hockey
              </IonLabel>
            </div>
            <div
              className="content-list__item"
              key="football_3"
              onClick={() => {
                const soccer = {'sport_id': '1', 'step': '1'}
                // getMenu(soccer);
                setOneDetailItem('1')
                setOneDetailName('Football')
                actions.setIsNavigationID('1')
                actions.setIsNavigationName('Football')
                onOpenDetailedMenu(!detailedSideBar)
                setSideMainMenu(false)
                setDetailedItemName(menu)
                actions.setRenderIonContent(false)
                setDisplayNone(false)
              }}
            >
              <img className="content-list__item-icon" src={BasketballIcon} alt="" />
              <IonLabel className="content-list__item-text">
                Basketball
              </IonLabel>
            </div>
            <div
              className="content-list__item"
              key="cricket_4"
              onClick={() => {
                const cricket = {'sport_id': '4', 'step': '1'}
                // getMenu(cricket);
                setOneDetailItem('4')
                setOneDetailName('Cricket')
                actions.setIsNavigationID('4')
                actions.setIsNavigationName('Cricket')
                onOpenDetailedMenu(!detailedSideBar)
                setSideMainMenu(false)
                setDetailedItemName(menu)
                actions.setRenderIonContent(false)
                setDisplayNone(false)
              }}
            >
              <img className="content-list__item-icon" src={BaseballIcon} alt="" />
              <IonLabel className="content-list__item-text">
                Baseball
              </IonLabel>
            </div>
            <div
              className="content-list__item"
              key="tennis_4"
              onClick={() => {
                const tennis = {'sport_id': '2', 'step': '1'}
                // getMenu(tennis);
                setOneDetailItem('2')
                setOneDetailName('Tennis')
                actions.setIsNavigationID('2')
                actions.setIsNavigationName('Tennis')
                onOpenDetailedMenu(!detailedSideBar)
                setSideMainMenu(false)
                setDetailedItemName(menu)
                actions.setRenderIonContent(false)
                setDisplayNone(false)
              }}
            >
              <img className="content-list__item-icon" src={DartsIcon} alt="" />
              <IonLabel className="content-list__item-text">
                Darts
              </IonLabel>
            </div>
            <div
              className="content-list__item"
              key="football_4"
              onClick={() => {
                const soccer = {'sport_id': '1', 'step': '1'}
                // getMenu(soccer);
                setOneDetailItem('1')
                setOneDetailName('Football')
                actions.setIsNavigationID('1')
                actions.setIsNavigationName('Football')
                onOpenDetailedMenu(!detailedSideBar)
                setSideMainMenu(false)
                setDetailedItemName(menu)
                actions.setRenderIonContent(false)
                setDisplayNone(false)
              }}
            >
              <img className="content-list__item-icon" src={FutsalIcon} alt="" />
              <IonLabel className="content-list__item-text">
                Futsal
              </IonLabel>
            </div>
            <div
              className="content-list__item"
              key="cricket_5"
              onClick={() => {
                const cricket = {'sport_id': '4', 'step': '1'}
                // getMenu(cricket);
                setOneDetailItem('4')
                setOneDetailName('Cricket')
                actions.setIsNavigationID('4')
                actions.setIsNavigationName('Cricket')
                onOpenDetailedMenu(!detailedSideBar)
                setSideMainMenu(false)
                setDetailedItemName(menu)
                actions.setRenderIonContent(false)
                setDisplayNone(false)
              }}
            >
              <img className="content-list__item-icon" src={TableTennisIcon} alt="" />
              <IonLabel className="content-list__item-text">
                Table Tennis
              </IonLabel>
            </div>
            <div
              className="content-list__item"
              key="tennis_5"
              onClick={() => {
                const tennis = {'sport_id': '2', 'step': '1'}
                // getMenu(tennis);
                setOneDetailItem('2')
                setOneDetailName('Tennis')
                actions.setIsNavigationID('2')
                actions.setIsNavigationName('Tennis')
                onOpenDetailedMenu(!detailedSideBar)
                setSideMainMenu(false)
                setDetailedItemName(menu)
                actions.setRenderIonContent(false)
                setDisplayNone(false)
                FetchhomeAllCompetition('2');
              }}
            >
              <img className="content-list__item-icon" src={RugbyIcon} alt="" />
              <IonLabel className="content-list__item-text">
                Rugby
              </IonLabel>
            </div>
            <div className="content-list__item" onClick={() => {
                if(user?.verifytoken) {
                  actions.setIsNavigationID('99999')
                  actions.setIsNavigationName('Casino');
                  history.push('/default-page');
                  actions.openSlotCasinoPopUp();
                }else {
                  actions.setLoginNew(true)
                }
              }}>
              <img className="content-list__item-icon" src={lIVECasinoIcon} alt="" />
              <IonLabel className="content-list__item-text">Casino</IonLabel>
            </div>
            


            {/* {getMenuItems()} */}
            {/* <div className="content-list__item" 
              onClick={() => {
                if(user?.verifytoken) {
                  history.push(`/casino_live/SPB-aviator/`);
                  actions.openSlotCasinoPopUp();
                }else {
                  actions.setLoginNew(true);
                }
              }}
            >
              <img className="content-list__item-icon" src={BlastOff} alt="" />
              <IonLabel className="content-list__item-text">BlastOff</IonLabel>
            </div>
            <div className="content-list__item" 
              onClick={() => {
                if(user?.verifytoken) {
                  history.push(`/casino_live/SPB-aviator/`);
                  actions.openSlotCasinoPopUp();
                }else {
                  actions.setLoginNew(true);
                }
              }}
            >
              <img className="content-list__item-icon" src={aviator} alt="" />
              <IonLabel className="content-list__item-text">Aviator</IonLabel>
            </div>
            <div 
              className="content-list__item" 
              onClick={() => {
                history.push('/slotsgames-lobby')
                actions.openSlotCasinoPopUp();
                // actions.setSlotCasinoPopUp(true)
              }}
            >
              <img className="content-list__item-icon" src={SlotsgamesIcon} alt="" />
              <IonLabel className="content-list__item-text">Slots Games</IonLabel>
            </div> */}
            

            

            
          </div>

          <div
            className={`contentList ${
              eventDetailed
                ? 'inactiveDet'
                : displayNone
                ? 'hidden'
                : isNavigationOpen && isAnimatedMenu
                ? 'navigationBack'
                : isNavigationOpen
                ? 'navigationOpen'
                :  ''
            }`}
          >
            <div
              className="contentList__detailed"
              onClick={() => {
                history.push('/favouritesPage')
                onOpenDetailedMenu(false)
                setSideMainMenu(true)
                actions.setIsNavigationOpen(false)
                actions.setRenderIonContent(false)
              }}
            >
              <img src={StarIcon} alt="" />
              <span>Favourites</span>
            </div>
            <div
              className="contentList__detailed"
              onClick={() => {
                onOpenDetailedMenu(!detailedSideBar)
                actions.setIsNavigationOpen(false)
                actions.setRenderIonContent(false)
              }}
            >
              <img src={HomepageIcon} alt="" />
              <span>Sports</span>
            </div>
            {/* TODO: work with sports left menu desktop here */}
            <div
              className={`contentList__detailed-game ${
                oneDetailName === 'Cricket'
                  ? 'cricketTitle'
                  : oneDetailName === 'Football' ||
                    oneDetailName === 'Exchange Games'
                  ? 'footballTitle'
                  : oneDetailName === 'Tennis'
                  ? 'tennisTitle'
                  : oneDetailName === 'Horse Racing'
                  ? 'horseTitle'
                  : oneDetailName === 'Greyhound Racing'
                  ? 'greyhoundTitle'
                  : null
              }`}
            >
              <img
                src={
                  oneDetailName === 'Cricket'
                    ? CricketIconWhite
                    : oneDetailName === 'Football'
                    ? FootballIconWhite
                    : oneDetailName === 'Tennis'
                    ? TennisIconWhite
                    : oneDetailName === 'Horse Racing'
                    ? HorseIconWhite
                    : oneDetailName === 'Greyhound Racing'
                    ? GreyhoundIconWhite
                    : oneDetailName === 'Exchange Games'
                    ? ExchangeIconWhite
                    : null
                }
                alt=""
              />
              <span>{oneDetailName}</span>
            </div>
            <div
              className={`contentList__detailed-previous ${
                oneDetailName === 'Cricket'
                  ? 'cricketPrevious'
                  : oneDetailName === 'Football' ||
                    oneDetailName === 'Exchange Games'
                  ? 'footballPrevious'
                  : oneDetailName === 'Tennis'
                  ? 'tennisPrevious'
                  : oneDetailName === 'Horse Racing'
                  ? 'horsePrevious'
                  : oneDetailName === 'Greyhound Racing'
                  ? 'greyhoundPrevious'
                  : null
              }`}
              onClick={() => {
                console.log("!!!!!click prev")
                onOpenDetailedMenu(!detailedSideBar)
                // actions.getMenuSuccess([])
                actions.setIsNavigationOpen(false)
                actions.setRenderIonContent(false);
                actions.getHomeAllCompetitionSuccess([]);
                history.push('/default-page');
                setTimeout(() => {
                  actions.setIsNavigationID('')
                  actions.setIsNavigationName('')
                }, 500)
              }}
            >
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15ZM7.66789 11.9571C8.05842 12.3476 8.69158 12.3476 9.08211 11.9571C9.47263 11.5666 9.47263 10.9334 9.08211 10.5429L6.03921 7.5L9.08211 4.45711C9.47263 4.06658 9.47263 3.43342 9.08211 3.04289C8.69158 2.65237 8.05842 2.65237 7.66789 3.04289L3.9886 6.72218C3.55903 7.15176 3.55903 7.84824 3.9886 8.27782L7.66789 11.9571Z" fill={oneDetailName === 'Cricket'
                  ? '#811F0F'
                  : oneDetailName === 'Football'
                  ? 'black'
                    : oneDetailName === 'Tennis'
                  ? '#80A74C'
                    : oneDetailName === 'Horse Racing'
                  ? '#7D5048'
                    : oneDetailName === 'Greyhound Racing'
                  ? '#305765'
                  : null}/>
              </svg>
              <span
                style={{
                  display: 'inline-block',
                  paddingLeft: '10px'
                }}
              >Previous</span>
            </div>
            
            
            
            {/* TODO: detailed sideBar menu here */}
            {
              // (detailedSideBar && menu.length && oneDetailItem)?
              // oneDetailItem === '4'? (menu[0] || []):
              // oneDetailItem === '1'? (menu[1] || []):
              // oneDetailItem === '2'? (menu[2] || []): []
              
              // menuList.length?
              // menuList?.map((item, index) => {

                homeMarketEvents.homeAllCompetition?.length?
                homeMarketEvents.homeAllCompetition?.map((item, index) => {
                // console.log("Menu Item: ", item);

                // .find((item) => item.id == oneDetailItem)
                // .children?
                   // detailedItemName
                  return (
                    <div
                      key={item.id}
                      style={
                        oneDetailName === 'Horse Racing' &&
                        togleActive === index
                          ? { color: 'white', background: '#7D5048' }
                          : oneDetailName === 'Greyhound Racing' &&
                            togleActive === index
                          ? { color: 'white', background: '#305765' }
                          : {}
                      }
                      className={`contentList__detailed ${
                        oneDetailName === 'Cricket'
                          ? 'cricketCompetition'
                          : oneDetailName === 'Football' ||
                            oneDetailName === 'Exchange Games'
                          ? 'footballCompetition'
                          : oneDetailName === 'Tennis'
                          ? 'tennisCompetition'
                          : oneDetailName === 'Horse Racing'
                          ? 'horseCompetition'
                          : oneDetailName === 'Greyhound Racing'
                          ? 'greyhoundCompetition'
                          : null
                      }`}
                      onClick={() => {

                        actions.setIsNavigationOpen(false)
                        actions.setRenderIonContent(false)
                        if (
                          oneDetailName === 'Horse Racing' ||
                          oneDetailName === 'Greyhound Racing'
                        ) {
                          history.push(
                            `/event-page/${oneDetailItem}/${item.id}`
                          )
                          setTogleActive(index)
                        } else {
                          onMoreDetails(!eventDetailed)
                          setTimeout(() => { onOpenDetailedMenu(!detailedSideBar)}, 1000)
                          // console.log("Children: ", item?.data);
                          setOneMoreDetaile(item?.data)
                          setAltName(Object.values(item))
                          actions.setIsAnimatedMenu(true)
                          actions.setIsMoreDetailed(true)
                          // actions.setIsDetailedGroup(item.id)
                          actions.setIsDetailedGroup(item.name)
                          // console.log('1111', item.id)
                          actions.setIsDetailedGroupName(item.altName);

                          FetchHomeAllEvents(oneDetailItem, item.id);
                        }
                      }}
                    >
                      {oneDetailName === 'Horse Racing' ||
                      oneDetailName === 'Greyhound Racing' ? (
                        <span style={{ lineHeight: '17px', fontWeight: '500' }}>
                          {/*{item.startTime.substring(11, 16)}*/}
                          {tsToDate(
                            Date.parse(item.startTime),
                            timeSettings?.timeZoneName
                          )
                            .split(' ')[1]
                            .split(':', 2)
                            .join(':')}
                          &nbsp;
                          {item.name.split(/\d/)[0].split(' ')[0]}
                          &nbsp; ({item.countryCode})
                        </span>
                      ) : (
                        <span style={{ fontWeight: '500' }}>{item.name}</span>
                      )}
                      {/* svg image if we have more children */}
                      {/* <svg
                        className="arrowRight"
                        style={{marginRight: '10px'}}
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1.625 9.25L5.30429 5.57071C5.34334 5.53166 5.34334 5.46834 5.30429 5.42929L1.625 1.75"
                          stroke={oneDetailName === 'Cricket'
                          ? '#811F0F'
                          : oneDetailName === 'Football'
                          ? 'black'
                            : oneDetailName === 'Tennis'
                          ? '#80A74C'
                            : oneDetailName === 'Horse Racing'
                          ? '#7D5048'
                            : oneDetailName === 'Greyhound Racing'
                          ? '#305765'
                          : null}
                          stroke-width="2"
                          stroke-linecap="round"/>
                      </svg>
                      <svg
                        className="arrowRightWhite"
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.625 11.25L9.30429 7.57071C9.34334 7.53166 9.34334 7.46834 9.30429 7.42929L5.625 3.75"
                          stroke={
                            togleActive === index ? '#fff' : 'transparent'
                          }
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                      </svg> */}
                    </div>
                  )
                }) : null}
          </div>

          <div className="contentListMore">
            <div
              className="contentListMore__detailed"
              onClick={() => {
                history.push('/favouritesPage')
                onMoreDetails(!moreDetailed)
                onDisplayNone(!displayNone)
                setSideMainMenu(!sideMainMenu)
                // actions.setIsNavigationOpen(false)
                // actions.setIsAnimatedMenu(true)
                actions.setIsMoreDetailed(false)
                actions.setRenderIonContent(false)
                // actions.setIsOpenSport(false)
              }}
            >
              <img src={StarIcon} alt="" />
              <span>Favourites</span>
            </div>
            <div
              className="contentListMore__detailed"
              onClick={() => {
                // if(isOpenSport){
                //   onMoreDetails(!moreDetailed)
                //   onOpenDetailedMenu(true)
                //   setTogleActive(false)
                //   actions.setIsMoreDetailed(false)
                //   actions.setIsOpenSport(false)
                //   // onDisplayNone(!displayNone)
                //   // setItemSport([])

                // } else{
                  onMoreDetails(!moreDetailed)
                  onDisplayNone(!displayNone)
                  setSideMainMenu(!sideMainMenu)
                  actions.setIsMoreDetailed(false)
                  actions.setIsOpenSport(false)
                  actions.setRenderIonContent(false)
                  // setItemSport([])
                // }
              }}
            >
              <img src={HomepageIcon} alt="" />
              {/* <span>Sports</span> */}
            </div>
            <div
              className={`contentListMore__detailed-game ${
                oneDetailName === 'Cricket'
                  ? 'cricketTitle'
                  : oneDetailName === 'Football' ||
                    oneDetailName === 'Exchange Games'
                  ? 'footballTitle'
                  : oneDetailName === 'Tennis'
                  ? 'tennisTitle'
                  : oneDetailName === 'Horse Racing'
                  ? 'horseTitle'
                  : oneDetailName === 'Greyhound Racing'
                  ? 'greyhoundTitle'
                  : null
              }`}
            >
              <img
                src={
                  oneDetailName === 'Cricket'
                    ? CricketIconWhite
                    : oneDetailName === 'Football'
                    ? FootballIconWhite
                    : oneDetailName === 'Tennis'
                    ? TennisIconWhite
                    : oneDetailName === 'Horse Racing'
                    ? HorseIconWhite
                    : oneDetailName === 'Greyhound Racing'
                    ? GreyhoundIconWhite
                    : oneDetailName === 'Exchange Games'
                    ? ExchangeIconWhite
                    : null
                }
                alt=""
              />
              <span>{oneDetailName}</span>
            </div>
            <div
              className={`contentListMore__detailed-previous ${
                oneDetailName === 'Cricket'
                  ? 'cricketPrevious'
                  : oneDetailName === 'Football' ||
                    oneDetailName === 'Exchange Games'
                  ? 'footballPrevious'
                  : oneDetailName === 'Tennis'
                  ? 'tennisPrevious'
                  : oneDetailName === 'Horse Racing'
                  ? 'horsePrevious'
                  : oneDetailName === 'Greyhound Racing'
                  ? 'greyhoundPrevious'
                  : null
              }`}
              onClick={() => {
                  onMoreDetails(!eventDetailed)
                  onOpenDetailedMenu(true)
                  setTogleActive(false)
                  actions.setIsMoreDetailed(false)
                  actions.setIsOpenSport(false)
                  actions.setIsEventDetailed(false)
                  actions.setRenderIonContent(false)
                  actions.setIsAnimatedMenu(false)
                  // history.push(`/sports-page`)
              }}
            >
             <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15ZM7.66789 11.9571C8.05842 12.3476 8.69158 12.3476 9.08211 11.9571C9.47263 11.5666 9.47263 10.9334 9.08211 10.5429L6.03921 7.5L9.08211 4.45711C9.47263 4.06658 9.47263 3.43342 9.08211 3.04289C8.69158 2.65237 8.05842 2.65237 7.66789 3.04289L3.9886 6.72218C3.55903 7.15176 3.55903 7.84824 3.9886 8.27782L7.66789 11.9571Z" fill={oneDetailName === 'Cricket'
                  ? '#811F0F'
                  : oneDetailName === 'Football'
                  ? 'black'
                    : oneDetailName === 'Tennis'
                  ? '#80A74C'
                    : oneDetailName === 'Horse Racing'
                  ? '#7D5048'
                    : oneDetailName === 'Greyhound Racing'
                  ? '#305765'
                  : null}/>
              </svg>
              <span
              style={{
                display: 'inline-block',
                paddingLeft: '10px'
              }}
              >Previous</span>
            </div>


            {isOpenSport &&
            itemSport?.find((item) => item?.name == sportsGroupName)?.children?.map((item, index) => {

              return(
                <div
                    style={
                      oneDetailName === 'Cricket' && item.name === sportsName || oneDetailName === 'Cricket' && togleActive === index
                        ? { color: 'white', background: '#811F0F' }
                        : oneDetailName === 'Football' && item.name === sportsName || oneDetailName === 'Football' && togleActive === index
                        ? { color: 'white', background: '#000' }
                        : oneDetailName === 'Tennis' && item.name === sportsName || oneDetailName === 'Tennis' && togleActive === index
                        ? { color: 'white', background: '#80A74C' }
                        : {}
                    }
                    className={`contentListMore__detailed ${
                      oneDetailName === 'Cricket'
                        ? 'cricketCompetition'
                        : oneDetailName === 'Football' ||
                          oneDetailName === 'Exchange Games'
                        ? 'footballCompetition'
                        : oneDetailName === 'Tennis'
                        ? 'tennisCompetition'
                        : oneDetailName === 'Horse Racing'
                        ? 'horseCompetition'
                        : oneDetailName === 'Greyhound Racing'
                        ? 'greyhoundCompetition'
                        : null
                    }`}
                    onClick={(e) => {
                      oneDetailName === 'Exchange Games'
                        ? history.push(
                            `/exchange-game-page/${oneDetailItem}/${item.id}`
                          )
                        : history.push(
                            `/event-page/${oneDetailItem}/${item.id}`
                          )
                          actions.setIsMoreDetailed(false)


                      setTogleActive(index)
                      actions.setSportsName('')
                    }}
                  >
                    <span style={{ fontWeight: '500' }}>{item.name}</span>
                    <svg
                      className="arrowRight"
                        style={{marginRight: '10px'}}
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1.625 9.25L5.30429 5.57071C5.34334 5.53166 5.34334 5.46834 5.30429 5.42929L1.625 1.75"
                          stroke={oneDetailName === 'Cricket'
                          ? '#811F0F'
                          : oneDetailName === 'Football'
                          ? 'black'
                          : oneDetailName === 'Tennis'
                          ? '#80A74C'
                          : oneDetailName === 'Horse Racing'
                          ? '#7D5048'
                          : oneDetailName === 'Greyhound Racing'
                          ? '#305765'
                          : null}
                          stroke-width="2"
                          stroke-linecap="round"/>
                      </svg>
                    <svg
                      className="arrowRightWhite"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.625 11.25L9.30429 7.57071C9.34334 7.53166 9.34334 7.46834 9.30429 7.42929L5.625 3.75"
                        stroke={item.name === sportsName || togleActive === index ? '#fff' : 'transparent'}
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                    </svg>
                  </div>
                )
              })}



              {/* {oneMoreDetaile &&
               oneMoreDetaile.map((item, index) => { */}
              {homeMarketEvents.homeAllEvents?.length>0 &&
               homeMarketEvents.homeAllEvents.map((item, index) => {
                return (
                  <div
                    style={
                      oneDetailName === 'Cricket' && togleActive === index
                        ? { color: 'white', background: '#811F0F' }
                        : oneDetailName === 'Football' && togleActive === index
                        ? { color: 'white', background: '#000' }
                        : oneDetailName === 'Tennis' && togleActive === index
                        ? { color: 'white', background: '#80A74C' }
                        : {}
                    }
                    className={`contentListMore__detailed ${
                      oneDetailName === 'Cricket'
                        ? 'cricketCompetition'
                        : oneDetailName === 'Football' ||
                          oneDetailName === 'Exchange Games'
                        ? 'footballCompetition'
                        : oneDetailName === 'Tennis'
                        ? 'tennisCompetition'
                        : oneDetailName === 'Horse Racing'
                        ? 'horseCompetition'
                        : oneDetailName === 'Greyhound Racing'
                        ? 'greyhoundCompetition'
                        : null
                    }`}
                    onClick={(e) => {
                      oneDetailName === 'Exchange Games'
                        ? history.push(
                            `/exchange-game-page/${oneDetailItem}/${item.id}`
                          )
                        : history.push(
                            `/event-page/${oneDetailItem}/${item.id}`
                          )
                          actions.setIsMoreDetailed(false)


                      setTogleActive(index)
                    }}
                  >
                    <span style={{ fontWeight: '500' }}>{item.name}</span>
                    <svg
                      className="arrowRight"
                        style={{marginRight: '10px'}}
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1.625 9.25L5.30429 5.57071C5.34334 5.53166 5.34334 5.46834 5.30429 5.42929L1.625 1.75"
                          stroke={oneDetailName === 'Cricket'
                          ? '#811F0F'
                          : oneDetailName === 'Football'
                          ? 'black'
                          : oneDetailName === 'Tennis'
                          ? '#80A74C'
                          : oneDetailName === 'Horse Racing'
                          ? '#7D5048'
                          : oneDetailName === 'Greyhound Racing'
                          ? '#305765'
                          : null}
                          stroke-width="2"
                          stroke-linecap="round"/>
                      </svg>
                    <svg
                      className="arrowRightWhite"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.625 11.25L9.30429 7.57071C9.34334 7.53166 9.34334 7.46834 9.30429 7.42929L5.625 3.75"
                        stroke={item.name === sportsName || togleActive === index ? '#fff' : 'transparent'}
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                    </svg>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default connectLeftSideMenuDesktop()(LeftSideMenuDesktop)
