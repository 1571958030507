import "./AccountStatementDetailsTable.scss";
import React, {useEffect, useState} from "react";
import {connectAccountStatementDetailsTable} from "./connectAccountStatementDetailsTable";
import { tsToDate } from "../../helpers/date.helper";
import { getCurrencyFormatWithZeroAfterDot } from "../../helpers/getCurrencyFormat.helper";
import axios from "axios";
import { BASE_DEV_URL } from "../../config/api.config";
import { useSelector } from "react-redux";


const AccountStatementDetailsTable = ({detailsPnlData, selectPNLItem, totalPnL, goBack, accountStatement, timeSettings, orderList, betHistory}) => {
    const [itemDetails, setItemDetails] = useState([]);
    const [marketBets, setMarketBets] = useState([]);
    const user = useSelector((state) => state.user);


    useEffect(() => {
      if(selectPNLItem) {
        requestMerketBets(selectPNLItem.marketId);
      }
    }, []);

    const requestMerketBets = async(marketId) => {
      const data = { 
        details: { 
          username: user.details.username, 
          _id: user._id, 
          key: user.key, 
          role: user.details.role, 
          token: user.verifytoken
        }, 
        marketId: marketId 
      };

      try {
        const res = await axios.post(BASE_DEV_URL + 'getMarketBet/', data, {
          headers: { Authorization: `Bearer ${user.verifytoken}` }
        });
        if(res.status == 200 && res?.data?.success) {
          setMarketBets(res?.data?.response);
        } 
      }catch(err) {
        console.log("err: ", err);
      }
    }


    useEffect(() => {
        if (detailsPnlData?.length === 0) return;
        // setItemDetails(detailsPnlData[0].providerId === 1 ? orderList : betHistory );
        setItemDetails(detailsPnlData);
    }, [detailsPnlData]);

    const getBackSubtotal = () => {
        if (!marketBets || !marketBets?.length || marketBets?.length <= 0){
          return 0;
        } else {


        let sum = 0;
        marketBets.forEach(item => {
            if (item?.type === 'Back') {
                sum += (item?.ratestake || 0);
            }
        });
        return sum;
      }
    };
    const getLaySubtotal = () => {
      if (!marketBets || !marketBets?.length || marketBets?.length <= 0)
      {
       return 0;
     } else {

        let sum = 0;
        marketBets.forEach(item => {
            if (item?.type === "Lay") {
                sum += (item?.ratestake || 0);
            }
        });
        return sum;
      }
    };



    const getNetMarketTotal = () => {
        if (marketBets?.length <= 0) return 0;

        return (getBackSubtotal() + getLaySubtotal()) - (selectPNLItem?.commission || 0);
    };

    return (
    <section className="AccountStatementDetailsTable">
      <div className="AccountStatementDetailsTable__table">
        <h6 className="AccountStatementDetailsTable__table-title">Total P&L:&nbsp;
          <span className={totalPnL >= 0 ? "green" : "red"}>{getCurrencyFormatWithZeroAfterDot(totalPnL)}</span>
        </h6>
        <p> <span onClick={goBack} className="text-bold">Account Statements</span>
         {selectPNLItem && <span> `{'>'}` {selectPNLItem?.eventName} - {selectPNLItem?.marketName}</span>}
        </p>
        
        <div className="AccountStatementDetailsTable__table-body">
          <ul className="AccountStatementDetailsTable__table-header">
            <li>Placed</li>
            <li>Selection</li>
            <li>Type</li>
            <li>Odds</li>
            <li>Stake</li>
            <li className="info-right">Profit/Loss</li>
            <li className="info-right">Status</li>
          </ul>
          {(marketBets?.length >= 1)&& 
            marketBets?.map((item, index) =>
            <ul className="AccountStatementDetailsTable__table-body-row" key={item?._id}>
              <li>
                {/*<span className="text-bold">{tsToDate(item?.betPlacedDate, timeSettings?.timeZoneName).split(" ")[0]} </span>*/}
                <span  className="text-bold">
                  {tsToDate(item?.placedTime, timeSettings?.timeZoneName)
                  .split(" ")[0]
                  .split("/")
                  .map((dateItem) => dateItem.length === 1 ? "0" + dateItem : dateItem)
                  .join("/")}
                </span>
                <span className="text-color">| {tsToDate(item?.placedTime, timeSettings?.timeZoneName).split(" ")[1]}</span>
              </li>
              {/* <li>{item?.selectionName}</li> */}
              <li>{item?.Ireland || item?.eventName || item?.eventTypeName}</li>
              {/* <li>{item?.side === 0 ? "Back" : "Lay"}</li> */}
              <li>{item?.type}</li>
              <li>{item?.rate || '-'}</li>
              <li>{getCurrencyFormatWithZeroAfterDot(item?.stake)}</li>
              <li className={`info-right ${item?.ratestake >= 0 ? "green" : "red"}`}>{getCurrencyFormatWithZeroAfterDot(item?.ratestake)}</li>
              <li className={`info-right ${item?.result === "WON" ? "green" : "red"}`}>{item?.result}</li>
            </ul>,
          )}
        </div>
        <div className="AccountStatementDetailsTable__info">
          <ul className="AccountStatementDetailsTable__info-wrapper">
            <li>
              <span className="AccountStatementDetailsTable__info-title">Back subtotal:</span>
              <span className={`AccountStatementDetailsTable__info-price ${getBackSubtotal().toFixed(2) < 0 ? "negative-value" : ""}`}>
                {getCurrencyFormatWithZeroAfterDot(getBackSubtotal())}
              </span>
            </li>
            <li>
              <span className="AccountStatementDetailsTable__info-title">Lay subtotal</span>
              <span className={`AccountStatementDetailsTable__info-price ${getLaySubtotal().toFixed(2) < 0 ? "negative-value" : ""}`}>
                 {getCurrencyFormatWithZeroAfterDot(getLaySubtotal())}
              </span>
            </li>
            <li>
              <span className="AccountStatementDetailsTable__info-title">Market subtotal:</span>
              <span className={`AccountStatementDetailsTable__info-price ${((getBackSubtotal() + getLaySubtotal()).toFixed(2)) < 0 ? "negative-value" : ""}`}>
                {getCurrencyFormatWithZeroAfterDot(getBackSubtotal() + getLaySubtotal())}
              </span>
            </li>
            <li>
              <span className="AccountStatementDetailsTable__info-title">Commission:</span>
              <span className={`AccountStatementDetailsTable__info-price`}>
                {getCurrencyFormatWithZeroAfterDot(selectPNLItem?.commission || 0) || "0.00"}
              </span>
            </li>
            <li>
              <span className="AccountStatementDetailsTable__info-title">Net Market Total:</span>
              <span className={`AccountStatementDetailsTable__info-price AccountStatementDetailsTable__info-price-with-border ${getNetMarketTotal().toFixed(2) < 0 ? "negative-value" : ""}`} >
                {getCurrencyFormatWithZeroAfterDot(getNetMarketTotal())}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </section>
    );
};

export default connectAccountStatementDetailsTable()(AccountStatementDetailsTable);
