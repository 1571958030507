import "./AccountStatementTable.scss";
import React from "react";
import {timeToDate, tsToDate} from "../../helpers/date.helper";
import { connectAccountStatementTable } from "./connectAccountStatementTable";
import { getCurrencyFormatWithZeroAfterDot } from "../../helpers/getCurrencyFormat.helper";


const AccountStatementTable = ({accountStatement, timeSettings, setSelectPNLItem, totalPnL, showTotalPL=false}) => {

    const handleClickName = (item) => {
      setSelectPNLItem(item);
    };

    return (
    <section className="AccountStatementTable">
      <div className="AccountStatementTable__table">
        {(showTotalPL)&&
          <h6 className="AccountStatementTable__table-title">Total P&L:&nbsp;
            <span
              className={totalPnL >= 0 ? "green" : "red"}>{getCurrencyFormatWithZeroAfterDot(totalPnL)}</span>
          </h6>
        }

        <div className="AccountStatementTable__table-body">
          {(accountStatement?.length>0)?
            <div>
              <ul className="AccountStatementTable__table-header">
                <li>Sr No</li>
                <li>Date</li>
                <li>Credit</li>
                <li>Debit</li>
                <li>Balance</li>
                <li>Remark</li>
              </ul>
              {accountStatement.map((item, itemIndex) => {
                return(
                  <ul 
                    className="AccountStatementTable__table-body-row" 
                    key={item?._id}
                    onClick={() => {handleClickName(item);}}
                  >
                    <li><span className="text-color">{itemIndex + 1}</span></li>
                    <li style={{display: 'block'}}>
                      <div className="AccountStatementTable__table-body-time" style={{height: 'auto'}}>
                        <span>{tsToDate(item?.time, timeSettings?.timeZoneName)
                          .split(" ")[0].split("/")
                          .map((dateItem) => dateItem.length === 1 ? "0" + dateItem : dateItem).join("/")}
                        </span>
                      </div>
                      <span className="text-color">{tsToDate(item?.time, timeSettings?.timeZoneName).split(" ")[1]}</span>
                    </li>

                    <li className={`${item?.amount > 0 ? "green" : ""}`}>{(item?.amount>0)? getCurrencyFormatWithZeroAfterDot(item?.amount) : '-'}</li>
                    <li className={`${item?.amoun >= 0 ? "" : "red"}`}>{(item?.amount <=0)? getCurrencyFormatWithZeroAfterDot(item?.amount) : '-'}</li>
                    <li className={`${item?.newLimit >= 0 ? "green" : "red"}`}>{getCurrencyFormatWithZeroAfterDot(item?.newLimit)}</li>
                    <li>{item?.eventTypeName}</li>
                  </ul>
                );
              })}
            </div>
            :
            <div className="AccountStatementTable__table-body">
              <div 
                className="AccountStatementTable__table-body-empty"
                style={{color: '#1e8067', justifyContent: 'start', paddingLeft: '20px'}}
                >No data to display
              </div>
            </div>
          }
        </div>
      </div>
    </section>
    );
};

export default connectAccountStatementTable()(AccountStatementTable);
