import React, { useEffect, useState } from 'react'
import {
  clearIntervalAsync,
  setIntervalAsync,
} from 'set-interval-async/dynamic'
import './MenuEvent.scss'
import { connectMenuEvent } from './connect'
import {
  IonList,
  IonListHeader,
  IonItem,
  IonContent,
  IonPage,
  IonHeader,
} from '@ionic/react'
import Toolbar from '../../components/Toolbar/Toolbar'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { GET_BALANCE_INTERVAL, GET_INPLAY_POPULAR_INTERVAL } from '../../config/app.config'
import RaceListEventDetails from '../../components/RaceListEventDetails'
import LoaderContent from '../../components/LoaderContent/LoaderContent'
import FooterDesktop from '../../components/FooterDesktop/FooterDesktop'

const MenuEvent = ({ menu, menuEvent, elementsParams, actions }) => {
  const location = useLocation()
  const history = useHistory()
  const { eventTypeId } = useParams()
  const [more, setMore] = useState(false)
  const width =
    elementsParams && elementsParams?.clientWidth
      ? elementsParams?.clientWidth
      : window.innerWidth

  // const [raceOpen, setOpen] = useState(0);
  useEffect(() => {
    // actions.getEventSuccess(null);
    setMore(false)
    // setOpen(0);
    if (location.pathname.indexOf('/menu-event') !== -1) {
      if (eventTypeId == 7 || eventTypeId == 4339) {
        let mainTimer
        actions.requestMenuEventRace(eventTypeId)
        mainTimer = setIntervalAsync(async() => {
          await actions.requestMenuEventRace(eventTypeId);
        }, GET_BALANCE_INTERVAL);
        return () => clearIntervalAsync(mainTimer);
      }
      else {
        if (!menuEvent) actions.requestMenuEvent(eventTypeId)
        const timer = setIntervalAsync(async () => {
          await actions.requestMenuEvent(eventTypeId)
        }, GET_INPLAY_POPULAR_INTERVAL)

        return () => clearIntervalAsync(timer)
      }

    }
    if(location.pathname.indexOf('/event-page/') !== -1) {
      if (eventTypeId == 7 || eventTypeId == 4339) {
        let mainTimer
        actions.requestMenuEventRace(eventTypeId)
        mainTimer = setIntervalAsync(async() => {
          await actions.requestMenuEventRace(eventTypeId);
        }, GET_BALANCE_INTERVAL);
        return () => clearIntervalAsync(mainTimer);
      }
    }
    // else {
    //   if (menuEvent) {
    //     setTimeout(() => {
    //
    //       actions.getEventError()
    //     }, 100)
    //   }
    // }
  }, [location.pathname, eventTypeId])

  const competionNames = (playerNames) => {
    let eventsArray = menu.find((item) => item.id == eventTypeId)
    let competitionName = eventsArray?.children?.find((el) => el?.children?.find(item => item.name === playerNames))
    return competitionName?.name
  }

  const renderItem = (item) =>
    item.event && (
      <IonItem
        key={item.event.id}
        className="MenuEvent__list__event"
        onClick={() =>
          history.push(`/event-page/${eventTypeId}/${item.event.id}`)
        }
      >
        <div className="MenuEvent__list__event__name">
          <span style={{fontWeight: 'bold'}}>{item.event.name}</span>
          <span style={{color: 'grey', fontSize: '12px'}}>{competionNames(item.event.name)}</span>
        </div>
        <span className="MenuEvent__list__event__in-play-icon">
          <span className="apl-icon-custom-live"></span>
        </span>
      </IonItem>
    )

  const renderMenuEvents = () => {
    if (menu && location && eventTypeId != 7 && eventTypeId != 4339) {
      return width < 1024 ? (
        <IonContent className="MenuEvent" fullscreen>
          <IonListHeader className="MenuEvent__list__header bold">
            {menu.find((item) => item.id == eventTypeId)?.name ==
            'Exchange Game'
              ? 'Exchange Games'
              : menu.find((item) => item.id == eventTypeId)?.name}
          </IonListHeader>
          {/* InPlay events if exist */}
          {menuEvent && menuEvent[`${eventTypeId}`] && (
            <IonList className="MenuEvent__list in-play">
              <div className="MenuEvent__list__header__green">
                <span className="apl-icon-custom-play MenuEvent__list__header__green__name">
                  {' '}
                  In play ({menuEvent[`${eventTypeId}`].length})
                </span>
              </div>
              {/* first 5 items */}
              {menuEvent[`${eventTypeId}`]
                .slice(0, 5)
                .map((item) => renderItem(item))}
              {/* button more */}
              {menuEvent[`${eventTypeId}`].length > 5 && !more && (
                <IonItem
                  key="more"
                  className="MenuEvent__list__event"
                  onClick={() => setMore(true)}
                >
                  <div className="more">
                    <span>SEE MORE</span>
                  </div>
                </IonItem>
              )}
              {/* next items */}
              {more &&
                menuEvent[`${eventTypeId}`]
                  .slice(5)
                  .map((item) => renderItem(item))}
            </IonList>
          )}
          {/* TODO: mobile list of menu events here */}
          <IonList className="MenuEvent__list">
            <IonListHeader className="MenuEvent__list__header">
              Competitions
            </IonListHeader>
            {menu
              .find((item) => item.id == eventTypeId)?.children?.map((item) => (
                <IonItem
                  className="MenuEvent__list__event"
                  key={item.id}
                  onClick={() =>
                    history.push(`/competition/${eventTypeId}/${item.id}`)
                  }
                >
                  {item.name}
                </IonItem>
              ))}
          </IonList>
        </IonContent>
      ) : (
        <>
          <div
            style={{ height: 'fit-content', minHeight: '100%' }}
            className="MenuEvent"
          >
            <IonListHeader className="MenuEvent__list__header bold">
              {menu.find((item) => item.id == eventTypeId)?.name ==
              'Exchange Game'
                ? 'Exchange Games'
                : menu.find((item) => item.id == eventTypeId)?.name}
            </IonListHeader>
            {/* InPlay events if exist */}
            {menuEvent && menuEvent[`${eventTypeId}`] && (
              <IonList className="MenuEvent__list in-play">
                <div className="MenuEvent__list__header__green">
                  <span className="apl-icon-custom-play MenuEvent__list__header__green__name">
                    {' '}
                    In play ({menuEvent[`${eventTypeId}`].length})
                  </span>
                </div>
                {/* first 5 items */}
                {menuEvent[`${eventTypeId}`]
                  .slice(0, 5)
                  .map((item) => renderItem(item))}
                {/* button more */}
                {menuEvent[`${eventTypeId}`].length > 5 && !more && (
                  <IonItem
                    key="more"
                    className="MenuEvent__list__event"
                    onClick={() => setMore(true)}
                  >
                    <div className="more">
                      <span>SEE MORE</span>
                    </div>
                  </IonItem>
                )}
                {/* next items */}
                {more &&
                  menuEvent[`${eventTypeId}`]
                    .slice(5)
                    .map((item) => renderItem(item))}
              </IonList>
            )}
            <IonList className="MenuEvent__list">
              <IonListHeader className="MenuEvent__list__header">
                Competitions
              </IonListHeader>
              {menu
                .find((item) => item.id == eventTypeId)?.children?.map((item) => (
                  <IonItem
                    className="MenuEvent__list__event"
                    key={item.id}
                    onClick={() =>
                      history.push(`/competition/${eventTypeId}/${item.id}`)
                    }
                  >
                    {item.name}
                  </IonItem>
                ))}
            </IonList>
          </div>
          <FooterDesktop />
        </>
      )
    } else if (
      menu &&
      menuEvent &&
      menuEvent[`${eventTypeId}`] &&
      location &&
      (eventTypeId == 7 || eventTypeId == 4339)
    ) {
      return width < 1024 ? (
        <IonContent className="MenuEvent" fullscreen>
          <IonList className="MenuEvent__list in-play">
            <IonListHeader className="MenuEvent__list__header">
              {menu.find((item) => item.id == eventTypeId)?.name}
            </IonListHeader>
            <IonListHeader className="MenuEvent__list__subtitle">
              Upcoming Races
            </IonListHeader>
            <RaceListEventDetails initialOpen={0} eventTypeId={eventTypeId} />
          </IonList>
        </IonContent>
      ) : (
        <div>
          <IonList className="MenuEvent__list in-play">
            <IonListHeader className="MenuEvent__list__header">
              {menu.find((item) => item.id == eventTypeId)?.name}
            </IonListHeader>
            <IonListHeader className="MenuEvent__list__subtitle">
              Upcoming Races
            </IonListHeader>
            <RaceListEventDetails initialOpen={0} eventTypeId={eventTypeId} />
          </IonList>
        </div>
      )
    } else {
      return (
        <IonContent>
          <LoaderContent />
        </IonContent>
      )
    }
  }

  return (
    <IonPage>
      {width < 1024 ? (
        <IonHeader>
          <Toolbar />
        </IonHeader>
      ) : (
        <div style={{ height: '135px' }}></div>
      )}
      {renderMenuEvents()}
    </IonPage>
  )
}

export default connectMenuEvent()(MenuEvent)
