import './inplay.scss'
import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { IonItemGroup } from '@ionic/react'
// import InPlayPopularItemList from '../InPlayItemList/inPlayPopularItemList'
import leftSideMenuMapper from '../../helpers/getLeftSideMenuMapper.helper'
import InPlayPopularHeader from '../InPlayPopularHeader/inplay-poplular-header'
import CasinoGridList from '../CasinoGridList/CasinoGridList'
import ExchangeGamesGridList from '../ExchangeGamesGridList/ExchangeGamesGridList'
import BetbyWidget from '../BetbyWidget/BetbyWidget'
import BetbyWidgetNotAuth from '../BetbyWidget-NotAuth/BetbyWidget-notAuth'
import { useHistory } from 'react-router'
import PopularGamesGridList from '../PopularGamesGridList/PopularGamesGridList'
import GetListsItems from './getListItems/GetListsItems'
// import { InplayData } from '../../constants/InplayData'
const InPlayPopularItemList = lazy(() => import('../InPlayItemList/inPlayPopularItemList'));


const InPlayPopular = ({
  inPlayEvents,
  balanceSettings,
  timeSettings,
  type,
  displayedBack,
  setDisplayedBack,
  betPlacing,
  orderCount,
  pathname,
  promotions,
  navigationID = '',
}) => {
  const [inPlayEventsMap, setInPlayEventsMap] = useState(new Map())
  const history = useHistory()
  const auth = useSelector((state) => state.auth);
  const width = window.innerWidth;


  const updateInPlayEventsMap = useCallback(() => {
    // if (!inPlayEvents && !InplayData) {
    if (!inPlayEvents) return;

    // inPlayEventsMap.clear();
    const newMap = new Map();
    inPlayEvents.forEach((e) => {
      const evId = Number(e.eventTypeId)
      if (newMap.has(evId)) {
        newMap.get(evId).push(e);
      } else {
        newMap.set(evId, [e]);
      }
    })
    if (promotions && promotions?.popularBanners) {
      promotions.popularBanners.forEach((e) => {
        const evId = 1111111;
        if (newMap.has(evId)) {
          newMap.get(evId).push(e);
        } else {
          newMap.set(evId, [e]);
        }
      })
    }

    setInPlayEventsMap(newMap)
  }, [inPlayEvents, promotions])

  useEffect(() => {
    updateInPlayEventsMap()
  }, [updateInPlayEventsMap]);
  
  const getListsItems = () => {
    return Object.entries(leftSideMenuMapper)
      .filter(([key, value]) => { return !!inPlayEventsMap.get(value.id) && ((navigationID === '' && value.id =='4') || value.id == navigationID);})
      .map(([key, value]) => {
        switch (value.id) {
          case 77777:
            return (
              history.location.pathname !== '/favouritesPage' && (
                <div key={value.id}>
                  {/* {auth ? (
                    <BetbyWidget pathname={pathname} />
                  ) : (
                     <BetbyWidgetNotAuth startBetByWidget={startBetByWidget} pathname={pathname} />
                  )} */}
                  <CasinoGridList
                    key={value.id}
                    type={type}
                    itemHeaderData={value}
                    itemsData={inPlayEventsMap.get(value.id)}
                  />
                </div>
              )
            )

          case 1444001:
            return history.location.pathname !== '/favouritesPage' ? (
              <ExchangeGamesGridList
                key={value.id}
                type={type}
                itemHeaderData={value}
                itemsData={inPlayEventsMap.get(value.id)}
              />
            ) : (
              <InPlayPopularItemList
                type={type}
                timeSettings={timeSettings}
                betPlacing={betPlacing}
                key={value.id}
                displayedBack={displayedBack}
                setDisplayedBack={setDisplayedBack}
                itemHeaderData={value}
                balanceSetting={balanceSettings && balanceSettings[value.id]}
                itemsData={inPlayEventsMap.get(value.id).sort(a => a?.event?.name?.includes("SRL") ? 1 : -1).sort(a => a?.market?.id?.includes("BR") ? 1 : -1)}
              />
            )
          case 1111111:
            return history.location.pathname !== '/favouritesPage' ? (
              <PopularGamesGridList
                key={value.id}
                type={type}
                itemHeaderData={value}
                itemsData={inPlayEventsMap.get(value.id)}
              />
            ) : (
              <InPlayPopularItemList
                type={type}
                timeSettings={timeSettings}
                betPlacing={betPlacing}
                key={value.id}
                displayedBack={displayedBack}
                setDisplayedBack={setDisplayedBack}
                itemHeaderData={value}
                balanceSetting={balanceSettings && balanceSettings[value.id]}
                itemsData={inPlayEventsMap.get(value.id).sort(a => a?.event?.name?.includes("SRL") ? 1 : -1).sort(a => a?.market?.id?.includes("BR") ? 1 : -1)}
              />
            )

          default:
            return (
              <Suspense fallback={<div>Loading...</div>}>
                <InPlayPopularItemList
                  type={type}
                  timeSettings={timeSettings}
                  betPlacing={betPlacing}
                  key={value.id}
                  displayedBack={displayedBack}
                  setDisplayedBack={setDisplayedBack}
                  itemHeaderData={value}
                  balanceSetting={balanceSettings && balanceSettings[value.id]}
                  itemsData={inPlayEventsMap.get(value.id).sort(a => a?.event?.name?.includes("SRL") ? 1 : -1).sort(a => a?.market?.id?.includes("BR") ? 1 : -1)}
                />
              </Suspense>
            )
        }
      })
  }


  return (
    <IonItemGroup className="inplay-content">
      {width<1024?
        <InPlayPopularHeader type={type} orderCount={orderCount} />
        :
        null
      }

      <GetListsItems 
        inPlayEventsMap={inPlayEventsMap}
        type={type}
        timeSettings={timeSettings}
        betPlacing={betPlacing}
        displayedBack={displayedBack}
        setDisplayedBack={setDisplayedBack}
        balanceSettings={balanceSettings}
        navigationID={navigationID}
      />

      {/* {getListsItems()} */}
    </IonItemGroup>
  )
}

export default InPlayPopular
