import { createSlice } from '@reduxjs/toolkit'

export const betPlacingSlice = createSlice({
  name: 'betPlacing',
  initialState: {
    market: null,
    marketType: null,
    marketName: null,
    runner: null,
    isBack: null,
    price: null,
    priceActual: null,
    fancyPrice: null,
    delay: null,
    stake: null,
    confirmation: false,
    maxBet: null,
    maxWinPerMarket: null,
    line: null,
    btype: null,
    mtype: null,
    oddsType: null,
    minBet: null,
    eventTypeId: null,
    eventId: null,
    eventName: null,
    runnerName: null,
    competitionId: null,
    fromOneClick: 0,
    side: 0,
    betSlipRef: 0, // unknown
    showBetPlacingPopover: false,
    betPlacingPopoverStage2: false,
    minMaxAll: null,
    isConfirmBetPopUpOpen: false,
    isCashOut: false,
    cashOutId: null,
    cashoutMarket: null,
    betDelay: {
      matchoddsBetDelay: 0,
      soccerBetDelay: 0,
      tennisBetDelay: 0,
      bookmakerBetDelay: 0,
      fancyBetDelay: 0,
      ballbyballBetDelay: 0,
      virtualBetDelay: 0,
    },
  },
  reducers: {
    setCashOutId: (state, action) => ({ ...state, cashOutId: action.payload }),
    setIsCashOut: (state, action) => ({ ...state, isCashOut: action.payload }),
    setCashoutMarket: (state, action) => ({ ...state, cashoutMarket: action.payload }),
    setMarket: (state, action) => ({ ...state, market: action.payload }),
    setMarketType: (state, action) => ({ ...state, marketType: action.payload }),
    setMarketName: (state, action) => ({ ...state, marketName: action.payload }),
    setRunner: (state, action) => ({ ...state, runner: action.payload }),
    setRunnerName: (state, action) => ({ ...state, runnerName: action.payload }),
    setIsBack: (state, action) => ({ ...state, isBack: action.payload }),
    setPrice: (state, action) => ({ ...state, price: action.payload }),
    setPriceActual: (state, action) => ({ ...state, priceActual: action.payload }),
    setFancyPrice: (state, action) => ({ ...state, fancyPrice: action.payload }),
    setDelay: (state, action) => ({ ...state, delay: action.payload }),
    setStake: (state, action) => ({ ...state, stake: action.payload }),
    setConfirmation: (state, action) => ({ ...state, confirmation: action.payload }),
    setMaxBet: (state, action) => ({ ...state, maxBet: action.payload }),
    setMaxWinPerMarket: (state, action) => ({ ...state, maxWinPerMarket: action.payload }),
    setLine: (state, action) => ({ ...state, line: action.payload }),
    setBtype: (state, action) => ({ ...state, btype: action.payload }),
    setMtype: (state, action) => ({ ...state, mtype: action.payload }),
    setOddsType: (state, action) => ({ ...state, oddsType: action.payload }),
    setMinBet: (state, action) => ({ ...state, minBet: action.payload }),
    setEventTypeId: (state, action) => ({ ...state, eventTypeId: action.payload }),
    setEventId: (state, action) => ({ ...state, eventId: action.payload }),
    setEventName: (state, action) => ({ ...state, eventName: action.payload }),
    setCompetitionId: (state, action) => ({ ...state, competitionId: action.payload }),
    setFromOneClick: (state, action) => ({ ...state, fromOneClick: action.payload }),
    setSide: (state, action) => ({ ...state, side: action.payload }),
    setBetSlipRef: (state, action) => ({ ...state, betSlipRef: action.payload }),
    setShowBetPlacingPopover: (state, action) => ({ ...state, showBetPlacingPopover: action.payload }),
    setBetPlacingPopoverStage: (state, action) => ({ ...state, betPlacingPopoverStage2: action.payload }),
    setMinMaxAll: (state, action) => ({ ...state, minMaxAll: [...state.minMaxAll, action.payload] }),
    setStartTime: (state, action) => ({ ...state, startTime: action.payload }),
    setIsConfirmBetPopUpOpen: (state, action) => ({ ...state, isConfirmBetPopUpOpen: action.payload }),
    setBetDelay: (state, action) => ({ ...state, betDelay: action.payload }),
  },
})

export const {
  setMarket,
  setMarketType,
  setMarketName,
  setRunner,
  setRunnerName,
  setIsBack,
  setPrice,
  setPriceActual,
  setFancyPrice,
  setDelay,
  setStake,
  setConfirmation,
  setMaxBet,
  setMaxWinPerMarket,
  setLine,
  setBtype,
  setMtype,
  setOddsType,
  setMinBet,
  setEventTypeId,
  setEventId,
  setEventName,
  setCompetitionId,
  setFromOneClick,
  setSide,
  setBetSlipRef,
  setShowBetPlacingPopover,
  setBetPlacingPopoverStage,
  setDismissPopoverFunc,
  setMinMaxAll,
  setStartTime,
  setCashOutId,
  setIsCashOut,
  setCashoutMarket,
  setIsConfirmBetPopUpOpen,
  setBetDelay,
} = betPlacingSlice.actions

export default betPlacingSlice.reducer
