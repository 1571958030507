import { createSlice } from '@reduxjs/toolkit'

export const openBetsSlice = createSlice({
    name: 'openBets',
    initialState: {
        openBets: [],
        eventPageOpenBets: [],
        allEventsOpenBets: [],
    },
    reducers: {
        getOpenBetsSuccess: (state, action) => ({ ...state, openBets: action.payload}),
        getOpenBetsError: (state) => ({ ...state, openBets: []}),
        getEventPageOpenBetsSuccess: (state, action) => ({ ...state, eventPageOpenBets: action.payload}),
        getEventPageOpenBetsError: (state) => ({ ...state, eventPageOpenBets: []}),
        getAllEventsOpenBetsSuccess: (state, action) => ({ ...state, allEventsOpenBets: action.payload}),
        getAllEventsOpenBetsError: (state) => ({ ...state, allEventsOpenBets: []})
    },
})

export const {
    getOpenBetsError,
    getOpenBetsSuccess,
    setInfoDetailed,
    getEventPageOpenBetsSuccess,
    getEventPageOpenBetsError,
    getAllEventsOpenBetsSuccess,
    getAllEventsOpenBetsError
} = openBetsSlice.actions

export default openBetsSlice.reducer
