import './editStakesPopUp.scss'
import { IonInput } from '@ionic/react'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { connectEditStakesPopUp } from './EditStakesPopUp.connect'
import { useRef } from 'react'

const EditStakesPopUp = ({ user, stakes, actions, open, balanceInformationHeight, isDetailedBalanceOpen, ...props }) => {
  const isPopUpOpenDispatch = useSelector(
    (state) => state.editStakesPopUp.isOpen
  )



  //intial three fileds for classic stake
  const [stake, setStake] = useState(
    user.settings.presetStakeSettings.settings
  )
  const [stakeButtons, setStakeButtons] = useState([]);

  const saveMobileStakesSettings = () => {
    actions.requestSaveStakesSettings(user.memberCode, stake)
  }

  console.log(user.settings.presetStakeSettings.settings)

  const handleInputStake = (e, index) => {
    e.preventDefault()
    const val = Number(e.target.value)
    Number.isInteger(val) &&
      setStake(() => {
        const newStake = [...stake]
        newStake[index] = val
        return newStake
      })
  }

  useEffect(() => {
    setStake(user.settings.presetStakeSettings.settings)
  }, [open])

  useEffect(() => {
    if(stakes?.length <= 0) {
      actions.requestStakeButtons()
    }
  }, []);

  useEffect(() => {
    if(stakes?.length && !stakeButtons.length) {
      setStakeButtons(stakes);
    }
  }, [stakes, stakeButtons]);


  const handleStakeChange = (e, index) => {
    e.preventDefault();
    const numVal = Number(e.target.value);
    if(Number.isInteger(numVal)) {
      setStakeButtons((prev) =>
        prev.map((item, i) => {
          if (i === index) {
            const labelKey = `label${index + 1}`;
            const priceKey = `price${index + 1}`;
            return {
              ...item,
              [priceKey]: numVal,
              [labelKey]: e.target.value,
            };
          }
          return item;
        })
      );
    }
  }

  const handleStakeUpdate = () => {
    const requiredData = stakeButtons.reduce((acc, obj) => {
      Object.keys(obj).forEach(key => {
          acc[key] = obj[key];
      });
      return acc;
    }, {});

    actions.requestUpdateStakeButtons(requiredData).then(() => {
    }).catch(() => {
    })
  }

  return (
    <>
      {open && (
        // <div className='editStakesPopUp_wrapperrr'>
        <div className="editStakesPopUp">
          <div
            className="editStakesPopUp_wrapper"
            style={balanceInformationHeight > 30 
              ? { top: `${balanceInformationHeight + 118}px`} 
              : {}}
            onClick={(e) => {
              if (e.currentTarget === e.target) {
                actions.setIsOpen(!isPopUpOpenDispatch)
              }
            }}
          >
            <div className="editStakesPopUp_place">
              <div
                className="editStakesPopUp__fields"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <div className="editStakesPopUp__fields-container" style={{flexWrap: 'wrap', gap: '5px'}}>
                  {(stakeButtons?.length>0)&&
                    stakeButtons.map((item, index) => {
                      return(
                        <IonInput
                          key={index}
                          className="editStakesPopUp__input"
                          placeholder="0"
                          name={`firstOneclick${index}`}
                          type="text"
                          autofocus={true}
                          value={item[`price${index+1}`]}
                          onIonChange={(e) => {handleStakeChange(e, index)}}
                        />
                      );
                    })
                  }
                </div>

                


                {/* <div className="editStakesPopUp__fields-container">
                  <IonInput
                    className="editStakesPopUp__input"
                    placeholder="0"
                    name="firstOneclick"
                    type="text"
                    autofocus={true}
                    value={stake ? stake[0] : 0}
                    onIonChange={(e) => handleInputStake(e, 0)}
                  />
                  <IonInput
                    className="editStakesPopUp__input"
                    placeholder="0"
                    name="secondOneclick"
                    type="text"
                    value={stake ? stake[1] : 0}
                    onIonChange={(e) => handleInputStake(e, 1)}
                  />
                  <IonInput
                    className="editStakesPopUp__input"
                    placeholder="0"
                    name="thirdOneclick"
                    type="text"
                    value={stake ? stake[2] : 0}
                    onIonChange={(e) => handleInputStake(e, 2)}
                  />
                </div>
                <div className="editStakesPopUp__fields-container">
                  <IonInput
                    className="editStakesPopUp__input"
                    placeholder="0"
                    name="fourthOneclick"
                    type="text"
                    value={stake && stake[3]}
                    onIonChange={(e) => handleInputStake(e, 3)}
                  />
                  <IonInput
                    className="editStakesPopUp__input"
                    placeholder="0"
                    name="fifthOneclick"
                    type="text"
                    value={stake && stake[4]}
                    onIonChange={(e) => handleInputStake(e, 4)}
                  />
                  <IonInput
                    className="editStakesPopUp__input"
                    placeholder="0"
                    name="sixthOneclick"
                    type="text"
                    value={stake && stake[5]}
                    onIonChange={(e) => handleInputStake(e, 5)}
                  />
                </div> */}
              </div>
              {
                <div className="editStakesPopUp__fields-bottomContainer">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => actions.setIsOpen(!isPopUpOpenDispatch)}
                  >
                    Cancel
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      // saveMobileStakesSettings()
                      handleStakeUpdate();
                      actions.setIsOpen(!isPopUpOpenDispatch);

                    }}
                    className="pushable"
                  >
                    <span className="shadow"></span>
                    <span className="edge refreshBtn"></span>
                    <span className="front refreshBtn">Save</span>
                  </button>
                  {/* <button
                    onClick={() => {
                      saveMobileStakesSettings()
                      actions.setIsOpen(!isPopUpOpenDispatch)
                    }}
                    class="editStakesPopUp__btn"
                    // mode="md"
                  >
                    Save
                  </button> */}
                </div>
              }
            </div>
          </div>
        </div>
        // {/* </div> */}
      )}
    </>
  )
}

export default connectEditStakesPopUp()(EditStakesPopUp)
