import "./BettingProfitAndLossItemList.scss";
import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import BettingProfitAndLoseItem from "../BettingProfitAndLossItem/BettingProfitAndLossItem";
import { tsToDate } from "../../helpers/date.helper";
import { getCurrencyFormat } from "../../helpers/getCurrencyFormat.helper";

const BettingProfitAndLossItemList = ({listItemsObject, dateFrom, dateTo, timeSettings, setSelectedItemDetails}) => {

    //get window with
    const checkStateWidth = useSelector((state) => state.elementsParams.clientWidth)
    const width = checkStateWidth ? checkStateWidth : window.innerWidth
    //end get window with

    const [totalPL, setTotalPL] = useState("0");
    const [dataList, setDataList] = useState([]);

    const calculateTotalPL = () => {
        let total = 0;
        dataList.forEach(d => {
            total += d?.profitloss;
        });
        setTotalPL(total.toFixed(2));
    };

    const getValueColor = (value) => {
        return value === 0 ? "pnl-total-value--black" : value > 0 ? (width<1024? "pnl-total-value--white" : "pnl-total-value--green") : "pnl-total-value--red";
    };

    useEffect(() => {
        if(listItemsObject) {
            setDataList(Object.values(listItemsObject?.profit || {}));
            calculateTotalPL();
        }

    }, [listItemsObject]);


    if (width < 1024) {
        return (
          <div className="betting-profit-and-lose-item-list">
              <div className="betting-profit-and-lose-item-list__header">
                  {/* <span className="betting-profit-and-lose-item-list__header-date">{listItemsObject?.dateString}</span> */}
                  <span className="betting-profit-and-lose-item-list__header-total-pl">
                    <span className="betting-profit-and-lose-item-list__header-total-pl-title">P&L:</span>
                    <span className={`betting-profit-and-lose-item-list__header-total-pl-value ${getValueColor(listItemsObject?.totalprofit)}`}>{getCurrencyFormat(listItemsObject?.totalprofit) || 0}</span>
                </span>
              </div>
              <div className="betting-profit-and-lose-item-list__content">
                  {
                      dataList.map(d => {
                            return(
                                <BettingProfitAndLoseItem 
                                    key={d?.eventTypeId} 
                                    data={d} 
                                    dateFrom={dateFrom}
                                    dateTo={dateTo}
                                />

                            ); 
                      })
                  }
              </div>
          </div>
        )
    }

    return (
        <div className="betting-profit-and-lose-item-list">
            {/* <div className="betting-profit-and-lose-item-list__header">
                <span className="betting-profit-and-lose-item-list__header-date">{tsToDate(listItemsObject?.time, timeSettings?.timeZoneName)?.split(" ")[0].split("/").map((dateItem) => dateItem.length === 1 ? "0" + dateItem : dateItem).join("/")}
                </span>
                
            </div> */}
            <div className="betting-profit-and-lose-item-list__content">
                {dataList.map(d => {
                        return(
                            <BettingProfitAndLoseItem 
                                key={d?.marketId} 
                                data={d} 
                                setSelectedItemDetails={setSelectedItemDetails}
                                dateFrom={dateFrom}
                                dateTo={dateTo}
                            />
                        ); 
                    })
                }
            </div>
        </div>
    );
};

export default BettingProfitAndLossItemList;
