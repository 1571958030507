import httpAuth from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getCasinoLiveError, getCasinoLiveSuccess } from './casinoLive.slice'
import NewHttpAuth from '../../services/NewHttpAuth'


export const requestCasinoStart = (gameId = '', tableId = "") => async (dispatch, getState) => {
  // const user = getState().auth;
  // console.log("User during live: ", user);
  
  try {
    const checkAuth = getState().auth
    if (checkAuth) {
      const response = await NewHttpAuth.post('singleGame/', {
        gameId: gameId,
        tableId: tableId,
      });

      // console.log("casino Res: ", response);
      if(response?.status == 200) {
        if(response?.data?.success) {
          dispatch(getCasinoLiveSuccess(response?.data?.data))
        }else{ 
          dispatch(getCasinoLiveError())
        }
      }else{
        dispatch(getCasinoLiveError())
      }

    } else {
    }

  } catch (e) {
    dispatch(getCasinoLiveError())
    // const errorMsg = getErrorMessage(e)
    // throw errorMsg
  }
  
  // try {
  //   const checkAuth = getState().auth
  //   if ((id === "67722-1" || id === "67722-2") && !checkAuth) {
  //     dispatch(getCasinoLiveSuccess({
  //       "url": "https://games.fawk.app/" + makeId(10) + "/6666",
  //       "frame_key": "",
  //       "marketId": "7.7",
  //       "status": "OPEN"
  //     }));
  //   } else {
  //     const response = await httpAuth.post(`/casino/live/session/open`, { id, type })
  //     if (response.data.success) {
  //       dispatch(getCasinoLiveSuccess(response.data.result))
  //     }
  //     return response
  //   }

  // } catch (e) {
  //   dispatch(getCasinoLiveError())
  //   // const errorMsg = getErrorMessage(e)
  //   // throw errorMsg
  // }
}

function makeId(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const requestCasinoEnd = () => async (dispatch) => {
  dispatch(getCasinoLiveSuccess(null));
  
  // try {
  //   const response = await httpAuth.post(`/casino/live/session/close`)
  //   if (response.data.success) {
  //     dispatch(getCasinoLiveSuccess(response.data.result))
  //   }
  //   return response
  // } catch (e) {
  //   dispatch(getCasinoLiveError())
  //   // const errorMsg = getErrorMessage(e)
  //   // throw errorMsg
  // }
}
