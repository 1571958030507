export const CasinoData = [
    [
        {
            id: 'livecasino_1',
            imageUrl: 'https://winbuzz360.com/assets/images/casino/livecasino/Super+Over.webp',
            name: 'Super Over',
            gameId: "EZU-cricketwar",
            tableId: "45100",
        },
        {
            id: 'livecasino_2',
            imageUrl: 'https://winbuzz360.com/assets/images/casino/livecasino/3+Cards+Judgement.webp',
            name: '3 Cards Judgements',
            gameId: "EZU-threecardpoker",
            tableId: "227100",
        },
        {
            id: 'livecasino_3',
            imageUrl: 'https://winbuzz360.com/assets/images/casino/livecasino/Live+Teenpatti.webp',
            name: 'Teenpatti',
            gameId: "EZU-teenpatti",
            tableId: "227101",
        },
        {
            id: 'livecasino_4',
            imageUrl: 'https://winbuzz360.com/assets/images/casino/livecasino/Teenpatti+T20.webp',
            name: 'Teen Patti T20',
            gameId: "EZU-teenpatti",
            tableId: "227101",
        },
        {
            id: 'livecasino_5',
            imageUrl: 'https://winbuzz360.com/assets/images/casino/livecasino/Teenpatti+Test.webp',
            name: 'Teenpatti Test',
            gameId: "EZU-teenpatti",
            tableId: "227101",
        },
        {
            id: 'livecasino_6',
            imageUrl: 'https://winbuzz360.com/assets/images/casino/livecasino/2+Cards+Teenpatti.webp',
            name: '2 Card Teenpatti',
            gameId: "EZU-teenpatti",
            tableId: "227101",
        },
        {
            id: 'livecasino_7',
            imageUrl: 'https://winbuzz360.com/assets/images/casino/livecasino/Teenpatti+Open.webp',
            name: 'Open Teenpatti',
            gameId: "EZU-teenpatti",
            tableId: "227101",
        },
        {
            id: 'livecasino_8',
            imageUrl: 'https://winbuzz360.com/assets/images/casino/livecasino/Muflis+Teenpatti.webp',
            name: 'MUFLIS TEENPATTI',
            gameId: "EZU-teenpatti",
            tableId: "227101",
        },
        {
            id: 'livecasino_9',
            imageUrl: 'https://winbuzz360.com/assets/images/casino/livecasino/dragon_tiger.avif',
            name: 'Dragon Tiger',
            gameId: "EZU-dragontiger",
            tableId: "150",
        },
        {
            id: 'livecasino_10',
            imageUrl: 'https://winbuzz360.com/assets/images/casino/livecasino/32+cards+casino.webp',
            name: '32 Card Casino',
            gameId: "EZU-32cards",
            tableId: "228002",
        },
        {
            id: 'livecasino_11',
            imageUrl: 'https://winbuzz360.com/assets/images/casino/livecasino/Bollywood+Casino.webp',
            name: 'Bollywood Casino',
            gameId: "EZU-casinoholdem",
            tableId: "507000",
        },
        {
            id: 'livecasino_12',
            imageUrl: 'https://winbuzz360.com/assets/images/casino/livecasino/Amar+Akbar+Anthony.webp',
            name: 'Amar Akbar Anthony',
            gameId: "NE-bollywoodstory",
            tableId: "",
        },
        {
            id: 'livecasino_13',
            imageUrl: "https://winbuzz360.com/assets/images/casino/livecasino/queen.avif",
            name: 'Queen',
            gameId: "CQC-firequeen2",
            tableId: ""
        },
        {
            id: 'livecasino_14',
            imageUrl: "https://winbuzz360.com/assets/images/casino/livecasino/Baccarat.webp",
            name: 'Baccarat',
            gameId: "EZU-baccarat",
            tableId: "100"
        },
        {
            id: 'livecasino_15',
            imageUrl: "https://winbuzz360.com/assets/images/casino/livecasino/29+Card+Baccarat.webp",
            name: '29 Card Bacarrat',
            gameId: "EZU-baccarat",
            tableId: "100"
        },
        {
            id: 'livecasino_16',
            imageUrl: "https://winbuzz360.com/assets/images/casino/livecasino/Casino+War.webp",
            name: 'Casino War',
            gameId: "EZU-casinoholdem",
            tableId: "507000"
        },
        {
            id: 'livecasino_17',
            imageUrl: "https://winbuzz360.com/assets/images/casino/livecasino/Casino+Meter.webp",
            name: 'Casino Meter',
            gameId: "EZU-casinoholdem",
            tableId: "507000"
        },
        {
            id: 'livecasino_18',
            imageUrl: "https://winbuzz360.com/assets/images/casino/livecasino/Trio.webp",
            name: 'Trio',
            gameId: "WOO-tricardpoker",
            tableId: ""
        },
        {
            id: 'livecasino_19',
            imageUrl: "https://winbuzz360.com/assets/images/casino/livecasino/Hi+Low.webp",
            name: 'High Low',
            gameId: "TRB-hilo",
            tableId: ""
        },
        {
            id: 'livecasino_20',
            imageUrl: "https://winbuzz360.com/assets/images/casino/livecasino/Race+20-20.webp",
            name: 'Race 20 - 20',
            gameId: "BTG-theracemegaways",
            tableId: ""
        },
        {
            id: 'livecasino_21',
            imageUrl: "https://winbuzz360.com/assets/images/casino/livecasino/Race+to+17.webp",
            name: 'Race to 17',
            gameId: "PPL-nocommspeedbaccarat2",
            tableId: "171"
        },
        {
            id: 'livecasino_22',
            imageUrl: "https://winbuzz360.com/assets/images/casino/livecasino/7+up+%26+Down.webp",
            name: '7 Up Down',
            gameId: "EZU-lucky7",
            tableId: "228001"
        },
        {
            id: 'livecasino_23',
            imageUrl: "https://winbuzz360.com/assets/images/casino/livecasino/Andar+Bahar.webp",
            name: 'Andar Bahar',
            gameId: "EZU-andarbahar",
            tableId: "228000"
        },
        {
            id: 'livecasino_24',
            imageUrl: "https://winbuzz360.com/assets/images/casino/livecasino/Poker.webp",
            name: 'Poker',
            gameId: "WOO-tricardpoker",
            tableId: ""
        },
        {
            id: 'livecasino_25',
            imageUrl: "https://winbuzz360.com/assets/images/casino/livecasino/Poker+20-20.webp",
            name: '20 - 20 Poker',
            gameId: "WOO-tricardpoker",
            tableId: ""
        },
        {
            id: 'livecasino_26',
            imageUrl: "https://winbuzz360.com/assets/images/casino/livecasino/Six+player+poker.webp",
            name: 'Six PLayer Poker',
            gameId: "BTV-6poker",
            tableId: "227200"
        },
        {
            id: 'livecasino_27',
            imageUrl: "https://winbuzz360.com/assets/images/casino/livecasino/The+Trap.webp",
            name: 'The Trap',
            gameId: "NE-crabtrap",
            tableId: ""
        },
        {
            id: 'livecasino_28',
            imageUrl: "https://winbuzz360.com/assets/images/casino/livecasino/Worli+Matka.webp",
            name: 'Worli Matka',
            gameId: "KGL-deeprush",
            tableId: ""
        },
        {
            id: 'livecasino_29',
            imageUrl: "https://winbuzz360.com/assets/images/casino/livecasino/Roulette.webp",
            name: 'Roulette',
            gameId: "EZU-roulette",
            tableId: "541000"
        },
        {
            id: 'livecasino_30',
            imageUrl: "https://winbuzz360.com/assets/images/casino/livecasino/Sicbo.webp",
            name: 'Sicbo',
            gameId: "EZU-sicbo",
            tableId: "224000"
        },
        {
            id: 'livecasino_31',
            imageUrl: "https://winbuzz360.com/assets/images/casino/livecasino/dream_catcher.avif",
            name: 'Dream Catcher',
            gameId: "EVO-dreamcatcher",
            tableId: ""
        }
    ],

    [
        {
            id: "virtualcasino_1",
            imageUrl: "https://winbuzz360.com/assets/images/casino/virtualcasino/Baccarat+(Virtual).webp",
            name: 'Baccarat',
            gameId: "HAK-baccarat",
            tableId: ""
        },
        {
            id: "virtualcasino_2",
            imageUrl: "https://winbuzz360.com/assets/images/casino/virtualcasino/Race+20-20+(Virtual).webp",
            name: 'Race 20 - 20',
            gameId: "EVO-firstpersonvideopoker",
            tableId: ""
        },
        {
            id: "virtualcasino_3",
            imageUrl: "https://winbuzz360.com/assets/images/casino/virtualcasino/Amar+Akbar+Anthony+(Virtual).webp",
            name: 'Amar Akbar Anthony',
            gameId: "RTG-akbarromeowalter",
            tableId: ""
        },
        {
            id: "virtualcasino_4",
            imageUrl: "https://winbuzz360.com/assets/images/casino/virtualcasino/Poker+20-20+(Virtual).webp",
            name: '20 - 20 Poker',
            gameId: "EVO-firstpersonvideopoker",
            tableId: ""
        },
        {
            id: "virtualcasino_5",
            imageUrl: "https://winbuzz360.com/assets/images/casino/virtualcasino/Teenpatti+Open+(virtual).webp",
            name: 'Open Teenpatti',
        },
        {
            id: "virtualcasino_6",
            imageUrl: "https://winbuzz360.com/assets/images/casino/virtualcasino/Teenpatti+Test+(virtual).webp",
            name: 'Teenpatti Test',

        },
        {
            id: "virtualcasino_7",
            imageUrl: "https://winbuzz360.com/assets/images/casino/virtualcasino/Queen+(virtual).webp",
            name: 'Queen Race',
            gameId: "EVO-dreamcatcher",
            tableId: ""
        },
        {
            id: "virtualcasino_8",
            imageUrl: "https://winbuzz360.com/assets/images/casino/virtualcasino/Trio+(virtual).webp",
            name: 'Trio',
        },
        {
            id: "virtualcasino_9",
            imageUrl: "https://winbuzz360.com/assets/images/casino/virtualcasino/Muflis+Teenpatti+(virtual).webp",
            name: 'Muflis Teenpatti',
        },
        {
            id: "virtualcasino_10",
            imageUrl: "https://winbuzz360.com/assets/images/casino/virtualcasino/Casino+War+(Virtual).webp",
            name: 'Casino War',
            gameId: "PNG-3handcasinoholdem",
            tableId: ""
        },
        {
            id: "virtualcasino_11",
            imageUrl: "https://winbuzz360.com/assets/images/casino/virtualcasino/Casino+Meter+(Virtual).webp",
            name: 'Casino Meter',
            gameId: "PNG-3handcasinoholdem",
            tableId: ""
        },
        {
            id: "virtualcasino_12",
            imageUrl: "https://winbuzz360.com/assets/images/casino/virtualcasino/Bollywood+Casino+(Virtual).webp",
            name: 'Bollywood Casino',
            gameId: "PNG-3handcasinoholdem",
            tableId: ""
        },
        {
            id: "virtualcasino_13",
            imageUrl: "https://winbuzz360.com/assets/images/casino/virtualcasino/7+up+%26+Down+(Virtual).webp",
            name: '7 Up & Down',

        },
        {
            id: "virtualcasino_14",
            imageUrl: "https://winbuzz360.com/assets/images/casino/virtualcasino/Teenpatti+T20+(Virtual).webp",
            name: 'Teenpatti T20',

        },
        {
            id: "virtualcasino_15",
            imageUrl: "https://winbuzz360.com/assets/images/casino/virtualcasino/Andar+Bahar+(Virtual).webp",
            name: 'Andar Bahar',
            gameId: "RTG-andarbahar",
            tableId: ""
        },
        {
            id: "virtualcasino_16",
            imageUrl: "https://winbuzz360.com/assets/images/casino/virtualcasino/Hi+Low+(Virtual).webp",
            name: 'High Lower',
        },
        {
            id: "virtualcasino_17",
            imageUrl: "https://winbuzz360.com/assets/images/casino/virtualcasino/Dragon+Tiger+(Virtual).webp",
            name: 'Dragon Tiger',
            gameId: "PG-dragontiger",
            tableId: ""
        },
        {
            id: "virtualcasino_18",
            imageUrl: "https://winbuzz360.com/assets/images/casino/virtualcasino/Teenpatti+One-Day+(Virtual).webp",
            name: 'Teenpatti',
        },
        {
            id: "virtualcasino_19",
            imageUrl: "https://winbuzz360.com/assets/images/casino/virtualcasino/32+cards+casino+(Virtual).webp",
            name: '32 Card Casino',
            gameId: "PNG-3handcasinoholdem",
            tableId: ""
        },
        {
            id: "virtualcasino_20",
            imageUrl: "https://winbuzz360.com/assets/images/casino/virtualcasino/Matka+(Virtual).webp",
            name: 'Worli Matka',
        },
        {
            id: "virtualcasino_21",
            imageUrl: "https://winbuzz360.com/assets/images/casino/virtualcasino/Poker++(Virtual).webp",
            name: 'Poker',
            gameId: "GA-caribbeanstudpoker",
            tableId: ""
        },
        {
            id: "virtualcasino_22",
            imageUrl: "https://winbuzz360.com/assets/images/casino/virtualcasino/Six+player+poker+(Virtual).webp",
            name: 'Six Player Poker',
            gameId: "WAZ-americanpokerv",
            tableId: ""
        },
        {
            id: "virtualcasino_23",
            imageUrl: "https://winbuzz360.com/assets/images/casino/virtualcasino/Roulette+(Virtual).webp",
            name: 'Roulette',
            gameId: "SWC-europeanroulette",
            tableId: ""
        }
      ]
      
];