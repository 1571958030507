import './LadderPopover.scss'
import { IonButton, IonPopover } from '@ionic/react'
import React from 'react'
import { connectLadderPopover } from './LadderPopover.connect'
import { createPortal } from "react-dom";
const modalRoot = document.querySelector("#modal-root");

const LadderPopover = ({
  open,
  ladderTitle,
  ladderId,
  ladderData,
  actions
}) => {
  const handleYes = async () => {
    actions.setSportLadderShow(false)
  }

  return createPortal(
    open &&
    <div className="LadderPopover-wrapper"
    onClick={(e) => {
        if (e.currentTarget === e.target) {
          actions.setSportLadderShow(false)
        }
      }}
    >
      <div className="LadderPopover-wrapper-content">
        <div className="LadderPopover__title">{ladderData?.market?.marketName} - Ladder</div>
        <div className="LadderPopover__content">
          <div className="LadderPopover__content__row">
            <div className="LadderPopover__content__row__item">Runs</div>
            <div className="LadderPopover__content__row__item">P&L</div>
          </div>
          {/* {ladderData && ladderData[ladderId] && Array.isArray(ladderData[ladderId]) && ladderData[ladderId].map((item) => {
            return (
              <div className="LadderPopover__content__row" key={item.start}>
                <div className="LadderPopover__content__row__item LadderPopover__content__row__value">{item.start}{item.end ? ` - ${item.end}` : '+'}</div>
                <div className={`LadderPopover__content__row__item LadderPopover__content__row__value ${item.exposure > 0 ? 'positive' : 'negative'}`}>{item.exposure}</div>
              </div>
            );
          })} */}

            {ladderData?.runnerProfit&& (
              Object.entries(ladderData?.runnerProfit)?.map(([key, value]) => (
                <div className="LadderPopover__content__row" key={key}>
                  <div className="LadderPopover__content__row__item LadderPopover__content__row__value">{key}</div>
                  <div className={`LadderPopover__content__row__item LadderPopover__content__row__value ${value > 0 ? 'positive' : 'negative'}`}>{Math.round(value)}</div>
                </div>
              ))
            )}
        </div>
        <IonButton
          onClick={handleYes}
          mode="md"
          class="LadderPopover__btn"
          >OK</IonButton>
      </div>
    </div>,
    modalRoot
  )
}

export default connectLadderPopover()(LadderPopover)
