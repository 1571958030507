import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestSportVideo, requestSportVisualisation } from '../../store/sportEvent/sportEvent.thunks';

export const connectLiveSection = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      sportEventStats: state.sportEvent.sportStats,
      sportVideo: state.sportEvent.sportVideo,
      sportScore: state.sportEvent.sportScore,
      sportVisualisation: state.sportEvent.sportVisualisation,
      exchangeGames: state.exchangeGames,
      auth: state.auth, 
      elementsParams: state.elementsParams
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestSportVideo,
      requestSportVisualisation,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
