export const CasinoProviderDATA = [
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://tezcdn.com/casino/casino-provider-500*299/mac88.webp',
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://tezcdn.com/casino/casino-provider-500*299/kingmaker.webp',
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://tezcdn.com/casino/casino-provider-500*299/aesexy.webp',
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://tezcdn.com/casino/casino-provider-500*299/betgames.webp',
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://tezcdn.com/casino/casino-provider-500*299/betgames.webp',
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://tezcdn.com/casino/casino-provider-500*299/bombaylive.webp',
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://tezcdn.com/casino/casino-provider-500*299/evolution.webp',
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://tezcdn.com/casino/casino-provider-500*299/ezugi.webp',
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://tezcdn.com/casino/casino-provider-500*299/gamzix.webp',
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://tezcdn.com/casino/casino-provider-500*299/jili.webp',
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://tezcdn.com/casino/casino-provider-500*299/royalgaming.webp',
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://tezcdn.com/casino/casino-provider-500*299/spribe.webp',
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://tezcdn.com/casino/casino-provider-500*299/turbogames.webp',
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://tezcdn.com/casino/casino-provider-500*299/vivogaming.webp',
    },
    {
        eventId: '99999',
        eventType: '99999',
        eventTypeId: '99999',
        backgroundColour: '',
        image: 'https://tezcdn.com/casino/casino-provider-500*299/smartsoft.webp',
    },
];