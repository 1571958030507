import React, { useRef } from 'react';
import './Accordion.scss'; // Import your SCSS

const Accordion = ({item, index, selectedRuleId, onSelectRule}) => {
    const isOpen = selectedRuleId === item?.id;
    const panelRef = useRef(null);


    return (
        <div className="panel panel-default">
            <div className="panel-heading" role="tab" id={`heading#${index}`}>
                <h4 className="panel-title">
                    <a
                        className="collapsed-accord"
                        role="button"
                        onClick={() => {onSelectRule(item?.id);}}
                        aria-expanded={isOpen}
                        aria-controls={`collapse#${index}`}
                    >
                        {item?.name}
                    </a>
                </h4>
            </div>
            <div
                ref={panelRef}
                id={`collapse#${index}`}
                className={`panel-collapse`}
                role="tabpanel"
                aria-labelledby={`heading#${index}`}
                style={{ maxHeight: isOpen ? `${panelRef?.current?.scrollHeight}px` : '0px',}}
            >
                <div className="panel-body">{item?.content}</div>
            </div>
        </div>
    );
};

export default Accordion;
