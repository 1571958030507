import React from "react";

import { useHistory } from "react-router";
import { IonButton } from "@ionic/react";
import { connectVerification } from "./verification.connect";


import walletD from "../../assets/images/verification/walletD.svg";
import walletW from "../../assets/images/verification/walletW.svg";
import "./Verification.scss";




const Verifbuttons = ({auth, actions}) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const history = useHistory();
  
    return (
      <div className="verification">
        <div className="verification__main">
          <div className="verification__main-new">New</div>
          <div className="verification__main-title">
            Now Deposit and Withdraw directly from the Lotus365 website and mobile app
          </div>
          <div className="verification__main-buttons">
            <IonButton
              onClick={() => auth ?  history.push("/withdrawal") : actions.setLoginNew(true)}
              class="verification-button"
              mode="md"
            >
              <img src={walletW} alt="walletW" />
              Withdraw
            </IonButton>
            <IonButton
              onClick={() => auth ? history.push("/deposit") :  actions.setLoginNew(true)}
              class="verification-button deposit"
              mode="md"
            >
              <img src={walletD} alt="walletD" />
              Deposit
            </IonButton>
          </div>
        </div>
      </div>
    )
  }
export default connectVerification()(Verifbuttons);
