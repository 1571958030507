import { createSlice } from '@reduxjs/toolkit'

export const virtualEventSlice = createSlice({
    name: 'virtualEvent',
    initialState: {
        virtualData: null,
        ballByBallData: null,
        virtualLastResults: [],
        ballByBallLastResults: [],
    },
    reducers: {
        getVirtualDataSuccess: (state, action) => ({ ...state, virtualData: action.payload }),
        getVirtualDataError: (state) => ({ ...state, virtualData: null }),
        getBallByBallDataSuccess: (state, action) => ({ ...state, ballByBallData: action.payload }),
        getBallByBallDataError: (state) => ({ ...state, ballByBallData: null }),
        getVirtualLastResultsSuccess: (state, action) => ({ ...state, virtualLastResults: action.payload }),
        getVirtualLastResultsError: (state) => ({ ...state, virtualLastResults: [] }),
        getBallByBallLastResultsSuccess: (state, action) => ({ ...state, ballByBallLastResults: action.payload }),
        getBallByBallLastResultsError: (state) => ({ ...state, ballByBallLastResults: [] }),
    },
})

export const {
    getVirtualDataSuccess,
    getVirtualDataError,
    getBallByBallDataSuccess,
    getBallByBallDataError,
    getVirtualLastResultsSuccess,
    getVirtualLastResultsError,
    getBallByBallLastResultsSuccess,
    getBallByBallLastResultsError,
} = virtualEventSlice.actions

export default virtualEventSlice.reducer;
