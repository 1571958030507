import {io} from 'socket.io-client';

// https://socketetherapy.kushubmedia.com/
// https://socket.etherapypro.com/
export const Socket = io('https://api.diamond222.com/', {
  transports: ['polling'], 
  options: {}
});


Socket.on('connect_error', () => {
    console.log("1");
    Socket.io.opts.transports = ['polling', 'websocket'];
});

Socket.on('disconnect', () => {
    console.log("2");
    Socket.emit('connected');
})

Socket.on("connect", () => {
    console.log("3");
    Socket.sendBuffer = [];
});


// Socket.on('connect_error', () => {
//     // revert to classic upgrade
//     Socket.io.opts.transports = ['polling', 'websocket'];
//   });
  
  // Socket.emit('connected', () => {
  //   console.log('connected');
  // });
  
  // Socket.on('get-status', (...args) => {
  //   console.log(args);
  // });

