import httpAuth from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import moment from 'moment'
import { getAccountReportError, getAccountReportSuccess } from './account-report.slice'
import { getOrderListSuccess, getOrderListError } from '../orderList/order-list.slice'
import { BASE_DEV_URL_NEW } from '../../config/api.config'
import axios from 'axios'


export const requestAccountReport = (fromDate='', toDate='') => async (dispatch, getState) => {
    // const currentTime = (new Date()).getTime();
    // const lastEightDayTime = currentTime - (8 * 24 * 60 * 60 * 1000);
    const auth = getState().auth;

    const data = {
        from: moment(fromDate).format('YYYY-MM-DD'),
        to: moment(toDate).format('YYYY-MM-DD')
    };
    
    try {
        const response = await axios.post(BASE_DEV_URL_NEW + `userProfitloss`, data, {
            headers: {
                Authorization: `Bearer ${auth}`
            }
        });

        if (response.status == 200 && response.data.success) {
            dispatch(getAccountReportSuccess(response.data))
        }
        return response
        
    } catch (e) {
        dispatch(getAccountReportError())
        // const errorMsg = getErrorMessage(e)
        // throw errorMsg
    }
    // try {
    //     if(fromTo.fromDate !== '' && fromTo.toDate !=='') {
    //         const response = await httpAuth.get(`/account/${memberCode}/reports/pnl?from=${fromTo.fromDate}&to=${fromTo.toDate}`)
    //         if (response.data.success) {
    //             dispatch(getAccountReportSuccess(response.data.result))
    //         }
    //         return response
    //     }
    //     else {
    //         const response = await httpAuth.get(`/account/${memberCode}/reports/pnl?from=${lastEightDayTime}&to=${currentTime}`)
    //         if (response.data.success) {
    //             dispatch(getAccountReportSuccess(response.data.result))
    //         }
    //         return response
    //     }

    // } catch (e) {
    //     dispatch(getAccountReportError())
    //     // const errorMsg = getErrorMessage(e)
    //     // throw errorMsg
    // }
}


export const requestMarketPL = (data) => async (dispatch, getState) => {
    const auth = getState().auth;
    
    try {
        const response = await axios.post(BASE_DEV_URL_NEW + `eventProfitloss`, data, {
            headers: {
                Authorization: `Bearer ${auth}`
            }
        });

        if (response.status == 200 && response.data.success) {
            dispatch(getOrderListSuccess(response.data))
        }
        return response
        
    } catch (e) {
        dispatch(getOrderListError())
        // const errorMsg = getErrorMessage(e)
        // throw errorMsg
    }
}


