export const CasinoHeader = [
    {
        id: 'casino_1',
        filterName: 'LIVECASINO',
        name: 'Live Casino',
    },
    {
        id: 'casino_2',
        filterName: 'INSTANTWIN',
        name: 'Instant Win',
    },
    {
        id: 'casino_3',
        filterName: 'TABLEGAME',
        name: 'Table Game',
    },
    {
        id: 'casino_4',
        filterName: 'VIRTUAL_SPORTS',
        name: 'Virtual Sports',
    },
    {
        id: 'casino_5',
        filterName: 'VIDEOPOKER',
        name: 'Video Poker',
    },
    {
        id: 'casino_6',
        filterName: 'SHOOTING',
        name: 'Shooting',
    },
    {
        id: 'casino_7',
        filterName: 'SCRATCHCARD',
        name: 'Scretch Card',
    },
    {
        id: 'casino_8',
        filterName: 'SLOT',
        name: 'Slot',
    },
]