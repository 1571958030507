import { createSlice } from '@reduxjs/toolkit'

export const ruleRegulationPopupslice = createSlice({
  name: 'ruleRegulationPopUp',
  initialState: false,
  reducers: {
    setRuleRegulationPopUp: (state, action) => action.payload,
  },
})

export const { setRuleRegulationPopUp } = ruleRegulationPopupslice.actions

export default ruleRegulationPopupslice.reducer
