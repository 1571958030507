import { createSlice } from '@reduxjs/toolkit'

export const StakesSlice = createSlice({
  name: 'Stakes',
  initialState: [],
  reducers: {
    getStakesSuccess: (state, action) => action.payload,
    getStakesError: (state) => state,
  },
})

export const { getStakesSuccess, getStakesError } = StakesSlice.actions

export default StakesSlice.reducer
