import { createSlice } from '@reduxjs/toolkit'

export const homeMarketEventsSlice = createSlice({
    name: 'homeMarketEvents',
    initialState: {
        freeHomeAllMarkets: [],
        homeAllCompetition: [],
        homeAllEvents: [],
    },
    reducers: {
        getFreeHomeAllMarketsSuccess: (state, action) => {state.freeHomeAllMarkets = action.payload},
        getFreeHomeAllMarketsError: (state) => {state.freeHomeAllMarkets = []},
        getHomeAllCompetitionSuccess: (state, action) => {state.homeAllCompetition = action.payload},
        getHomeAllCompetitionError: (state) => {state.homeAllCompetition = []},
        getHomeAllEventsSuccess: (state, action) => {state.homeAllEvents = action.payload},
        getHomeAllEventsError: (state) => {state.homeAllEvents = []},
    },
})

export const { 
    getFreeHomeAllMarketsSuccess, getFreeHomeAllMarketsError,
    getHomeAllCompetitionSuccess, getHomeAllCompetitionError,
    getHomeAllEventsSuccess, getHomeAllEventsError,
} = homeMarketEventsSlice.actions;


export default homeMarketEventsSlice.reducer
