import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  setMarket,
  setRunner,
  setIsBack,
  setPrice,
  setPriceActual,
  setFancyPrice,
  setStake,
  setDelay,
  setMtype,
  setBtype,
  setLine,
  setRunnerName,
  setEventName,
  setShowBetPlacingPopover,
  setBetPlacingPopoverStage,
  setFromOneClick,
  setStartTime,
  setEventTypeId,
  setEventId,
  setMarketType,
  setMarketName,
  setIsCashOut
} from '../../store/betPlacing/betPlacing.slice'
import { requestBetPlacingStart } from '../../store/betPlacing/betPlacing.thunks'

export const connectOddbutton = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      auth: state.auth,
      betPlacing: state.betPlacing,
      oneClick: state.user.settings.oneClickSettings,
      balanceSettings: state.balanceSettings,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      setMarket,
      setRunner,
      setRunnerName,
      setEventName,
      setIsBack,
      setPrice,
      setPriceActual,
      setFancyPrice,
      setDelay,
      setStake,
      setMtype,
      setBtype,
      setLine,
      setShowBetPlacingPopover,
      setBetPlacingPopoverStage,
      setFromOneClick,
      requestBetPlacingStart,
      setStartTime,
      setEventTypeId,
      setEventId,
      setMarketType,
      setMarketName,
      setIsCashOut
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
