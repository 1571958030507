export const payloadSetter = (auth, betPlacing) => {
    if(betPlacing.marketType == 'SESSION') {
      let newRate;
        if (betPlacing.fancyPrice === 0) {
            newRate = 1;
        } else {
            newRate = betPlacing.fancyPrice / 100;
        }

        const payload = {
            token: auth,
            bet: {
                runnerId: 1,
                selectionName: betPlacing.priceActual,
                rate: newRate,
                stake: betPlacing.stake,
                marketId: betPlacing.market,
                marketName: betPlacing.marketName,
                marketType:"SESSION",
                eventId: betPlacing.eventId,
                eventName: betPlacing.eventName,
                type: betPlacing.isBack? 'Back' : 'Lay',
                browserdetail: '',
                ipaddress: '',
            }
        }

        return payload;
    }else {
        const payload = {
            token: auth,
            bet: {
                runnerId: betPlacing.runner,
                selectionName: betPlacing.runnerName,
                rate: betPlacing.priceActual,
                stake: betPlacing.stake,
                marketId: betPlacing.market,
                marketName: betPlacing.marketName,
                marketType: betPlacing.marketType,
                eventId: betPlacing.eventId,
                eventName: betPlacing.eventName,
                type: betPlacing.isBack? 'Back' : 'Lay',
                browserdetail: '',
                ipaddress: '',
            }
        }

        return payload;
    }
}