import React from 'react';
import './VirtualScoreCard.scss';


const VirtualScoreCard = ({virtualData}) => {

    let run1 = 0;
    let wkt1 = 0;
    for (let i = 0; i < virtualData?.scoreHomeVirtual?.length; i++) {
        run1 = JSON.parse(virtualData?.scoreHomeVirtual[i].Run) + run1;
        wkt1 = JSON.parse(virtualData?.scoreHomeVirtual[i].Wkt) + wkt1;
    }

    let run2 = 0;
    let wkt2 = 0;
    for (let i = 0; i < virtualData?.scoreAwayVirtual?.length; i++) {
        run2 = JSON.parse(virtualData?.scoreAwayVirtual[i].Run) + run2;
        wkt2 = JSON.parse(virtualData?.scoreAwayVirtual[i].Wkt) + wkt2;
    }

    let timer;
    if (virtualData?.timers) {
        if (JSON.parse(virtualData?.timers) <= 180) {
        timer = 0;
        } else {
        timer = JSON.parse(virtualData?.timers) - 180;
        }
    }

    let image2;
    if (virtualData?.Team2id == 1) {
        image2 = require(`../../../assets/images/team/1.png`);
    } else if (virtualData?.Team2id == 2) {
        image2 = require(`../../../assets/images/team/2.png`);
    } else if (virtualData?.Team2id == 3) {
        image2 = require(`../../../assets/images/team/3.png`);
    } else if (virtualData?.Team2id == 4) {
        image2 = require(`../../../assets/images/team/4.png`);
    } else if (virtualData?.Team2id == 5) {
        image2 = require(`../../../assets/images/team/5.png`);
    } else if (virtualData?.Team2id == 6) {
        image2 = require(`../../../assets/images/team/6.png`);
    } else if (virtualData?.Team2id == 7) {
        image2 = require(`../../../assets/images/team/7.png`);
    } else if (virtualData?.Team2id == 8) {
        image2 = require(`../../../assets/images/team/8.png`);
    }

    let image1;
    if (virtualData?.Team1id == 1) {
        image1 = require(`../../../assets/images/team/1.png`);
    } else if (virtualData?.Team1id == 2) {
        image1 = require(`../../../assets/images/team/2.png`);
    } else if (virtualData?.Team1id == 3) {
        image1 = require(`../../../assets/images/team/3.png`);
    } else if (virtualData?.Team1id == 4) {
        image1 = require(`../../../assets/images/team/4.png`);
    } else if (virtualData?.Team1id == 5) {
        image1 = require(`../../../assets/images/team/5.png`);
    } else if (virtualData?.Team1id == 6) {
        image1 = require(`../../../assets/images/team/6.png`);
    } else if (virtualData?.Team1id == 7) {
        image1 = require(`../../../assets/images/team/7.png`);
    } else if (virtualData?.Team1id == 8) {
        image1 = require(`../../../assets/images/team/8.png`);
    }

    // function setCounting() {
    //     let all = count - 1;
    //     if (all > 0) {
    //       dispatch({
    //         type: "COUNT",
    //         payload: all,
    //       });
    //     }
    //   }
    
    //   if (loading && placeType === "bet") {
    //     setTimeout(() => {
    //       setCounting();
    //     }, 1000);
    //   }

    return (
        <>
            <div className='virtual-container'>
                <div className="scoreboard-section">
                    <div className="row">
                        <div className="left-section">
                            <div className="teambox">
                                <div className="teamimg">
                                    <div className="teamimgbox" style={{background: `url(${image1})`}}></div>
                                </div>
                                <div className="teamnames">
                                    <h4>{virtualData?.runners[0].runnerName}</h4>
                                    <p>{run1}/{wkt1}</p>
                                </div>
                            </div>
                            <div className="teamscore">
                                {(virtualData?.scoreHomeVirtual?.length > 0)&&
                                    virtualData?.scoreHomeVirtual.map((item, index) => {
                                        return(
                                            <span key={`score2_${index}`}>
                                                {(item.Wkt != 1)? 
                                                    item.Run 
                                                    :
                                                    <span className="wicket">W</span>
                                                }
                                            </span>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    
                        <div className="timer">
                            <div className="timenexeve">
                                {(timer>0)&& <span>{timer}</span>}
                            </div>
                        </div>
                    
                        <div className="right-section">
                            <div className="teambox">
                                <div className="teamimg">
                                    <div className="teamimgbox" style={{background: `url(${image2})`}}></div>
                                </div>
                                <div className="teamnames">
                                    <h4>{virtualData?.runners[1].runnerName}</h4>
                                    <p>{run2}/{wkt2}</p>
                                </div>
                            </div>
                            <div className="teamscore">
                                {(virtualData?.scoreAwayVirtual.length > 0)&&
                                    virtualData?.scoreAwayVirtual.map((item, index) => {
                                        return(
                                            <span key={`score2_${index}`}>
                                                {(item.Wkt != 1)? 
                                                    item.Run 
                                                    :
                                                    <span className="wicket">W</span>
                                                }
                                            </span>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        {/* <section className='scoreCard-container'>
            <div className='displayScoreView'>
                <div className='displayTeamScoreView'>
                    <div className='displayteamLogoView'>
                        <img
                            className='image'
                            src={image1}
                            resizeMode="contain"
                        />
                        <p className='displayteamNameText'>{virtualData?.runners[0].runnerName}</p>
                    </div>
                    <div className='displayteamTotalScoreView'>
                        <div className='displayTeam1TotalScoreView'>
                            <p className='displayteam1TotalScoreText'>{run1}/{wkt1}</p>
                        </div>
                    </div>

                    {virtualData?.scoreHomeVirtual?.length > 0 ? (
                        <div className='displayScoreBoardView'>
                            {virtualData?.scoreHomeVirtual.map((item) => {
                                return (
                                    <div 
                                        className={item.Wkt != 1? 'displayScoreBoardRunView' : 'displayScoteBoardWicketView'}
                                        key={item.id}
                                    >
                                        <span className='displayScoreBoardRunText'>
                                            {item.Wkt != 1 ? item.Run : "W"}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    ) : null}
                </div>

                {timer > 0 && (
                  <div className='displayTimerView'>
                    <div className='displayTimerInnerView'>
                      <span className='displaytimerText'>{timer}</span>
                    </div>
                  </div>
                )}

                <div className={'displayTeamScoreView'}>
                    <div className={'displayteamLogoView'}>
                        <img
                            className={'image'}
                            src={image2}
                        />
                        <p className={'displayteamNameText'}>{virtualData?.runners[1].runnerName}</p>
                    </div>
                    <div className={'displayteamTotalScoreView'}>
                        <div className={'displayTeam2TotalScoreView'}>
                            <p className={'displayteam2TotalScoreText'}>{run2}/{wkt2}</p>
                        </div>
                    </div>

                    {virtualData?.scoreAwayVirtual.length > 0 ? (
                        <div className={'displayScoreBoardView'}>
                            {virtualData?.scoreAwayVirtual.map((item) => {
                                return (
                                <div
                                    className={(item.Wkt != 1)? 'displayScoreBoardRunView' : 'displayScoreBoardWicketView'}
                                    key={item.id}
                                >
                                    <span className={'displayScoreBoardRunText'}>
                                        {item.Wkt != 1 ? item.Run : "W"}
                                    </span>
                                </div>
                                );
                            })}
                        </div>
                    ) : null}
                </div>
              </div>
        </section> */}
        </>
    )
}

export default VirtualScoreCard;