import "./ChangePassword.scss";
import { IonContent, IonHeader, IonInput, IonItem, IonList, IonPage } from '@ionic/react'
import React, {useEffect, useState} from "react";
import Toolbar from "../../components/Toolbar/Toolbar";
import {connectChangePassword} from "./ChangePassword.connect";
import {toastr} from "react-redux-toastr";
import FooterDesktop from '../../components/FooterDesktop/FooterDesktop'
import { Socket } from "../../services/socket";

const ChangePassword = ({user, actions, elementsParams, ...props}) => {
    const [formError, setFormError] = useState("");
    const [formSuccess, setFormSuccess] = useState("");
    const [passwordOldError, setPasswordOldError] = useState("");
    const [passwordNewError, setPasswordNewError] = useState("");
    const [passwordRepeatError, setPasswordRepeatError] = useState("");
    const [formValue, setFormValue] = useState({oldPassword: "", newPassword: "", repeatPassword: ""});
    const [showLabel, setShowLabel] = useState({oldPassword: false, newPassword: false, repeatPassword: false});
    const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth


    useEffect(() => {
      Socket.on('password-changed-success', onPasswordChange);
  
      return () => {
        Socket.off('password-changed-success', onPasswordChange);
      };
    }, []);


    const onPasswordChange = (...args) => {
      if(args[0]?.success) {
        toastr.success("", args[0]?.message);
      }else{
        toastr.error("", args[0]?.message);
      }
    }


    const onFormChange = (e) => {
        setFormSuccess("");
        setFormValue({...formValue, [e.target.name]: e.target.value});
    };
    const onInputClick = (e) => {
        setShowLabel({...showLabel, [e.target.name]: true});
    };
    const onInputLeave = (e) => {
        if (!formValue[e.target.name]) setShowLabel({...showLabel, [e.target.name]: false});
    };
    const submitDesktop = async () => {
        try {
            const resPass = /^([A-Za-z0-9~`'\&quot;!@#№\?\$%\^&\*\(\)_\-\+\\=<>\|\/\.,:;\[\]{}]{6,25})$/;
      // form validation
            if (!formValue.newPassword && !formValue.oldPassword && !formValue.repeatPassword) return setFormError("Please fill all fields.");
            else if (formError) setFormError("");

            if (!formValue.oldPassword) return setPasswordOldError("This field is required");
            else if (formValue.oldPassword) setPasswordOldError("");
            if (formValue.oldPassword.length < 6 || formValue.oldPassword.length > 15) return setPasswordOldError("Password should be between 6 and 15 characters.");
            else if (passwordOldError) setPasswordOldError("");
            if (!resPass.test(formValue.oldPassword)) return setPasswordOldError("Must contain only latin letters, digits or special characters");
            else if (passwordOldError) setPasswordOldError("");

            if (!formValue.newPassword) return setPasswordNewError("This field is required");
            else if (passwordNewError) setPasswordNewError("");
            if (formValue.newPassword.length < 6 || formValue.newPassword.length > 15) return setPasswordNewError("Password should be between 6 and 15 characters.");
            else if (passwordNewError) setPasswordNewError("");
            if (!resPass.test(formValue.newPassword)) return setPasswordNewError("Must contain only latin letters, digits or special characters");
            else if (passwordNewError) setPasswordNewError("");

            if (!formValue.repeatPassword) return setPasswordRepeatError("This field is required");
            else if (passwordRepeatError) setPasswordRepeatError("");
            if (formValue.newPassword !== formValue.repeatPassword) return setPasswordRepeatError("Passwords mismatch.");
            else if (passwordRepeatError) setPasswordRepeatError("");

            // console.log("Mera User: ", user);

            // await actions.requestChangePassword(user.memberCode, {
            const sendData = {
              token: user?.verifytoken,
              password: formValue.oldPassword,
              npassword: formValue.newPassword,
              targetUser: '',
            }

            Socket.emit('password-changed', sendData);


            // await actions.requestChangePassword({
            //   token: user?.verifytoken,
            //   password: formValue.oldPassword,
            //   npassword: formValue.newPassword,
            //   // passVerify: formValue.repeatPassword,
            //   targetUser: '',
            // })
            // .then(() => {
            //     toastr.success("", "Password was changed successfully");
            //     setFormSuccess("Password was changed successfully");
            // });

          } catch (e) {
              if (e.oldPassword && e.oldPassword[0] && e.oldPassword[0].description) toastr.error("", e.oldPassword[0].description);
              else toastr.error("", "Something went wrong");
          }
    };
    const submit = async () => {
        try {
      // form validation
            if (!formValue.newPassword && !formValue.oldPassword && !formValue.repeatPassword) return setFormError("Please fill all fields.");
            else if (formError) setFormError("");
            if (!formValue.oldPassword) return setPasswordOldError("Old Password is required.");
            else if (!formValue.oldPassword) setPasswordOldError("");
            if (!formValue.newPassword) return setPasswordNewError("Password is required.");
            else if (passwordNewError) setPasswordNewError("");
            if (formValue.newPassword !== formValue.repeatPassword) return setPasswordRepeatError("Passwords mismatch.");
            else if (passwordRepeatError) setPasswordRepeatError("");
            if (formValue.newPassword.length < 6 || formValue.newPassword.length > 15) return setPasswordNewError("Password should be between 6 and 15 characters.");
            else if (passwordNewError) setPasswordNewError("");


            const sendData = {
              token: user?.verifytoken,
              password: formValue.oldPassword,
              npassword: formValue.newPassword,
              targetUser: '',
            }

            Socket.emit('password-changed', sendData);

        //     await actions.requestChangePassword(user.memberCode, {
        //         oldPassword: formValue.oldPassword,
        //         password: formValue.newPassword,
        //         passVerify: formValue.repeatPassword,
        //     })
        // .then(() => toastr.success("", "Password was changed successfully"));
        } catch (e) {
            if (e.oldPassword && e.oldPassword[0] && e.oldPassword[0].description) toastr.error("", e.oldPassword[0].description);
            else toastr.error("", "Something went wrong");
        }
    };

    if ( width < 1024 ) {return (
      <IonPage>
        <IonHeader>
          <Toolbar />
        </IonHeader>
        <IonContent className="ChangePassword" fullscreen>
          <h2 className="page-title">Change Password</h2>
          <div className="">
            <form
              className="ChangePassword__form"
              onSubmit={(e) => {
                  e.preventDefault();
                  submit();
              }}
            >
              {formError && (<div className="ChangePassword__form-error">{formError}</div>)}
              <IonList class="ChangePassword__list">
                <IonItem>
                  <IonInput
                    placeholder="Old Password"
                    name="oldPassword"
                    type="password"
                    className={`${passwordOldError ? "error" : ""}`}
                    value={formValue.oldPassword}
                    onIonChange={onFormChange}
                  />
                </IonItem>
                {passwordOldError && (<div className="ChangePassword__form-error">{passwordOldError}</div>)}
                <IonItem>
                  <IonInput
                    placeholder="New Password"
                    name="newPassword"
                    type="password"
                    className={`${passwordNewError ? "error" : ""}`}
                    value={formValue.newPassword}
                    onIonChange={onFormChange}
                  />
                </IonItem>
                {passwordNewError && (<div className="ChangePassword__form-error">{passwordNewError}</div>)}
                <IonItem>
                  <IonInput
                    placeholder="Repeat New Password"
                    name="repeatPassword"
                    type="password"
                    className={`${passwordRepeatError ? "error" : ""}`}
                    value={formValue.repeatPassword}
                    onIonChange={onFormChange}
                  />
                </IonItem>
                {passwordRepeatError && (<div className="ChangePassword__form-error">{passwordRepeatError}</div>)}
              </IonList>
              <button
                className="ChangePassword__button"
                type="submit"
                style={{background: '#1e8067'}}
              >
                Save
              </button>
            </form>
          </div>
          {/*<FooterDesktop />*/}
        </IonContent>
      </IonPage>
    );}

    return (
      // <IonPage>
      // <IonHeader>
      //   <Toolbar />
      // </IonHeader>
      <IonContent className="ChangePassword ChangePassword-desktop" fullscreen>
        <h2 className="page-title ChangePassword-desktop-page-title">Change Password</h2>
        <div className="">
          <form
            className="ChangePassword-desktop__form"
            onSubmit={(e) => {
                e.preventDefault();
                submitDesktop();
            }}
          >
            {formError && (<div className="ChangePassword-desktop__form-error">{formError}</div>)}
            {formSuccess && (<div className="ChangePassword-desktop__form-surccess">{formSuccess}</div>)}
            <div className="ChangePassword-desktop__list">
              <IonItem>
                {showLabel.oldPassword && <p className="ChangePassword-desktop__form-label">Old Password</p>}
                <IonInput
                  placeholder="Old Password"
                  name="oldPassword"
                  type="password"
                  className={`${passwordOldError ? "error" : ""}`}
                  value={formValue.oldPassword}
                  onIonChange={onFormChange}
                  onClick={onInputClick}
                  onIonBlur={onInputLeave}
                />
                {passwordOldError && (<div className="ChangePassword-desktop__form-error">{passwordOldError}</div>)}
              </IonItem>
              <IonItem>
                {showLabel.newPassword && <p className="ChangePassword-desktop__form-label">New Password</p>}
                <IonInput
                  placeholder="New Password"
                  name="newPassword"
                  type="password"
                  className={`${passwordNewError ? "error" : ""}`}
                  value={formValue.newPassword}
                  onIonChange={onFormChange}
                  onClick={onInputClick}
                  onIonBlur={onInputLeave}
                />
                {passwordNewError && (<div className="ChangePassword-desktop__form-error">{passwordNewError}</div>)}
              </IonItem>
              <IonItem>
                {showLabel.repeatPassword && <p className="ChangePassword-desktop__form-label">Repeat Password</p>}
                <IonInput
                  placeholder="Repeat Password"
                  name="repeatPassword"
                  type="password"
                  className={`${passwordRepeatError ? "error" : ""}`}
                  value={formValue.repeatPassword}
                  onIonChange={onFormChange}
                  onClick={onInputClick}
                  onIonBlur={onInputLeave}
                />
                {passwordRepeatError && (
                  <div className="ChangePassword-desktop__form-error">{passwordRepeatError}</div>)}
              </IonItem>
            </div>
            <button
              className="ChangePassword-desktop__button"
              type="submit"
            >
              Save
            </button>
          </form>
        </div>
      </IonContent>
    // </IonPage>
    );
};

export default connectChangePassword()(ChangePassword);
