export const PopularCasinoGamesData = [
    {
        ID: 'popular_casino_1',
        ImageURL: 'https://client.qtlauncher.com/images/?id=SPB-aviator_en_US&type=logo-square&version=1717639255289',
        backgroundColour: '',
        name: 'Aviator',
        gameId: "SPB-aviator",
        tableId: ""
    },
    {
        ID: 'popular_casino_2',
        ImageURL: 'https://client.qtlauncher.com/images/?id=RTG-32cards_en_US&type=logo-square&version=1713263307067',
        backgroundColour: '',
        name: '32 Cards',
        gameId: "RTG-32cards",
        tableId: ""
    },
    {
        ID: 'popular_casino_3',
        ImageURL: 'https://client.qtlauncher.com/images/?id=GLX-coinflip_en_US&type=logo-square&version=1695782766531',
        backgroundColour: '',
        name: 'Coins',
        gameId: "HAK-coins",
        tableId: ""
    },
    {
        ID: 'popular_casino_4',
        ImageURL: 'https://client.qtlauncher.com/images/?id=EZU-betonteenpatti_en_US&type=logo-square&version=1678477362730',
        backgroundColour: '',
        name: 'Teen Patti',
        gameId: "EZU-betonteenpatti",
        tableId: ""
    },
    {
        ID: 'popular_casino_5',
        ImageURL: 'https://client.qtlauncher.com/images/?id=RTG-lucky7_en_US&type=logo-square&version=1710322558011',
        backgroundColour: '',
        name: 'Lucjy 7',
        gameId: "RTG-lucky7",
    },
    {
        ID: 'popular_casino_6',
        ImageURL: 'https://client.qtlauncher.com/images/?id=EZU-dragontiger_en_US&type=logo-square&version=1716211550206',
        backgroundColour: '',
        name: 'Dragon Tiger',
        gameId: "EVO-rngdragontiger0",
        tableId: "",
    },
    {
        ID: 'popular_casino_7',
        ImageURL: 'https://client.qtlauncher.com/images/?id=EVO-autoroulettelapartage_en_US&type=logo-square&version=1702450712159',
        backgroundColour: '',
        name: 'Roulette',
        gameId: "EVO-autoroulettelapartage",
        tableId: ""
    },
    {
        ID: 'popular_casino_8',
        ImageURL: 'https://client.qtlauncher.com/images/?id=GMC-coinminer_en_US&type=logo-square&version=1694974772723',
        backgroundColour: '',
        name: 'Coin Miner',
        gameId: "GMC-coinminer",
        tableId: ""
    },
    {
        ID: 'popular_casino_9',
        ImageURL: 'https://client.qtlauncher.com/images/?id=TRB-ballball_en_US&type=logo-square&version=1689797783600',
        backgroundColour: '',
        name: 'Ball & Ball',
        gameId: "TRB-ballball",
        tableId: ""
    },
    {
        ID: 'popular_casino_10',
        ImageURL: 'https://client.qtlauncher.com/images/?id=SPB-mines_en_US&type=logo-square&version=1671526029768',
        backgroundColour: '',
        name: 'Mines',
        gameId: "SPB-mines",
        tableId: ""
    },
];