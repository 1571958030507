export const BettingEventsData = [
    {
        eventTypeId: '4',
        name: 'Cricket'
    },
    {
        eventTypeId: '2',
        name: 'Tennis'
    },
    {
        eventTypeId: '1',
        name: 'Football'
    },
    {
        eventTypeId: 'c1',
        name: 'Casino [Aura]'
    },
    {
        eventTypeId: 'c9',
        name: 'Casino [QTech]'
    },
    {
        eventTypeId: 'v9',
        name: 'Virtual'
    },
    {
        eventTypeId: 'b9',
        name: 'Ball By Ball'
    },
];