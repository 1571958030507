import './inplayitemlist.scss'
import React, { useRef, useCallback } from 'react'
import { useSelector } from 'react-redux';
import { IonList, IonListHeader } from "@ionic/react";
import InPlayItem from "../InPlayItem/inplayitem";
import { headerType } from "../../helpers/InPlayPopular.helper";
import { useHistory } from "react-router"
import EventTypeBanners from '../EventTypeBanners/EventTypeBanners'
import SportPageItem from '../SportPageItem/SportPageItem';
import moment from 'moment';
import { List, CellMeasurer, CellMeasurerCache, AutoSizer } from 'react-virtualized';



const InPlayPopularItemList = ({ type, itemHeaderData, balanceSetting, timeSettings, itemsData, displayedBack, setDisplayedBack, betPlacing }) => {

    const checkStateWidth = useSelector((state) => state.elementsParams.clientWidth)
    const width = checkStateWidth ? checkStateWidth : window.innerWidth;
    const cache = useRef(
        new CellMeasurerCache({
          fixedWidth: true,
          defaultHeight: 100,
          minHeight: 45,
        })
    );

    const getHeaderIconColor = () => {
        switch (type) {
            case headerType['INPLAY']: {
                return 'inplay-color-icon';
            }

            case headerType['POPULAR']: {
                return 'popular-color-icon';
            }

            case headerType['FUTURE']: {
                return ;
            }

            default: return 'popular-color-icon';
        }
    }

    const getHeaderIcon = () => {
        switch (itemHeaderData.id) {
            case 1: {
                return `inplay-content__logo-icon--football`
            }

            case 2: {
                return 'inplay-content__logo-icon--tennis'
            }

            case 4: {
                return 'inplay-content__logo-icon--cricket'
            }

            case 7: {
                return 'inplay-content__logo-icon--horse'
            }

            case 4339: {
                return 'inplay-content__logo-icon--greyhound'
            }

            case 77777: {
                return 'inplay-content__logo-icon--casino'
            }

            case 1444001: {
                return 'inplay-content__logo-icon--exchange-games'
            }

            case 2378962: {
                return 'inplay-content__logo-icon--election'
            }

            default: return 'inplay-content__logo-icon--exchange-games'
        }
    }

    const getBanner = () => {
        if (headerType['INPLAY'] == type) {
             return <EventTypeBanners eventType={itemHeaderData.id} />
        } else return null
    }


    const renderHeader = () => {
        if (itemsData.length == 0 || (itemsData.length == 1 && itemsData[0].eventType == 'SPORTSBOOK')) return null
        return ( 
            <IonListHeader className="inplay-item-list__header">
                <div className="inplay-item-list__header-content">
                    <div className="inplay-item-list__header-logo">
                        <i className={`inplay-content__logo-icon ${getHeaderIcon()} ${getHeaderIconColor()}`} />
                        {itemHeaderData.name}
                    </div>
                    { (itemHeaderData.id === 1 || itemHeaderData.id === 4 || (width < 1024 && itemHeaderData.id === 2) ) &&
                    <div className="inplay-item-list__header-title">
                        <span>1</span>
                        <span>X</span>
                        <span>2</span>
                    </div>
                    }
                    {width > 1024 && itemHeaderData.id === 2 &&
                    <div className="inplay-item-list__header-title">
                        <span>1</span>
                        <span>2</span>
                    </div>
                    }
                </div>
            </IonListHeader>

        );
    }


    const rowRenderer = useCallback(
        ({ index, key, parent, style }) => {
            const itemData = itemsData[index];
            if (itemData.eventType === 'SPORTSBOOK') return null;

            return (
                <CellMeasurer
                    cache={cache.current}
                    columnIndex={0}
                    // key={key}
                    key={`${index}-${itemData?._id}`}
                    parent={parent}
                    rowIndex={index}
                >
                    <div style={style}>
                        {itemData?.marketBook?.inplay || moment(itemData?.openDate).subtract(15, 'minutes').isBefore(moment()) ? (
                        <InPlayItem
                            type={type}
                            betPlacing={betPlacing}
                            timeSettings={timeSettings}
                            balanceSetting={balanceSetting}
                            displayedBack={displayedBack}
                            setDisplayedBack={setDisplayedBack}
                            itemData={itemData}
                        />
                        ) : (
                        <SportPageItem
                            type={type}
                            betPlacing={betPlacing}
                            timeSettings={timeSettings}
                            balanceSetting={balanceSetting}
                            displayedBack={displayedBack}
                            setDisplayedBack={setDisplayedBack}
                            sportItemData={itemData}
                        />
                        )}
                    </div>
                </CellMeasurer>
            );
        },
        [itemsData, type, betPlacing, timeSettings, balanceSetting, displayedBack, setDisplayedBack]
    );

    return (
        <IonList 
            className="inplay-item-list" 
            // style={{height: `${itemsData?.length * 45}px`}}
        >
            {getBanner()}
            {renderHeader()}

            {/* {itemsData && (
                <AutoSizer>
                    {({ height: autoHeight, width: autoWidth }) => (
                        <List
                            height={autoHeight}
                            width={autoWidth}
                            rowCount={itemsData.length}
                            deferredMeasurementCache={cache.current}
                            rowHeight={cache.current.rowHeight}
                            rowRenderer={rowRenderer}
                            overscanRowCount={3}
                        />
                    )}
                </AutoSizer>
            )} */}


            {(itemsData)&& 
            itemsData.map((itemData, index) => {
                if (itemData.eventType == 'SPORTSBOOK') return null;
                // if (!itemData?.players) return null
                // return ( !itemData.id ?

                // let startdate = new Date(itemData?.openDate);
                // startdate.setMinutes(startdate.getMinutes() - 15);
                // startdate < new Date()

                // return ((itemData?.marketBook?.inplay || moment(itemData?.openDate).subtract(15, 'minutes').isBefore(moment()))?

                return ((itemData?.marketBook?.inplay === true || moment(itemData?.openDate).isBefore(moment()))?
                    <InPlayItem
                      type={type}
                      betPlacing={betPlacing}
                      timeSettings={timeSettings}
                      balanceSetting={balanceSetting}
                      displayedBack={displayedBack}
                      setDisplayedBack={setDisplayedBack}
                      key={index}
                      itemData={itemData}
                    />
                    :
                    <SportPageItem
                      type={type}
                      betPlacing={betPlacing}
                      timeSettings={timeSettings}
                      balanceSetting={balanceSetting}
                      displayedBack={displayedBack}
                      setDisplayedBack={setDisplayedBack}
                      key={index}
                      sportItemData={itemData}
                    />
                )
            })}

            
        </IonList>
    )
}

export default InPlayPopularItemList
