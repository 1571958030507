// import http from '../../services/http'
import httpAuth from '../../services/httpAuth'

import getErrorMessage from '../../helpers/getErrorMessage.helper'
import {
  getSignUpSuccess,
  getSignUpError,
  setPromoListStore
} from './signUp.slice'
import axios from 'axios'
import { BASE_DEV_URL, BASE_VERIFY_OTP_URL } from "../../config/api.config";
import NewHttpAuth from '../../services/NewHttpAuth';

// export const postNumberSignUp = (memberCode) => async (dispatch) => {
//   try {
//     const response = await http.get(`/account/${memberCode}/balance-settings`)
//     if (response.data.success) {
//       dispatch(getSignUpSuccess(response.data.result))
//     }
//     return response
//   } catch (e) {
//     dispatch(getSignUpError())
//     const errorMsg = getErrorMessage(e)
//     throw errorMsg
//   }
// }
// let token 

export const postNumberSignUp = (data) => async (dispatch) => {
  try {
    const response = await NewHttpAuth.post('createOtp/', data)
    console.log("Data OTP: ", data);
    if (response.data.success) {

      // dispatch(getSignUpSuccess(response.data.success))
    }
    return response
  } catch (e) {
    dispatch(getSignUpError())
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}


export const CreateUserOTP = (data) => async (dispatch) => {
  try {
    // const response = await httpAuth.post('/auth/verifyOtp', data)
    const response = await NewHttpAuth.post('createUserOtp/', data)
    if (response.data.success) {
      // console.log("Success Response: ", response.data.success);
      // console.log(response);
      // token = response.headers.authorization
    }
    return response
  } catch (e) {
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}


export const resendOTP = (data) => async (dispatch) => {
  try {
    const response = await httpAuth.post('/auth/resendOtp', data)
    if (response.data.success) {
      console.log(response.data.success);
    }
    return response
  } catch (e) {
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}

export const verifyOTP = (data) => async (dispatch) => {
  try {
    // const response = await httpAuth.post('/auth/verifyOtp', data)
    const response = await axios.post( BASE_VERIFY_OTP_URL + '/auth/verifyOtp', data)
    if (response.data.success) {
      console.log(response.data.success);
      console.log(response);
      // token = response.headers.authorization
    }
    return response
  } catch (e) {
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}



export const postCheckPromo = (memberCode, token, data) => async (dispatch) => {
  // const memberCode = getState().user.memberCode;
  console.log('token in req', token);
  try {
    const response = await axios.post(BASE_DEV_URL + `/account/${memberCode}/promotions/promo-code`, data, {
      headers: {
        Authorization: `${token}`,
      },
    })
    if (response.data.success) {
      console.log(response.data.success);
    }
    return response
  } catch (e) {
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}

export const getPromotionsList = () => async (dispatch) => {
  try {
    const response = await httpAuth.get('/promotions')
    if (response.data.success) {
      dispatch(getSignUpSuccess(response.data.result))
      console.log(response.data.success);
      // if(response?.data?.result.length){
      // }
    }
    return response
  } catch (e) {
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}
