import "./BettingProfitAndLossDetailsTable.scss";
import React from "react";
import {tsToDate} from "../../helpers/date.helper";
import RoundResults from '../RoundResults/RoundResults'
import { getCurrencyFormatWithZeroAfterDot } from "../../helpers/getCurrencyFormat.helper";

const BettingProfitAndLossDetailsTable = ({itemDetails, selectedItemDetails, timeSettings, setSelectedItemDetails, breadcrumbNames}) => {

  // const roundId = selectedItemDetails && selectedItemDetails?.marketId.split(".")[1];
  const dataList = Object.values(itemDetails?.profit || {})

    // const getBackSubtotal = () => {
    //     if (itemDetails.length <= 0) return 0;

    //     let sum = 0;
    //     itemDetails.forEach(item => {
    //         if (item.side === 0) {
    //             sum += (item?.profitLoss || 0);
    //         }
    //     });
    //     return sum;
    // };

    // const getLaySubtotal = () => {
    //     if (itemDetails.length <= 0) return 0;

    //     let sum = 0;
    //     itemDetails.forEach(item => {
    //         if (item.side === 1) {
    //             sum += (item?.profitLoss || 0);
    //         }
    //     });
    //     return sum;
    // };

    const getNetMarketTotal = () => {
        // if (itemDetails.length <= 0) return 0;
        if (!itemDetails) return 0;

        // return (getBackSubtotal() + getLaySubtotal()) - (selectedItemDetails?.memberComm || 0);
        return itemDetails?.totalprofitloss + itemDetails?.totalcommision;
    };

    const getMarketName = (id) => {
      let market = '';
      switch (id) {
          case '4':
              market = 'Cricket';
              break;
          case '2':
              market = 'Tennis';
              break;
          case '1':
              market = 'Football';
              break;
          case 'c1':
              market = 'Casino [Aura]';
              break;
          case 'c9':
              market = 'Casino [QTech]';
              break;
          case 'v9':
              market = 'Virtual';
              break;
          case 'b9':
              market = 'Ball By Ball';
              break;
          default:
              market = '';
              break;
      }
      return market;
    }

    return (
      <div className="bettingProfitAndLossDetailsTable">
        <p className="bettingProfitAndLossDetailsTable__table-breadcrumbs">
          {/* <span onClick={() => { setSelectedItemDetails(null);}} className="text-bold">{breadcrumbNames?.buttonName}</span> */}
          {/* <span> `{'>'}` {itemDetails[0]?.eventName} - {itemDetails[0]?.marketName}</span> */}
          <span onClick={() => { setSelectedItemDetails(null);}} className="text-bold">Betting P&L</span>
          <span> `{'>'}` {getMarketName(selectedItemDetails?.eventTypeId)}</span>
        </p>
        <div className="bettingProfitAndLossDetailsTable__table-inner">
          <ul className="bettingProfitAndLossDetailsTable__table-header">
            <li>Placed</li>
            <li>Selection</li>
            {/* <li>Bet ID</li> */}
            {/* <li>Type</li> */}
            {/* <li>Odds</li>
            <li>Stake</li> */}
            <li className="info-right">Profit/Loss</li>
            <li className="info-right">Commission</li>
            <li className="info-right">Total PL</li>
          </ul>
          <div className="bettingProfitAndLossDetailsTable__table-body">
            {dataList?.length >= 1 && dataList.map((item, index) =>
              <ul className="bettingProfitAndLossDetailsTable__table-body-row" key={item?.eventId + index}>
                <li>
                  <span className="text-bold">{tsToDate(item?.date, timeSettings?.timeZoneName)?.split(" ")[0].split("/").map((dateItem) => dateItem.length === 1 ? "0" + dateItem : dateItem).join("/")}</span>
                  <span className="text-color">| {tsToDate(item?.date, timeSettings?.timeZoneName).split(" ")[1]}</span>
                </li>
                <li>{item?.eventName}</li>
                {/* <li>{item?.apolloBetId}</li> */}
                {/* <li>{item?.side === 0 ? "Back" : "Lay"}</li> */}
                {/* <li>{item?.averagePrice.toFixed(3)}</li>
                <li>{getCurrencyFormatWithZeroAfterDot(item?.size)}</li> */}
                <li className={`info-right text-bold ${item?.profitloss >= 0 ? "green" : "red"}`}>{getCurrencyFormatWithZeroAfterDot(item?.profitloss)}</li>
                <li className={`info-right ${item?.commision >= 0 ? "green" : "red"}`}>{getCurrencyFormatWithZeroAfterDot(item?.commision)}</li>
                <li className={`info-right text-bold ${item?.totalpl >=0 ? "green" : "red"}`}>{getCurrencyFormatWithZeroAfterDot(item?.totalpl)}</li>
              </ul>,
            )}
          </div>
        </div>
        <div className="bettingProfitAndLossDetailsTable__info-wrapper">
          <ul className="bettingProfitAndLossDetailsTable__info">
            {/* <li>
              <span className="bettingProfitAndLossDetailsTable__info-title">Back subtotal:</span>
              <span className={`bettingProfitAndLossDetailsTable__info-price ${getBackSubtotal().toFixed(2) < 0 ? "negative-value" : ""}`}>
                {getCurrencyFormatWithZeroAfterDot(getBackSubtotal())}
              </span>
            </li>
            <li>
              <span className="bettingProfitAndLossDetailsTable__info-title">Lay subtotal</span>
              <span className={`bettingProfitAndLossDetailsTable__info-price ${getLaySubtotal().toFixed(2) < 0 ? "negative-value" : ""}`}>
                 {getCurrencyFormatWithZeroAfterDot(getLaySubtotal())}
              </span>
            </li> */}
            <li>
              <span className="bettingProfitAndLossDetailsTable__info-title">Market subtotal:</span>
              <span className={`bettingProfitAndLossDetailsTable__info-price ${((itemDetails?.totalprofitloss)) < 0 ? "negative-value" : ""}`}>
                {getCurrencyFormatWithZeroAfterDot(itemDetails?.totalprofitloss)}
              </span>
            </li>
            <li>
              <span className="bettingProfitAndLossDetailsTable__info-title">Commission:</span>
              <span className={`bettingProfitAndLossDetailsTable__info-price`}>
                {/* {getCurrencyFormatWithZeroAfterDot(selectedItemDetails?.memberComm) || 0} */}
                {getCurrencyFormatWithZeroAfterDot(itemDetails?.totalcommision) || 0}
              </span>
            </li>
            <li>
              <span className="bettingProfitAndLossDetailsTable__info-title">Net Market Total:</span>
              <span className={`bettingProfitAndLossDetailsTable__info-price bettingProfitAndLossDetailsTable__info-price-with-border ${getNetMarketTotal().toFixed(2) < 0 ? "negative-value" : ""}`}>
                {getCurrencyFormatWithZeroAfterDot(getNetMarketTotal())}
              </span>
            </li>
          </ul>
          {/* {selectedItemDetails?.eventTypeId === "1444001" && <RoundResults roundId={roundId} />} */}
        </div>
      </div>
    );
};

export default BettingProfitAndLossDetailsTable;
