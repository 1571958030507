export const MyBetsData = [
    {
        EventName: "India vs Pakistan",
        nation: "India",
        betType: "Cricket",
        UserRate: 1.5,
        amount: 100,
        profitLoss: 50,
        placeDate: "2024-07-01T14:30:00Z",
        matchDate: "2024-07-02T14:30:00Z"
    },
    {
        EventName: "Barcelona vs Real Madrid",
        nation: "Spain",
        betType: "Football",
        UserRate: 2.1,
        amount: 200,
        profitLoss: -200,
        placeDate: "2024-06-28T17:00:00Z",
        matchDate: "2024-06-29T19:00:00Z"
    },
    {
        EventName: "USA vs Canada",
        nation: "USA",
        betType: "Tennis",
        UserRate: 1.8,
        amount: 150,
        profitLoss: 120,
        placeDate: "2024-07-05T10:00:00Z",
        matchDate: "2024-07-06T12:00:00Z"
    },
    {
        EventName: "England vs Australia",
        nation: "England",
        betType: "Cricket",
        UserRate: 1.9,
        amount: 250,
        profitLoss: -250,
        placeDate: "2024-06-25T11:30:00Z",
        matchDate: "2024-06-26T14:00:00Z"
    },
    {
        EventName: "Real Madrid vs Juventus",
        nation: "Spain",
        betType: "Football",
        UserRate: 2.5,
        amount: 300,
        profitLoss: 450,
        placeDate: "2024-07-10T15:00:00Z",
        matchDate: "2024-07-11T17:00:00Z"
    },
    {
        EventName: "India vs Australia",
        nation: "India",
        betType: "Cricket",
        UserRate: 1.7,
        amount: 200,
        profitLoss: null,
        placeDate: "2024-07-03T14:30:00Z",
        matchDate: "2024-07-04T14:30:00Z"
    },
    {
        EventName: "France vs Germany",
        nation: "France",
        betType: "Football",
        UserRate: 2.3,
        amount: 100,
        profitLoss: 130,
        placeDate: "2024-06-30T16:00:00Z",
        matchDate: "2024-07-01T18:00:00Z"
    },
    {
        EventName: "Serena Williams vs Naomi Osaka",
        nation: "USA",
        betType: "Tennis",
        UserRate: 1.6,
        amount: 150,
        profitLoss: -150,
        placeDate: "2024-07-07T11:00:00Z",
        matchDate: "2024-07-08T13:00:00Z"
    },
    {
        EventName: "Brazil vs Argentina",
        nation: "Brazil",
        betType: "Football",
        UserRate: 2.0,
        amount: 250,
        profitLoss: 500,
        placeDate: "2024-06-27T18:00:00Z",
        matchDate: "2024-06-28T20:00:00Z"
    },
    {
        EventName: "New Zealand vs South Africa",
        nation: "New Zealand",
        betType: "Cricket",
        UserRate: 1.4,
        amount: 100,
        profitLoss: 40,
        placeDate: "2024-07-12T12:00:00Z",
        matchDate: "2024-07-13T14:30:00Z"
    },
    {
        EventName: "Japan vs South Korea",
        nation: "Japan",
        betType: "Football",
        UserRate: 2.2,
        amount: 200,
        profitLoss: -200,
        placeDate: "2024-07-08T14:00:00Z",
        matchDate: "2024-07-09T16:00:00Z"
    },
    {
        EventName: "Roger Federer vs Rafael Nadal",
        nation: "Switzerland",
        betType: "Tennis",
        UserRate: 1.9,
        amount: 300,
        profitLoss: 270,
        placeDate: "2024-07-11T09:00:00Z",
        matchDate: "2024-07-12T11:00:00Z"
    },
    {
        EventName: "India vs England",
        nation: "India",
        betType: "Cricket",
        UserRate: 1.5,
        amount: 250,
        profitLoss: -250,
        placeDate: "2024-07-02T13:30:00Z",
        matchDate: "2024-07-03T14:30:00Z"
    },
    {
        EventName: "Chelsea vs Manchester United",
        nation: "England",
        betType: "Football",
        UserRate: 2.0,
        amount: 200,
        profitLoss: null,
        placeDate: "2024-07-06T17:00:00Z",
        matchDate: "2024-07-07T19:00:00Z"
    },
    {
        EventName: "Sri Lanka vs Pakistan",
        nation: "Sri Lanka",
        betType: "Cricket",
        UserRate: 1.6,
        amount: 150,
        profitLoss: 90,
        placeDate: "2024-07-04T12:30:00Z",
        matchDate: "2024-07-05T14:30:00Z"
    },
    {
        EventName: "Italy vs Spain",
        nation: "Italy",
        betType: "Football",
        UserRate: 2.4,
        amount: 300,
        profitLoss: 420,
        placeDate: "2024-06-29T16:00:00Z",
        matchDate: "2024-06-30T18:00:00Z"
    },
    {
        EventName: "Australia vs West Indies",
        nation: "Australia",
        betType: "Cricket",
        UserRate: 1.7,
        amount: 200,
        profitLoss: -200,
        placeDate: "2024-07-09T11:30:00Z",
        matchDate: "2024-07-10T14:30:00Z"
    },
    {
        EventName: "Mexico vs USA",
        nation: "Mexico",
        betType: "Football",
        UserRate: 2.1,
        amount: 100,
        profitLoss: 110,
        placeDate: "2024-07-01T15:00:00Z",
        matchDate: "2024-07-02T17:00:00Z"
    },
    {
        EventName: "Djokovic vs Thiem",
        nation: "Serbia",
        betType: "Tennis",
        UserRate: 1.8,
        amount: 150,
        profitLoss: null,
        placeDate: "2024-07-10T12:00:00Z",
        matchDate: "2024-07-11T14:00:00Z"
    },
    {
        EventName: "Germany vs Netherlands",
        nation: "Germany",
        betType: "Football",
        UserRate: 2.3,
        amount: 250,
        profitLoss: -250,
        placeDate: "2024-06-26T18:00:00Z",
        matchDate: "2024-06-27T20:00:00Z"
    }
];;