export const ExchangeGamesData = [
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/dragontiger.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150001',
        gameId: "EVO-rngdragontiger0",
        tableId: "",
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/20-20poker.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
        gameId: "BTV-6poker",
        tableId: ""
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/onedayteenpatti.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
        gameId: "BTL-betonteenpatti",
        tableId: ""
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/poker.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
        gameId: "GA-caribbeanstudpoker",
        tableId: ""
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/baccarat.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
        gameId: "BTV-baccarat",
        tableId: ""
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/sicbo.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
        gameId: "EZU-sicbo",
        tableId: "224000"
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/roulette.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
        gameId: "EZU-roulette",
        tableId: "541000"
    },
    {
        image: 'https://client.qtlauncher.com/images/?id=1x2-spinocricket_en_US&type=logo-square&version=1676295305923',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
        gameId: "1x2-spinocricket",
        tableId: ""
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/lucky7.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
        gameId: "GA-10luckysevems",
        tableId: ""
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/andarbahar.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
        gameId: "OT-andarbahar",
        tableId: ""
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/teenpattitest.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
        gameId: "BTL-betonteenpatti",
        tableId: ""
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/32cards.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
        gameId: "EZU-32cards",
        tableId: ""
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/worlimatka.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
        gameId: "KGL-deeprush",
        tableId: ""
    },
    {
        image: 'https://winbuzz360.com/assets/images/casino/livecasino/Race+20-20.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
        gameId: "BTG-theracemegaways",
        tableId: ""
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/bollywoodcasino.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
        gameId: "EZU-casinoholdem",
        tableId: "507000"
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/amarakbaranthony.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
        gameId: "NE-bollywoodstory",
        tableId: ""
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/2cardsteenpatti.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
        gameId: "EZU-teenpatti",
        tableId: "227101"
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/3cardjudgement.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
        gameId: "EZU-threecardpoker",
        tableId: "227100"
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/blue/trio.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
        gameId: "WOO-tricardpoker",
        tableId: ""
    },
    {
        image: 'https://winbuzz360.com/assets/images/casino/livecasino/Race+to+17.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
        gameId: "PPL-nocommspeedbaccarat2",
        tableId: "171"
    },
    {
        image: 'https://client.qtlauncher.com/images/?id=KIR-eurofastleaguefootballsingle_en_US&type=logo-square&version=1717054466139',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
        gameId: "KIR-eurofastleaguefootballsingle",
        tableId: ""
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/mac88-virtual/2cardteenpatti.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
        gameId: "EZU-teenpatti",
        tableId: "227101"
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/mac88-virtual/poker.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
        gameId: "WOO-tricardpoker",
        tableId: ""
    },
    {
        image: 'https://tezcdn.com/casino/mac88-500*299/mac88-virtual/highcard.webp',
        backgroundColour: '',
        eventTypeId: '99995',
        eventId: '150036',
        gameId: "TRB-hilo",
        tableId: ""
    },
];