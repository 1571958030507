import React, { useState, useEffect, memo, useRef } from 'react'
import './SportMarketTabs.scss';
import SportMarketStandard from '../../components/SportMarketStandard/SportMarketStandard';
import { useDispatch, useSelector } from 'react-redux'
import { requestBetPlacingStartAll } from '../../store/betPlacing/betPlacing.thunks'

const SportMarketTabs = ({ data, betPlacing, elementsParams }) => {
    // console.log("Item Bolte: ", data);

    const [activeTab, setActiveTab] = useState(0);
    // const dispatch = useDispatch();
    //
    // useEffect(() => {
    //     if (data?.tabs[activeTab]?.name === "Fancy") {
    //         dispatch(requestBetPlacingStartAll(data?.tabs[activeTab].items))
    //     }
    //     }, [activeTab]);


    useEffect(() => {
        if (!data.tabs[activeTab]) setActiveTab(Object.keys(data.tabs)[0])
    });
    // const checkStateWidth = useSelector((state) => state.elementsParams.clientWidth)
    // const width = checkStateWidth ? checkStateWidth : window.innerWidth


    return (
        <section className="SportMarketTabs">
            {(data?.tabs[activeTab]?.name==='Fancy')&&
                <div className="SportMarketTabs__header">
                    {Object.keys(data?.tabs)?.map(tabGroupIndex => (
                        <div key={tabGroupIndex} className={`SportMarketTabs__header__item ${tabGroupIndex == activeTab ? 'active' : ''}`} onClick={() => setActiveTab(tabGroupIndex)}>
                            {data.tabs[tabGroupIndex]?.name}
                        </div>
                    ))}
                </div>
            }
            <div className="SportMarketTabs__markets">
                {/* {(width > 1024 && data?.tabs[activeTab]?.name === "Fancy")&& */}
                {(data?.tabs[activeTab]?.name === "Fancy")&&
                    <div className="SportMarketTabs__markets_fancy-title">
                        <div className='session_name'>Sessions</div>
                        <div className="SportMarketTabs__markets_fancy-title-block">
                            <span>No</span>
                            <span>Yes</span>
                        </div>
                    </div>
                }

                {data.tabs[activeTab] &&
                    <SportMarketStandard data={data.tabs[activeTab]} betPlacing={betPlacing} />
                }
                {/* {data.tabs[activeTab] && data.tabs[activeTab]?.items?.map(item => (
                    <div key={item?._id} className={`SportMarketTabs__markets__item`}>
                        <SportMarketStandard data={item} betPlacing={betPlacing} />
                    </div>
                ))} */}
            </div>
        </section>
    );
}

export default (SportMarketTabs)
