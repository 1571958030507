import React from 'react';
import './VirtualLastResults.scss';


const VirtualLastResults = ({data, eventTypeId, onSelectResult=()=>{} }) => {
    return (
        <div className="lastresult">
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th colSpan="4" scope="col" className="text-center">Last Result</th>
                        </tr>
                    </thead>
                </table>
                
                {eventTypeId=='v9'&&
                    <div className="resultcolwrap">
                        {(data?.length>0)&&
                            data?.map((item) => {
                                return(
                                    <div key={item?._id} className="resultcol">
                                        <div className="resultcol-text">
                                            <a>{item?.Result}</a>
                                            <span className="text-warning">&gt;&gt;</span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                }

                {eventTypeId=='b9'&&
                    <div className="lastresulrgrid">
                        {(data?.length>0)&&
                            data.map((item) => {
                                return(
                                    <div 
                                        key={item?._id} 
                                        className="lastresulrcol" 
                                        onClick={()=>{onSelectResult(item);}}
                                    >
                                        <div className="lastresulrttiel">{item?.Result}</div>
                                    </div>
                                );
                            })
                        }
                    </div>
                }

            </div>
        </div>
    )
}

export default VirtualLastResults;