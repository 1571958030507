import React, { useEffect, useRef, useState } from "react";
import "./Verification.scss";
import { IonButton } from "@ionic/react";
import doneIMG from "../../assets/images/verification/done.svg";
import checkIMG from "../../assets/images/verification/check.svg";
import { connectVerification } from "./verification.connect";
import { requestMobileVerification, requestOTPVerification, requestProfile } from "../../store/profile/profile.thunks";
import { useDispatch, useSelector } from "react-redux";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { countryCodesArray } from "./countryCodes";

const Verification = React.memo(({ auth, profile,  home = true, shake=false, submit=() => {}}, actions) => {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [userModified, setUserModified] = useState({ phone: "", isLoginMame: false, canChange: true });
  const [error, setError] = useState("");
  const [step, setStep] = useState(0);
  const [otp, setOtp] = useState("");
  const [user, setUser] = useState("");

  const [countryCodeInput, setCountryCodeInput] = useState('IN')
  const [countryCodeDial, setCountryCodeDial] = useState('+91')

  const checkUser = async (profile = null) => {
    let userRes;
    if (profile){
      userRes = profile
    } else{
      userRes = await dispatch(requestProfile())
    }
    setUser(user)
    const  loginName = JSON.parse(localStorage.getItem("user_data") || "{}")?.loginName
    const isPhoneInLoginName = !![9, 10].includes(loginName?.split("_")[1]?.length)

    if (home) {
      if (!userRes?.mobile_verified && loginName && !isPhoneInLoginName) {
        setStep(1)
      }
    } else {
      const getPart = (index) => (loginName && isPhoneInLoginName
        ? loginName?.split("_")[index]
        : (userRes?.mobile_verified ? userRes?.mobile?.split("_")[index] : "")
      );

      const country = getPart(0) || '91';
      const mobile = getPart(1);

      const countryInfo = countryCodesArray.find((item) => item.dial_code === `+${country}`);

      setCountryCodeDial(countryInfo?.dial_code);
      setCountryCodeInput(countryInfo?.code);
      setUserModified({
        phone :mobile,
        isLoginMame: loginName && isPhoneInLoginName,
        canChange: !(isPhoneInLoginName || userRes?.mobile_verified)
      });
      setPhone(mobile);
      setStep(1);
    }
  };

  useEffect( () => {
    if(!home && profile){
      checkUser(profile)
    }
  }, []);

  const changePhone = (e) => {
    const value = e.target.value
    if (![9,10].includes(value.length) && value.length !== 0) {
      setError("The number must be 9 or 10 characters");
    } else {
      setError("");
    }

    setPhone(e.target.value.replace(/\D/g, ''))
  };
  const checkPhone = async () => {
    if (!![9,10].includes(phone.length)) {
      const res = await dispatch(requestMobileVerification(countryCodeDial, phone));
      if (res) {
        setError("")
        setStep(2);
      }
    } else {
      setError("The number must be 9 or 10 characters");
    }
    return
  };


  const inputRefs = useRef([]);
  const numInputs = 4;
  const handleInputChange = (event, currentIndex) => {

    if (event?.target?.value?.length > 1) {
      event.target.value = event?.target?.value[1]
    }
    const inputValue = event.target.value;

    if (!/^[0-9]+$/.test(inputValue)) {
      event.target.value = inputValue.slice(0, -1);
    }

    if (/^[0-9]+$/.test(inputValue) && currentIndex < numInputs) {
      const newOtp = [...otp];
      newOtp[currentIndex] = inputValue;
      setOtp(newOtp);

      if (inputValue.length === 1 && currentIndex < numInputs - 1) {
        inputRefs.current[currentIndex + 1].focus();
      }
    }
  };

  const checkOTP = async () => {
    const res = await dispatch(requestOTPVerification(countryCodeDial, phone, otp.join("")));

    if (res) {
      if (home) {
        setStep(3);
        setTimeout(() => {
          setStep(0)
        }, 2000)
      }

      if (!home) {
        submit()
        setStep(4);
      }
      dispatch(requestProfile())
    } else {
      setError("Invalid OTP provided")
    }
  };
  const resendOPT = () => {
    inputRefs.current.map(one => one.value = "")
    setOtp([])
    setError("")
    checkPhone()
  };

  const initialMinute = 0,
    initialSeconds = 59;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    if (step === 2) {
      let myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(myInterval);
          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  });

  if (!auth) {
    return
  }

  return (
    <div className={`verification ${shake ? "shake" : ""}`}>
      {(step === 1 || step === 4) && <div className="verification-number">
        {home ?
          <div className="verification-title">
            Submit phone number to verify your account
          </div>
          :
          <div className="verification-title left">
            Phone Number <span> ( This is mandatory )</span>
          </div>
        }
        <div className="verification-input">
        <>
          <PhoneInput
          readOnly={!userModified.canChange}
          className={`countryCodeInput ${!userModified.canChange && 'arrowDisabled'}`}
          value={countryCodeDial}
          international
          defaultCountry={countryCodeInput}
          onChange={setCountryCodeDial}/>
          <div className={`countryPlaceholder ${phone.length ? "active" : ""}`}>{countryCodeDial}</div>
          </>
          <input
            className="verificationInput"
            placeholder="Mobile Number"
            name="phone"
            type="string"
            value={phone}
            disabled={!userModified.canChange}
            onChange={e => changePhone(e)}
          />
          { step === 1 && <IonButton
            onClick={checkPhone}
            class="verification-button submit-phone"
            mode="md"
            // disabled={phone.length !== 10}
          >
            {home ? "Submit" : "GET OTP"}
          </IonButton>}
          {step === 4 && <div className="verification-input-done">
            <img loading={"lazy"} src={checkIMG} alt="checkIMG" />
            <span>Verified</span>
          </div>
          }
        </div>
        {error && <div className="verification-error">
          {error}
        </div>}
      </div>}
      {step === 2 && <div className="verification-otp">
        <div className="verification__title">
          We have sent OTP to {countryCodeDial} {phone}
        </div>
        <div className="verification-otp-input-wrapper">
          <div className="verification-otp-otpBox">
            {Array.from({ length: numInputs }, (_, index) => (
              <input
                key={index}
                ref={(el) => (inputRefs.current[index] = el)}
                onChange={(e) => handleInputChange(e, index)}
                onFocus={e => {
                  e.target.value = "";
                }}
                placeholder={"_"}
                max={9}
                min={0}
                inputMode={"numeric"}
                maxLength={1}
                value={otp[index]}
                type="string"
                autoComplete="one-time-code"
                className="verification-otp-otpBox-item"
              />
            ))}
            <IonButton
              onClick={checkOTP}
              class="verification-button submit"
              mode="md"
              disabled={!!error}
            >
              Verify
            </IonButton>
          </div>
        </div>
        <div className="verification-otp-resend-wrapper">
          <div className="verification-otp-resend">
            <div onClick={() => (seconds === 0 ? setSeconds(59) : null)}>
              {seconds === 0 && <span className="active" onClick={seconds === 0 ? resendOPT : null}>
                Resend
              </span>}
              {minutes === 0 && seconds === 0 ? null : (
                <span>
                  Resend {" "}in {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                </span>
              )}
            </div>
          </div>
          <div></div>
          {error && <div className="verification-error">
            {error}
          </div>}
        </div>
      </div>}
      {step === 3 &&  <div className="verification-finish">
        <img src={doneIMG} alt="doneIMG" />
        <span>Thank you! Your account has been verified</span>
      </div>}
    </div>
  );
});
export default connectVerification()(Verification);

