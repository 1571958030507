import "./OpenBetsTable.scss";
import React from "react";
import {tsToDate} from "../../helpers/date.helper";
import { useHistory } from "react-router-dom";
import { getCurrencyFormatWithZeroAfterDot } from "../../helpers/getCurrencyFormat.helper";

const OpenBetsTable = ({timeSettings, bets, title, redirect, activeButton}) => {
    const history = useHistory();

    const handleClickName = (item) => {
        if (redirect) history.push(`/event-page/${item?.eventTypeId}/${item?.eventId}`);
    };

    const getProfitLiability = (item) => {
        const isFancy = item?.marketType === "INNINGS_RUNS" && item?.marketBettingType === "LINE";
        const profit = isFancy ? (item.size * item?.averagePrice / 100).toFixed(2) : (item.size * item?.averagePrice - item?.size).toFixed(2);

        return item.side === "Back" ? {profit, liability: ""} : {profit: "", liability: profit};
    };

    const getValueColor = (value) => {
      return  value >= 0 ? "pnl-item-value--green" : "pnl-item-value--red";
    };

    return (
    <section className="OpenBetsTable">
      <div className="OpenBetsTable__table">
        <ul className="OpenBetsTable__table-header">
          <li>Event Name</li>
          <li>Sport Name</li>
          <li>Bet Type</li>
          <li>User Rate</li>
          <li>Amount</li>
          <li>Profit / Loss</li>
          <li>Place Date</li>
          <li>Match Date</li>
        </ul>

        <div className="OpenBetsTable__table-body">
          {(bets?.length)? 
            bets.map((betItem, betIndex) => {
              return(
                <ul className="OpenBetsTable__table-body-row" key={betIndex?._id}>
                  <li className="OpenBetsTable__table-body-row-info">{betItem?.eventName || betItem?.eventTypeName}</li>
                  <li className="OpenBetsTable__table-body-row-description">
                    <p><span>{betItem?.eventTypeName || '-'}</span></p>
                  </li>
                  <li className="OpenBetsTable__table-body-row-info">{betItem?.type || '-'}</li>
                  <li className="OpenBetsTable__table-body-row-info">{betItem?.UserRate || '-'}</li>
                  <li className={`OpenBetsTable__table-body-row-info ${getValueColor(betItem?.amount || betItem?.stake)}`}>{getCurrencyFormatWithZeroAfterDot(betItem?.amount || betItem?.stake || 0)}</li>
                  {/* <li className={`OpenBetsTable__table-body-row-profit ${getValueColor(betItem?.result === 'WON'? 1 : -1)}`}>{getCurrencyFormatWithZeroAfterDot(betItem?.profitLoss)}</li> */}
                  <li className={`OpenBetsTable__table-body-row-profit ${getValueColor(betItem?.result === 'WON'? 1 : -1)}`}>-</li>
                  <li className="OpenBetsTable__table-body-row-date">
                    <span>{tsToDate(betItem?.placedTime, timeSettings.timeZoneName).split(" ")[0]}</span>
                    <span className="text-color">{tsToDate(betItem?.placedTime, timeSettings.timeZoneName).split(" ")[1]}</span>
                  </li>
                  <li className="OpenBetsTable__table-body-row-date">
                    <span>{tsToDate(betItem?.matchedTime, timeSettings.timeZoneName).split(" ")[0]}</span>
                    <span className="text-color">{tsToDate(betItem?.matchedTime, timeSettings.timeZoneName).split(" ")[1]}</span>
                  </li>
                </ul>
              );
            })
            :
            <ul className="OpenBetsTable__table-body-no-bets"
              style={{color: '#1e8067', justifyContent: 'start', paddingLeft: '20px'}}
            >
              <li>No data to display</li>
            </ul>
          }
        </div>

        {/* <div className="OpenBetsTable__table-body">
          {bets && Object.keys(bets).length > 0 ? (

            Object.keys(bets).map((group) =>
              bets[group].markets.map((market) => {
                  const marketName = market.name;
                  return market.selections.map((selection) =>
                  selection.map((item, index) => (
                    <ul className="OpenBetsTable__table-body-row" key={item?.apolloBetId + index}>
                      <li className="OpenBetsTable__table-body-row-date">
                        <span>{tsToDate(item.betPlacedDate, timeSettings.timeZoneName).split(" ")[0]}</span>
                        <span
                          className="text-color">{tsToDate(item.betPlacedDate, timeSettings.timeZoneName).split(" ")[1]}</span>
                      </li>
                      <li className="OpenBetsTable__table-body-row-description">
                        <p className="OpenBetsTable__table-body-row-description-name"
                           onClick={() => handleClickName(item)}>
                          {item.eventName}
                        </p>
                        <p><span className="text-bold">{item.selectionName}</span> <span className="text-color">{marketName}</span></p>
                        <p>
                          <span>Bet ID {item.apolloBetId}</span>
                          {activeButton === "matched" &&
                            <span className="OpenBetsTable__table-body-row-description-matched-time" >
                              Matched {tsToDate(item.betPlacedDate, timeSettings.timeZoneName)}
                            </span>
                          }
                        </p>
                      </li>
                      <li className="OpenBetsTable__table-body-row-info">{item.side}</li>
                      <li className="OpenBetsTable__table-body-row-info">{item.price}</li>
                      <li className="OpenBetsTable__table-body-row-info">{item.orderStatus === 'matched' 
                      ? getCurrencyFormatWithZeroAfterDot(item.sizeMatched)
                      : getCurrencyFormatWithZeroAfterDot(item.sizeRemaining)
                      }</li>
                      <li className="OpenBetsTable__table-body-row-info">{getCurrencyFormatWithZeroAfterDot(getProfitLiability(item).liability)}</li>
                      <li className="OpenBetsTable__table-body-row-profit">{getCurrencyFormatWithZeroAfterDot(getProfitLiability(item).profit)}</li>
                    </ul>
                  )),
                );
              }),
            )
          ) : (
            <ul className="OpenBetsTable__table-body-no-bets"
              style={{color: '#1e8067', justifyContent: 'start', paddingLeft: '20px'}}
            >
              <li>No data to display</li>
            </ul>
          )}

        </div> */}

      </div>
    </section>
    );
};

export default OpenBetsTable;
