import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestTwitterSettings } from '../../store/twitterSettings/twitterSettings.thunks';
import { requestSportStart } from '../../store/eventPage/eventPage.thunks';
import { requestOpenBets } from '../../store/openBets/openBets.thunks';
import { requestSportData, requestSportStats } from '../../store/sportEvent/sportEvent.thunks';
import { getSportEventSuccess, getSportEventError, getSportStatsError, getSportVisualisationError, getSportVideoSuccess, getSportVideoError, getSportScoreSuccess, getSportScoreError, getSportLadderSuccess, getSportLadderError } from '../../store/sportEvent/sportEvent.slice';
import { setRulesPopUp } from '../../store/rulesPopUp/rulesPopUp.slice';
import { getEventPageOpenBetsSuccess, getEventPageOpenBetsError } from '../../store/openBets/openBets.slice';


export const connectEventPage = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      lastActivityDate: state.balance.lastActivityDate,
      sportEventStats: state.sportEvent.sportStats,
      twitterSettings: state.twitterSettings,
      eventOpenBets: state.openBets.eventPageOpenBets,
      orders: state.eventPage.orders,
      sportData: state.sportEvent.sportData,
      rulesPopUp: state.rulesPopUp,
      auth: state.auth,
      elementsParams: state.elementsParams,
      user: state.user,
      sportVideo: state.sportEvent.sportVideo,
      sportScore: state.sportEvent.sportScore,
      // raceOdds: state.raceOdds,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestTwitterSettings,
      requestOpenBets,
      requestSportStart,
      requestSportData,
      requestSportStats,
      getSportEventError,
      getSportStatsError,
      getSportVideoError,
      getSportVisualisationError,
      setRulesPopUp,
      getSportEventSuccess,
      getSportVideoSuccess,
      getSportScoreSuccess,
      getSportScoreError,
      getEventPageOpenBetsSuccess,
      getEventPageOpenBetsError,
      getSportLadderSuccess,
      getSportLadderError
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
