import './DesktopBetPlacing.scss'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { IonInput } from '@ionic/react'
import { connectDesktopBetPlacing } from './DesktopBetPlacing.connect'
import { toastr } from 'react-redux-toastr'
import { BetPopoverContext } from '../../context'
import { getPlacingText } from '../../helpers/betPlacing.helper'
import { setLoginNew } from '../../store/LoginNew/loginNew.slice'
import {getCurrencyFormat} from '../../helpers/getCurrencyFormat.helper'
import { Socket } from '../../services/socket'
import { useHistory } from 'react-router'
import { payloadSetter } from '../../helpers/payloadSetter'

const DesktopBetPlacing = ({
  auth,
  betPlacing,
  stakes,
  presetStakeSettings,
  actions,
  balance,
  user,
  setThisEventDispatch
}) => {
  const dispatch = useDispatch()
  const cancelBetPopover = useContext(BetPopoverContext)
  const [incremented, setIncremented] = useState(false)
  const [styles, setStyles] = useState({ transform: 'translateY(-100%)' })
  const [minMaxStakes, setMinMaxStakes] = useState({min: 0, max: 0});
  const history = useHistory();



  // useEffect(() => {
  //   Socket.on('place-bet-success', placeBetSuccess);
  //   Socket.on('place-bet-error', placeBetError);
    
  //   return () => {
  //     Socket.off('place-bet-success', placeBetSuccess)
  //     Socket.off('place-bet-error', placeBetError);
  //   }
  // }, []);

  // const placeBetSuccess = (...args) => {
  //   toastr.success('', args[0]?.message);
  //   clearBetPlaceData();
  //   requestBets();
  // }
  // const placeBetError = (...args) => {
  //   toastr.error('', args[0]?.message);
  //   clearBetPlaceData();
  // }

  // const clearBetPlaceData = () => {
  //   cancelBetPopover();
  //   actions.setMarket(null);
  //   actions.setRunner(null);
  //   actions.setPrice(null);
  //   actions.setPriceActual(null);
  //   actions.setDelay(null);
  //   actions.setStake(null);
  //   actions.setMarketType(null);
  //   actions.setMarketName(null);
  // }

  useEffect(() => {
    if(stakes?.length<=0) {
      actions.requestStakeButtons();
    }
  }, [])

  useEffect(() => {
    if(stakes?.length>0 && minMaxStakes?.max == 0) {
      // Extract all price values from the data
      const prices = stakes.flatMap(obj =>  Object.keys(obj).filter(key => key.startsWith('price')).map(key => obj[key]));
  
      // Calculate min and max values
      const min = Math.min(...prices);
      const max = Math.max(...prices);
  
      // Update the state with min and max values
      setMinMaxStakes({ min, max });
    }

  }, [stakes]);

  useEffect(() => {
    setTimeout(() => setStyles({ transform: 'translateY(0px)' }), 200)
  }, [])

  const profit = (e) => {
    const type = betPlacing.isBack ? 'Profit' : 'Liability'
    // const isFancy = betPlacing.mtype == 'INNINGS_RUNS' && betPlacing.btype == 'LINE'
    const isFancy = betPlacing.marketType == 'SESSION';
    const stake = betPlacing.stake
      ? isFancy
        ? Number((betPlacing.stake * betPlacing.fancyPrice) / 100).toFixed(2)
        : Number((betPlacing.stake * betPlacing.priceActual) - betPlacing.stake).toFixed(2)
      : '0.00'
    if (e == 1) {
      return `${type}: ${getCurrencyFormat(stake)}`
    } else {
      return getCurrencyFormat(stake)
    }
  }

  const requestBets = () => {
    if(!user?.verifytoken) return;
    const userdata = {
      token: user?.verifytoken,
      filter: {
        username: user?.details?.username,
        deleted: false,
        result: 'ACTIVE',
      },
      sort: { placedTime: -1 },
    };

    if(history.location.pathname.includes('event-page') && setThisEventDispatch) {
      const parts = history?.location?.pathname.split('/');
      const lastParameter = parts[parts.length - 1];
      userdata.filter['eventId'] = lastParameter;
    }else {
      userdata.filter['status'] = 'MATCHED';
    }
    Socket.emit('get-bets', userdata);
  }



  const submit = async () => {
    // console.log("Confirmation: ", betPlacing.confirmation);
    if(!auth) return;

    if (betPlacing.confirmation || betPlacing.isCashOut) {
      actions.setBetPlacingPopoverStage(false)
    }
    else {
      actions.setBetPlacingPopoverStage(true)

      const payload = payloadSetter(auth, betPlacing);
      Socket.emit('create-bet', payload);
      
      // cancelBetPopover();

      // actions
      //   .requestSetOrder(betPlacing)
      //   .then((res) => {
      //     const text = getPlacingText(betPlacing, res)
      //     if (text) {
      //       text.indexOf('Unmatched') === -1
      //         ? toastr.success('', text)
      //         : toastr.error('', text)
      //     }
      //     cancelBetPopover()
      //   })
      //   .catch((e) => {
      //     cancelBetPopover()
      //     if (e[0] && e[0][0] && e[0][0].description)
      //       toastr.error('', e[0][0].description)
      //     else toastr.error('', 'Something went wrong')
      //   })


    }
    actions.setShowBetPlacingPopover(true)
  }

  const ripple = (event) => {
    const elem = event.currentTarget

    var rect = event.target.getBoundingClientRect()
    let x = event.clientX - elem.offsetLeft
    let y = rect.height - rect.bottom + event.clientY
    let rippleElement = document.createElement('span')
    rippleElement.style.left = x + 'px'
    rippleElement.style.top = y + 'px'
    elem.appendChild(rippleElement)
    setTimeout(function () {
      rippleElement.remove()
    }, 500)
  }

  const changeOddRipple = (event, mod, isChanging = 1) => {
    const ev = { ...event }
    ripple(event)
    changeOdd(mod, isChanging)
  }

  const changeOdd = (mod, isChanging = 1) => {
    let step, dec
    const odd = Number(betPlacing.priceActual)
    switch (true) {
      case odd < 2: {
        step = 0.01
        dec = 2
        break
      }
      case odd < 3: {
        step = 0.02
        dec = 2
        break
      }
      case odd < 4: {
        step = 0.05
        dec = 2
        break
      }
      case odd < 6: {
        step = 0.1
        dec = 2
        break
      }
      case odd < 10: {
        step = 0.2
        dec = 2
        break
      }
      case odd < 20: {
        step = 0.5
        dec = 1
        break
      }
      case odd < 30: {
        step = 1
        dec = 0
        break
      }
      case odd < 50: {
        step = 2
        dec = 0
        break
      }
      case odd < 100: {
        step = 5
        dec = 0
        break
      }
      default: {
        step = 10
        dec = 0
      }
    }
    let newVal = +(odd + step * mod * isChanging).toFixed(2)
    if (newVal < 1.01) newVal = 1.01
    else if (newVal > 1000) newVal = 1000
    else if (!Number.isInteger(newVal / step))
      newVal = newVal - (newVal % step) + step
    newVal = Number(newVal.toFixed(dec))
    actions.setPriceActual(newVal)
  }

  const getPriseFor_SB = (marketId, price) => {
    return marketId && marketId.slice(marketId.length - 3) === '_SB'
      ? ((price - 1) * 100).toFixed(2).replace('.00', '')
      : price
  }

  // another variant
  const renderOdd = () => {
    if (betPlacing.mtype == 'INNINGS_RUNS' && betPlacing.btype == 'LINE')
      return (
        <div className="DesktopBetPlacing__odd__text DesktopBetPlacing__odd__text--small">
          <div className="small1">{betPlacing.priceActual}</div>
          <div className="small2">{betPlacing.line}</div>
        </div>
      )
    if (betPlacing.mtype == 'MATCH_ODDS_SB' || betPlacing.mtype == 'BOOKMAKER')
      return (
        <div className="DesktopBetPlacing__odd__text">
          {getPriseFor_SB(betPlacing.market, betPlacing.priceActual)}
        </div>
      )
    return (
      <>
        <IonInput
          type="number"
          autocomplete="false"
          autoCorrect="false"
          className="DesktopBetPlacing__input DesktopBetPlacing__odd__input"
          value={betPlacing.priceActual}
          onIonChange={(e) => actions.setPriceActual(e.detail.value)}
          onBlur={() => changeOdd(1, 0)}
        ></IonInput>
        <div className="DesktopBetPlacing__changeodd-buttonsCol">
          <button
            className={`DesktopBetPlacing__changeodd DesktopBetPlacing__changeodd-buttonsCol-plus ${
              betPlacing.isBack ? '' : 'lay'
            }`}
            onClick={(event) => changeOddRipple(event, 1)}
            mode="md"
          >
          </button>
          <button
            className={`DesktopBetPlacing__changeodd DesktopBetPlacing__changeodd-buttonsCol-minus ${
              betPlacing.isBack ? '' : 'lay'
            }`}
            onClick={(event) => changeOddRipple(event, -1)}
            mode="md"
          >
          </button>
        </div>
      </>
    )
  }

  const renderOddTitle = () => {
    if (betPlacing.mtype == 'INNINGS_RUNS' && betPlacing.btype == 'LINE')
      return (
        <div className="DesktopBetPlacing__row DesktopBetPlacing__row f10">
          <div className="small1">Odds (H-J)</div>
          <div className="small2">Runs</div>
        </div>
      )
    return (
      <div
        style={
          betPlacing.mtype == 'MATCH_ODDS_SB' || betPlacing.mtype == 'BOOKMAKER'
            ? { paddingLeft: '5px' }
            : {}
        }
        className="DesktopBetPlacing__row f10"
      >
        Odds
      </div>
    )
  }

  useEffect(() => {
    if (incremented) setTimeout(() => setIncremented(false), 300)
  }, [incremented])

  const increment = (i) => {
    setIncremented(true)

    // actions.setStake(
    //   (betPlacing.stake ? Number(betPlacing.stake) : 0) +
    //     presetStakeSettings?.settings[i]
    // )

    actions.setStake(
      (betPlacing.stake ? Number(betPlacing.stake) : 0) +
        i
    )
  }
  const incrementNotAuth = (i) => {
    setIncremented(true)
    actions.setStake(
      (betPlacing.stake ? Number(betPlacing.stake) : 0) +
        i
    )
  }

  // console.log("BetPlacing: ", betPlacing);

  return (
    <div className="DesktopBetPlacing-container">
      {betPlacing?.market ? (
        <>
          <div className="DesktopBetPlacing__topMark">
            <div
              className={
                betPlacing?.isBack
                  ? 'DesktopBetPlacing__topMark-back'
                  : 'DesktopBetPlacing__topMark-lay'
              }
            ></div>
            <span className="DesktopBetPlacing__topMark-title">
              {betPlacing?.isBack ? 'Back' : 'Lay'}
            </span>
          </div>
          <div className="DesktopBetPlacing__matchName">
            {`${ new Date(betPlacing?.startTime).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }) }  ${betPlacing?.eventName.replace('${HH:mm}', '')}`}
          </div>
          <div
            className={`DesktopBetPlacing ${
              betPlacing?.isBack
                ? 'DesktopBetPlacing--back'
                : 'DesktopBetPlacing--lay'
            }`}
          >
            <div className="DesktopBetPlacing__betName">
              <span>{betPlacing?.runnerName}</span>
              <span style={{ fontSize: '10px' }}>
                Max Market: {getCurrencyFormat(betPlacing?.maxWinPerMarket) || '5,00,000'}
              </span>
            </div>
            <div className="DesktopBetPlacing__row">
              <div
                className={`DesktopBetPlacing__col ${
                  betPlacing?.mtype == 'INNINGS_RUNS' &&
                  betPlacing?.btype == 'LINE'
                    ? 'DesktopBetPlacing__col--small'
                    : ''
                }`}
              >
                {renderOddTitle()}
                <div className="DesktopBetPlacing__row">
                  <div className="DesktopBetPlacing__odd">
                    {renderOdd()}
                    </div>
                </div>
              </div>
              <div
                className={`DesktopBetPlacing__stake ${
                  (betPlacing.mtype == 'INNINGS_RUNS' &&
                    betPlacing.btype == 'LINE') ||
                  true
                    ? 'DesktopBetPlacing__col--large'
                    : ''
                }`}
              >
                <div className="DesktopBetPlacing__row f10">
                  <div>Stake</div>
                </div>
                <div className="DesktopBetPlacing__row">
                  <div className="DesktopBetPlacing__stake">
                    <IonInput
                      type="number"
                      inputmode="decimal"
                      className={`DesktopBetPlacing__input DesktopBetPlacing__stake__input DesktopBetPlacing__stake__input--rounded ${
                        incremented ? 'incremented' : ''
                      }`}
                      style={
                        Number(betPlacing.stake) <= 0
                          ? { border: '2px solid #B2493E' }
                          : {}
                      }
                      placeholder={`Min Bet: ${betPlacing?.minBet || ''}`}
                      value={+betPlacing.stake === 0 ? '' : +betPlacing.stake}
                      onIonChange={(e) => {
                        if(auth) {
                          if (
                            Number(e.target.value) >= betPlacing?.minBet
                          ) {
                            actions.setStake(
                              String(e.target.value).replace(
                                /^([0-9]*[.,][0-9]{2})([0-9]+)$/,
                                '$1'
                              )
                            )
                          }
                          else {
                            Number(e.target.value) <= betPlacing?.minBet
                              ? actions.setStake(String(betPlacing?.minBet)) : actions.setStake(
                                String(e.target.value).replace(
                                  /^([0-9]*[.,][0-9]{2})([0-9]+)$/,
                                  '$1'
                                )
                              )
                          }
                        }
                        else {
                          if (Number(e.target.value) && Number(e.target.value) < 500000) {
                            actions.setStake(
                              String(e.target.value).replace(
                                /^([0-9]*[.,][0-9]{2})([0-9]+)$/,
                                '$1'
                              )
                            )
                          } else {
                            if(Number(e.target.value) > 500000) {
                              actions.setStake(
                                String(500000).replace(
                                /^([0-9]*[.,][0-9]{2})([0-9]+)$/,
                                '$1'
                              ))
                            }
                          }
                        }
                      }}
                    ></IonInput>
                  </div>
                </div>
              </div>
              <div
                className={`DesktopBetPlacing__col profit--column ${
                  betPlacing?.mtype == 'INNINGS_RUNS' &&
                  betPlacing?.btype == 'LINE'
                    ? 'DesktopBetPlacing__col--small'
                    : ''
                }`}
              >
                <div
                  style={{ padding: '0' }}
                  className="DesktopBetPlacing__row f10"
                >
                  <div>{betPlacing?.isBack ? 'Profit' : 'Liability'}</div>
                </div>
                <div className="DesktopBetPlacing__row">
                  <div
                    style={{ margin: '0' }}
                    className="DesktopBetPlacing__odd"
                  >
                    <div className="DesktopBetPlacing__odd__text">
                       {profit(2)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="DesktopBetPlacing__col-delete">
                <div
                  className="DesktopBetPlacing__col-delete-btn"
                  onClick={() => actions.setMarket(null)}
                ></div>
              </div>
            </div>

            <div className="DesktopBetPlacing__row DesktopBetPlacing__row--actions">
              <div className="DesktopBetPlacing__row--actions-row">
                {(stakes?.length>0)&&
                  stakes.map((item, index) => {
                    return(
                      <button
                        key={`${item[`price${index+1}`]}` + index}
                        className="DesktopBetPlacing__addstake"
                        shape="round"
                        mode="md"
                        onClick={() => {
                          if(auth) increment(item[`price${index+1}`])
                          else incrementNotAuth(item[`price${index+1}`])
                        }}
                      >
                        +{getCurrencyFormat(item[`price${index+1}`]) || 0}
                      </button>
                    );
                  })
                }

                {/* <button
                  className="DesktopBetPlacing__addstake"
                  shape="round"
                  mode="md"
                  onClick={() => {
                    if(auth) increment(0)
                    else incrementNotAuth(300)
                  }}
                >
                  +{presetStakeSettings && presetStakeSettings.settings ? getCurrencyFormat(presetStakeSettings?.settings[0]) : "300"}
                </button>
                <button
                  className="DesktopBetPlacing__addstake"
                  shape="round"
                  mode="md"
                  onClick={() => {
                    if(auth) increment(1)
                    else incrementNotAuth(500)
                  }}
                >
                  +{presetStakeSettings && presetStakeSettings.settings ? getCurrencyFormat(presetStakeSettings?.settings[1]) : "500"}
                </button> */}
                {/* <button
                  className="DesktopBetPlacing__addstake"
                  shape="round"
                  mode="md"
                  onClick={() => {
                    if(auth) increment(2)
                    else incrementNotAuth(1000)
                  }}
                >
                  +{presetStakeSettings && presetStakeSettings.settings ? getCurrencyFormat(presetStakeSettings?.settings[2]) : "1,000"}
                </button> */}
                <button
                  className="DesktopBetPlacing__addstake"
                  shape="round"
                  mode="md"
                  onClick={() => {
                    if(auth) {
                      increment(minMaxStakes?.min || 1000)
                      // actions.setStake(
                      //   balance.availBalance
                        
                      //   // betPlacing.maxBet > balance.availBalance
                      //   //   ? balance.availBalance
                      //   //   : betPlacing.maxBet
                      // )
                    } else {
                      incrementNotAuth(minMaxStakes?.min || 1000)
                    }
                  }}
                >
                  MIN
                </button>
                <button
                  className="DesktopBetPlacing__addstake"
                  shape="round"
                  mode="md"
                  onClick={() => {
                    if(auth) {
                      increment(minMaxStakes?.max || 500000)
                      // actions.setStake(
                      //   balance.availBalance
                        
                      //   // betPlacing.maxBet > balance.availBalance
                      //   //   ? balance.availBalance
                      //   //   : betPlacing.maxBet
                      // )
                    } else {
                      incrementNotAuth(minMaxStakes?.max || 500000)
                    }
                  }}
                >
                  MAX
                </button>
              </div>
              
              <div className="DesktopBetPlacing__row--actions-row">
                {/* <button
                  className="DesktopBetPlacing__addstake"
                  shape="round"
                  mode="md"
                  onClick={() => {
                    if(auth) increment(3)
                    else incrementNotAuth(5000)
                  }}
                >
                  +{presetStakeSettings && presetStakeSettings.settings ? getCurrencyFormat(presetStakeSettings?.settings[3]) : "5,000"}
                </button>
                <button
                  className="DesktopBetPlacing__addstake"
                  shape="round"
                  mode="md"
                  onClick={() => {
                    if(auth) increment(4)
                    else incrementNotAuth(10000)
                  }}
                >
                  +{presetStakeSettings && presetStakeSettings.settings ? getCurrencyFormat(presetStakeSettings?.settings[4]) : "10,000"}
                </button>
                <button
                  className="DesktopBetPlacing__addstake"
                  shape="round"
                  mode="md"
                  onClick={() => {
                    if(auth) increment(5)
                    else incrementNotAuth(25000)
                  }}
                >
                  +{presetStakeSettings && presetStakeSettings.settings ? getCurrencyFormat(presetStakeSettings?.settings[5]) : "25,000"}
                </button> */}

                <div
                  className="DesktopBetPlacing__row--actions-row-clear"
                  onClick={() => actions.setStake(0)}
                >
                  Clear
                </div>
              </div>
            </div>
            {betPlacing.priceActual !== betPlacing.price &&
              (betPlacing.mtype == 'MATCH_ODDS_SB' ||
                betPlacing.mtype == 'BOOKMAKER' ||
                betPlacing.mtype == 'INNINGS_RUNS') && (
                <div className="DesktopBetPlacing__changedOdds">
                  <div className="DesktopBetPlacing__row DesktopBetPlacing__row-oddchanged">
                    The Odds of your selection have changed
                  </div>
                  <button
                    className="DesktopBetPlacing__changedOdds-accept"
                    type="button"
                    onClick={() => {
                      actions.setPrice(betPlacing.priceActual)
                    }}
                  >
                    Accept Changes
                  </button>
                </div>
              )}
          </div>
          <div
            style={{ fontWeight: '500' }}
            className="DesktopBetPlacing__matchName "
          >
            Liability: {betPlacing.isBack ? getCurrencyFormat(betPlacing.stake) : profit(2)}
          </div>
          <div className="DesktopBetPlacing__bottomBtns">
            <button
              className="DesktopBetPlacing__bottomBtns-remove"
              type="button"
              onClick={() => actions.setMarket(null)}
            >
              Remove All
            </button>
            {auth ? (
              <button
                className="DesktopBetPlacing__bottomBtns-placeBet"
                type="submit"
                onClick={submit}
                disabled={
                  Number(betPlacing.stake) <= 0 ||
                  Number(betPlacing.priceActual) < 1.01 ||
                  (betPlacing.priceActual !== betPlacing.price &&
                    (betPlacing.mtype == 'MATCH_ODDS_SB' ||
                      betPlacing.mtype == 'BOOKMAKER' ||
                      betPlacing.mtype == 'INNINGS_RUNS'))
                }
              >
                Place Bets
              </button>
            ) : (
              <button
                className="DesktopBetPlacing__bottomBtns-placeBet"
                type="submit"
                onClick={() => {
                  dispatch(setLoginNew(true))
                }}
              >
                Log In
              </button>
            )}
          </div>

          {(!betPlacing?.isCashOut)&&
            <div
              className="DesktopBetPlacing__confirm"
              onClick={() => actions.setConfirmation(!betPlacing.confirmation)}
            >
              <div
                className={`DesktopBetPlacing__confirm-checkbox ${
                  betPlacing.confirmation ? 'checked' : ''
                }`}
              ></div>
              <div className="DesktopBetPlacing__confirm-title">
                Confirm bets before placing
              </div>
            </div>
          }
        </>
      ) : (
        <div className={`DesktopBetPlacing__empty-message`}>
          Click on the odds to add selections to the betslip.
        </div>
      )}
    </div>
  )
}

export default connectDesktopBetPlacing()(DesktopBetPlacing)
