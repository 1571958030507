import React, { useEffect, useRef, useState } from 'react';
import './CasinoProvider.scss';
import HeaderTabs from './ProviderTabs/HeaderTabs';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { CasinoData } from '../../constants/Casino/CasinoData/CasinoData';
import { openSlotCasinoPopUp } from '../../store/slotCasinoPopUp/slotCasinoPopUp.slice';
import { setLoginNew } from '../../store/LoginNew/loginNew.slice';


const CasinoProvider = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const user = useSelector((state) => state.user);
    const casinoRef = useRef(null);
    const history = useHistory();
    const dispatch = useDispatch();

    
    useEffect(() => {
        if(casinoRef?.current) {
            scrollFocus();
        }
    }, []);

    const scrollFocus = () => {
        setTimeout(() => {
            casinoRef.current.scrollIntoView({
                behavior: 'smooth',
            })
        }, 400)
    }


    const onSelectGame = (data) => {
        if(!data?.gameId) return;

        if(user?.verifytoken) {
            const id = data?.gameId;
            const tableId = data?.tableId || '';
            history.push(`/casino_live/${id}/${tableId}`);
            dispatch(openSlotCasinoPopUp());
        }else {
            dispatch(setLoginNew(true));
        }
    } 


    return (
        <div ref={casinoRef} className='casino-provider-container'>
            <div className='casino-provider-header-list'>
                <HeaderTabs 
                    selectedIndex={selectedIndex}
                    setSelectedIndex={(val) => {setSelectedIndex(val);}}
                />
            </div>

            <div className='casino-games-container'>
                {(CasinoData[selectedIndex]?.length > 0)&&
                    CasinoData[selectedIndex].map((item) => {
                        return(
                            <div 
                                className='casino-game-box' key={item?.id}
                                onClick={() => {onSelectGame(item);}}
                            >
                                <div className='casino-icon-box'>
                                    <img 
                                        src={item?.imageUrl}
                                        alt={item?.name}
                                    />
                                    <div className='casino-name'>PLAY NOW</div>
                                </div>
                            </div>
                        );
                    })
                }

            </div>
        </div>
    )
}

export default CasinoProvider;