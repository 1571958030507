import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// import { requestTwitterSettings } from '../../store/twitterSettings/twitterSettings.thunks';
// import { requestSportStart } from '../../store/eventPage/eventPage.thunks';
// import { requestOpenBets } from '../../store/openBets/openBets.thunks';
// import { requestSportData, requestSportStats } from '../../store/sportEvent/sportEvent.thunks';
// import { getSportEventSuccess, getSportEventError, getSportStatsError, getSportVisualisationError, getSportVideoSuccess, getSportVideoError, getSportScoreSuccess, getSportScoreError, getSportLadderSuccess, getSportLadderError } from '../../store/sportEvent/sportEvent.slice';
// import { setRulesPopUp } from '../../store/rulesPopUp/rulesPopUp.slice';
import { getEventPageOpenBetsSuccess, getEventPageOpenBetsError } from '../../store/openBets/openBets.slice';
import { getVirtualDataSuccess, getVirtualDataError, getBallByBallDataSuccess, getBallByBallDataError, getVirtualLastResultsSuccess, getVirtualLastResultsError, getBallByBallLastResultsSuccess, getBallByBallLastResultsError } from '../../store/virtualEvent/virtualEvent.slice';

export const connectVirtualEventPage = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      eventOpenBets: state.openBets.eventPageOpenBets,
      sportData: state.sportEvent.sportData,
      virtualData: state.virtualEvent.virtualData,
      ballByBallData: state.virtualEvent.ballByBallData,
      virtualLastResults: state.virtualEvent.virtualLastResults,
      ballByBallLastResults: state.virtualEvent.ballByBallLastResults,
      auth: state.auth,
      elementsParams: state.elementsParams,
      user: state.user,
      timeZoneName: state.timeSettings.timeZoneName,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      getVirtualDataSuccess,
      getVirtualDataError,
      getBallByBallDataSuccess,
      getBallByBallDataError,
      getEventPageOpenBetsSuccess,
      getEventPageOpenBetsError,
      getVirtualLastResultsSuccess,
      getVirtualLastResultsError,
      getBallByBallLastResultsSuccess,
      getBallByBallLastResultsError
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
