import React from 'react';
import './BallByBallPopupModal.scss';


const BallByBallPopupModal = ({handleClose, data}) => {
    return (
        <div 
            id="ballbyballpopupmodal-modal" 
            tabindex="-1" 
            data-backdrop="static" 
            aria-labelledby="betpopupmodalmodalLabel" 
            className="modal fade show"
            onClick={()=>{handleClose();}}
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5>{data?.Result}</h5>
                        <a href="javascript:void(0)" aria-label="Close" className="modal-action modal-close" onClick={()=>{handleClose();}} >
                            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" color="#FFFFFF" height="20" width="20" xmlns="http://www.w3.org/2000/svg" style={{color: 'rgb(255, 255, 255)'}}>
                                <line _ngcontent-mia-c89="" x1="18" y1="6" x2="6" y2="18"></line>
                                <line _ngcontent-mia-c89="" x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                        </a>
                    </div>
                    <div className="modal-body">
                        <iframe 
                            _ngcontent-mia-c89="" 
                            id="fp_embed_player" 
                            marginwidth="0" 
                            marginheight="0" 
                            frameborder="0" 
                            width="100%" 
                            height="280px" 
                            scrolling="no" 
                            allowfullscreen="allowfullscreen" 
                            allow="autoplay" 
                            disableremoteplayback="" 
                            playsinline="" 
                            muted="" 
                            autoplay="" 
                            preload="auto" 
                            src={`https://vc9raw.sgp1.cdn.digitaloceanspaces.com/BallpBall/${data?.ballvideo}?autoplay=1&amp;mute=1`}></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BallByBallPopupModal;