 import { createSlice } from '@reduxjs/toolkit'
 
 export const slotCasinoPopUpSlice = createSlice({
   name: 'slotCasinoPopUp',
   initialState: {
    isOpen: false,
    openTimescount: 0,
   },
   reducers: {
    //  setSlotCasinoPopUp: (state, action) => action.payload,
     setSlotCasinoPopUp: (state, action) => ({...state, isOpen: action.payload}),
     setSlotCasinoPopupOpenCounts: (state, action) => ({...state, openTimescount: action.payload}),
     openSlotCasinoPopUp: (state, action) => {
      if (state.openTimescount === 0) {
        state.isOpen = true;
        state.openTimescount += 1;
      }
    },
   },
 })
 
 export const { setSlotCasinoPopUp, setSlotCasinoPopupOpenCounts, openSlotCasinoPopUp  } = slotCasinoPopUpSlice.actions
 
 export default slotCasinoPopUpSlice.reducer