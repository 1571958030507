import './InPlayItemLoaderPoUp.scss'
import React from 'react';
import { createPortal } from "react-dom";
import closeIcon from '../../../assets/images/closeCross.svg'

const modalRoot = document.querySelector("#modal-root");

const InPlayItemLoaderPoUp = ({onDidDismiss}) => {
  return createPortal(
    <div className="inPlayItemLoaderPoUp">
      <div className="inPlayItemLoaderPoUp_wrapper"
        onClick={(e) => {
          if (e.currentTarget === e.target) {
            onDidDismiss(false)
          }
        }}>
        <div className="inPlayItemLoaderPoUp">
          <div className="inPlayItemLoaderPoUp-content">
            {/* <div className='inPlayItemLoaderPoUp-content-closeIcon'
              onClick={(e) => {onDidDismiss(false)}}>
              <img src={closeIcon} alt="cross icon" />
            </div>
            <div className="inPlayItemLoaderPoUp-content_title">
              {title}</div> */}
            <svg className="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
              <circle className="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
            </svg>
          </div>
        </div>
      </div>
    </div>,
    modalRoot
  )
}

export default InPlayItemLoaderPoUp