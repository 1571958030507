import React from 'react';
import '../../SportMarketStandard/SportMarketStandard.scss';
import { useSelector } from 'react-redux';
import { profitCalculate } from '../../../helpers/profitCalculate';
import { getCurrencyFormat } from '../../../helpers/getCurrencyFormat.helper';
import OddButton from '../../OddButton/oddbutton';
import BetPlacing from '../../BetPlacing/BetPlacing';
import timerIcon from "../../../assets/images/timer.svg";



const VirtualMarketStandard = ({data, eventOpenBets}) => {
    const width = window.innerWidth;
    const betPlacing = useSelector((state) => state.betPlacing);
    
    const renderOdds = (item, runner) => {
        const status =  data?.marketBook?.status;
    
        if (status && !!status?.localeCompare("OPEN")) {
            return (
                <div className="SportEvent__market__status">{status?.toUpperCase()}</div>
            );
        }
    
            
        return (
            <>
                <OddButton
                    market={data}
                    isBack={true}
                    oddData={{ ...item?.availableToBack, runner }}
                    showEmpty
                    centered
                />
                <OddButton
                    market={data}
                    isBack={false}
                    oddData={{ ...item?.availableToLay, runner }}
                    showEmpty
                    centered
                />
            </>
        );
    };



    const renderExposure = (runnerId) => {
        let number = 0, calced;
        
        number = profitCalculate(eventOpenBets, runnerId, data?.marketId);
        
        if (betPlacing.market == data.marketId) {
            if (betPlacing.runner == runnerId) {
                if (betPlacing.isBack) {
                    const profit = (
                        betPlacing.stake * betPlacing.priceActual -
                        betPlacing.stake
                    ).toFixed(2);
                    calced = (number) + Number(profit);
                } else {
                    const profit = (
                        betPlacing.stake * betPlacing.priceActual -
                        betPlacing.stake
                    ).toFixed(2);
                    calced = (number) + Number(profit) * -1;
                }
            } else {
                calced = betPlacing.isBack
                ? number - Number(betPlacing.stake)
                : number - Number(betPlacing.stake) * -1;
            }
        }
    
        if (number || calced){
            return (
                <div className="SportEvent__market__title__exposure">
                    {number ? (
                        <div className={`${number > 0 ? "positive" : "negative"}`}>
                            {getCurrencyFormat(number)}
                        </div>
                    ) : null}
                    {(calced || (number && calced == 0)) && (
                        <div className={`${calced > 0 ? "positive" : "negative"}`}>
                            » {getCurrencyFormat(calced)}
                        </div>
                    )}
                </div>
            );
        }
        return null;
    };

    const betDelay = betPlacing?.betDelay?
        data?.eventTypeId == 'v9'? betPlacing?.betDelay?.virtualBetDelay :
        data?.eventTypeId == 'b9'? betPlacing?.betDelay?.ballbyballBetDelay : 0 : 0;

  
    return (
        <>
            {width<1024?
                <section className={`SportMarketStandard ${data?.runners?.length < 2 ? 'short' : ''}`}>
                    <div className="SportMarketStandard__markets">
                        {(data?.runners?.length>0? 
                            (data?.runners) : 
                            data?.marketBook?.runners?.length>0? (data?.marketBook?.runners) :
                            []
                        ).map((item, index) => {
                            const runner = {
                                id: item?.selectionId || data?.marketBook?.runners[index]?.selectionId || data?.runners[index]?.selectionId || '',
                                name: item?.runnerName || data?.marketBook?.runners[index]?.runnerName || data?.runners[index]?.runnerName || '',
                                status: data?.marketBook?.status || 'SUSPENDED',
                            }
                            const status =  data?.marketBook?.status;

                            return(
                                <div key={item?.selectionId} className="SportEvent__market">
                                    <div className={`SportEvent__market__row`}>
                                        <div className="SportEvent__market__title">
                                            <div className={`SportEvent__market__title__text`}>
                                                <div>
                                                    <p>
                                                        <span>{runner?.name}</span>
                                                    </p>
                                                </div>
                                                {renderExposure(runner?.id)}
                                            </div>
                                            {(data?.marketBook?.runners[index]?.status === 'WINNER' ||
                                                data?.runners[index]?.status === 'WINNER')&& (
                                                <span className={`SportEvent__market-showStatus`}>Winner</span>
                                            )}
                                        </div>
                                        {renderOdds(item, runner)}
                                    </div>
                                    {(betPlacing.market == data?.marketId)&& 
                                    (status)&& (betPlacing.runner == runner?.id)&&
                                    (!status.localeCompare("OPEN"))?
                                        <BetPlacing />
                                        :
                                        null
                                    }
                                </div>
                            );
                        })}
                    </div>
                </section>
                :
                <section className={`SportMarketStandard moreOdds ${data?.runners?.length < 2 ? 'short' : ''}`}>
                    <div className="SportMarketStandard__header">
                        <div className={`SportEvent__market__title ${betDelay >= 1 ? "withDelay" : ""}`}>
                            <span className="SportEvent__market__title-name">Who Will win the Match?</span>

                            {(betDelay >= 1)?
                                <div className="SportMarketStandard__title-timer">
                                    <img
                                        className="SportMarketStandard__title-timer-img"
                                        src={timerIcon}
                                        alt="timer icon"
                                        loading={"lazy"}
                                    />
                                    <span className="SportMarketStandard__title-timer-delay">
                                        {betDelay}s
                                    </span>
                                </div>
                                :
                                null
                            }
                        </div>

                        <div className="SportMarketStandard__header__action-wrapper">
                            <div className="SportMarketStandard__header__action">Back</div>
                            <div className="SportMarketStandard__header__action">Lay</div>
                        </div>
                    </div>
                    <div className="SportMarketStandard__markets">
                        {(data?.runners?.length>0? 
                            (data?.runners) : 
                            data?.marketBook?.runners?.length>0? (data?.marketBook?.runners) :
                            []
                        ).map((item, index) => {
                            const runner = {
                                id: item?.selectionId || data?.marketBook?.runners[index]?.selectionId || data?.runners[index]?.selectionId || '',
                                name: item?.runnerName || data?.marketBook?.runners[index]?.runnerName || data?.runners[index]?.runnerName || '',
                                status: data?.marketBook?.status || 'SUSPENDED',
                            }

                            return(
                                <div key={item?.selectionId} className="SportEvent__market">
                                    <div className={`SportEvent__market__row`}>
                                        <div className="SportEvent__market__title">
                                            <div className={`SportEvent__market__title__text ${betPlacing?.betDelay? "withDelay" : ""}`}>
                                                <div style={{width: '100%'}}>
                                                    <p>
                                                        <span className="SportEvent__market-item-name">{runner?.name}</span>
                                                    </p>
                                                </div>
                                                {renderExposure(runner?.id)}
                                            </div>
                                            {(data?.marketBook?.runners[index]?.status === 'WINNER' ||
                                                data?.runners[index]?.status === 'WINNER')&& (
                                                <span className={`SportEvent__market-showStatus`}>
                                                    Winner
                                                </span>
                                            )}
                                        </div>
                                        <div className={`SportEvent__market__odds-wrapper`}>
                                            {renderOdds(item, runner)}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </section>
            }
        </>
    )
}

export default VirtualMarketStandard;