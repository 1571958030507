import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setConfirmation, setMarket, setRunner, setIsBack, setPrice, setPriceActual, setStake, setDelay, setShowBetPlacingPopover, setBetPlacingPopoverStage, setMarketType, setMarketName } from '../../store/betPlacing/betPlacing.slice'
import { requestSetOrder } from '../../store/betPlacing/betPlacing.thunks'
import { setLoginNew } from '../../store/LoginNew/loginNew.slice'
import { requestStakeButtons } from '../../store/stakes/stakes.thunks'

export const connectBetPlacing = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      betPlacing: state.betPlacing,
      presetStakeSettings: state.user.settings.presetStakeSettings,
      oneClick: state.user.settings.oneClickSettings.active,
      sportEvent: state.sportEvent,
      auth: state.auth,
      messages: state.message,
      notifications: state.user.settings.notifications,
      stakes: state.stakes,
      user: state.user,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestSetOrder,
      setConfirmation,
      setMarket,
      setRunner,
      setIsBack,
      setPrice,
      setPriceActual,
      setDelay,
      setStake,
      setShowBetPlacingPopover,
      setBetPlacingPopoverStage,
      setLoginNew,
      setMarketType,
      setMarketName,
      requestStakeButtons
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
