import "./AccountStatementPage.scss";
import { IonButton, IonContent, IonDatetime, IonHeader, IonItem, IonLabel, IonPage } from '@ionic/react'
import React, { useEffect, useState } from "react";
import AccountStatementDetailsTable from "../../components/AccountStatementDetailsTable/AccountStatementDetailsTable";
import AccountStatementTable from "../../components/AccountStatementTable/AccountStatementTable";
import Toolbar from "../../components/Toolbar/Toolbar";
// import arrowGrean from "../../assets/images/arrow-grean.svg";
// import arrowRed from "../../assets/images/arrow-red.svg";
import { connectAccountStatementPage } from "./AccountStatementPage.connect";
import { useHistory } from "react-router";
import DatePicker from "react-date-picker";
import "react-calendar/dist/Calendar.css";
// import FooterDesktop from '../../components/FooterDesktop/FooterDesktop'
// import { getCurrencyFormat } from "../../helpers/getCurrencyFormat.helper";
// import { StatementData } from "../../constants/StatementData";
import { Socket } from "../../services/socket";
import { BettingEventsData } from "../../constants/BettingEventsData";

const AccountStatementPage = ({ accountStatement, user, actions, elementsParams }) => {
  const history = useHistory();
  const [selectedDateFrom, setSelectedDateFrom] = useState(new Date(new Date().setDate(new Date().getDate() - 7)));
  const [selectedDateTo, setSelectedDateTo] = useState(new Date());
  const [selectPNLItem, setSelectPNLItem] = useState(null);
  const [detailsPnlData, setDetailsPnlData] = useState([]);
  const [validationError, setValidationError] = useState("");
  const [statementData, setStatementData] = useState([]);
  const [totalPL, setTotalPL] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState('All');
  // const [choosenFrom, setChoosenFrom] = useState(false)
  // const [choosenTo, setChoosenTo] = useState(false)
  // const [onOpen, setOnOpen] = useState(false)


  useEffect(() => {
    Socket.on('get-logs-success', GetLogsSuccess);

    return () => {
      Socket.off('get-logs-success', GetLogsSuccess);
    };
  }, []);


  const GetLogsSuccess = (...args) => {
    actions.getAccountStatementSuccess(args[0]);
  };


  useEffect(() => { 
    if(accountStatement && accountStatement?.length >0) {
      if(selectedFilter) {
        filterStatements(selectedFilter);
      }else{
        setSelectedFilter('All');
        filterStatements('All');
      }

    }
  }, [accountStatement]);

  useEffect(() => {
    if(statementData?.length >0) {
      const totalProfitLoss = statementData.reduce((sum, item) => sum + item.amount || 0, 0);
      setTotalPL(totalProfitLoss);
    }else{
      setTotalPL(0);
    }
  }, [statementData]);



  const filterStatements = (filterVal) => {
    if(filterVal == 'All') {
      setStatementData(accountStatement);
    }else {
      const filterData = accountStatement.filter(item => filterVal === item.eventTypeId);
      setStatementData(filterData);
    }


  }



  const dateValidation = (from, to) => {
    if (from > to) return setValidationError("'To' date must be greater than 'From' date.");
    else if (setValidationError) setValidationError("");
    if (new Date().setHours(23, 59, 59, 999) < to) return setValidationError("'To' date must not be greater than today's date.");
    else if (setValidationError) setValidationError("");

    // if (!validationError) {
    //   actions
    //     .requestAccountStatementSuccess(user.memberCode, Date.parse(selectedDateFrom), Date.parse(selectedDateTo))
    //     .catch((e) => console.log("getWithTimeLimit = ", e));
    // }
  };

  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth

  useEffect(() => {
    if (user && user?.memberCode && selectPNLItem && selectPNLItem.description.market && selectPNLItem?.providerId === 7) {
      actions
        .requestBetHistorySuccess(selectPNLItem.description.market)
        .then((data) => setDetailsPnlData(data.data.result))
        .catch((e) => console.log("requestBetHistoryError = ", e));
      return;
    }
    if (user && user?.memberCode && selectPNLItem && selectPNLItem.description.market) {
      const DateFrom = selectedDateFrom && new Date(selectedDateFrom).setHours(0, 0, 0, 0);
      const DateTo = selectedDateTo && new Date(selectedDateTo).setHours(23, 59, 59, 999);
      actions
        .requestOrderListSuccess(user.memberCode, "settled", selectPNLItem.description.market, DateFrom, DateTo)
        .then((data) => setDetailsPnlData(data.data.result))
        .catch((e) => console.log("requestOrderListError = ", e));
      return;
    }
  }, [selectPNLItem]);

  // useEffect(() => {
  //   const DateTo = selectedDateTo && new Date(selectedDateTo).setHours(23, 59, 59, 999);
  //   const DateFrom = selectedDateFrom && new Date(selectedDateFrom).setHours(0, 0, 0, 0);
  //   if (history.location.pathname.indexOf("/account_statement") !== -1) actions.requestAccountStatementSuccess(user.memberCode, DateFrom, DateTo);

  // }, []);

  const getWithTimeLimit = () => {
    setSelectPNLItem(null);
    const DateFrom = selectedDateFrom && new Date(selectedDateFrom).setHours(0, 0, 0, 0);
    const DateTo = selectedDateTo && new Date(selectedDateTo).setHours(23, 59, 59, 999);
    dateValidation(DateFrom, DateTo);
  };

  let todayDate = new Date()

  const getMonth = (date) => {
    return `${date.toLocaleDateString('en-GB', {
      month: '2-digit',
    })}`
  }

  const getDate = (date) => {
    return `${date.toLocaleDateString('en-GB', {
      day: '2-digit',
    })}`
  }


  // const displayAccountStatement = () => {
  //   // return accountStatement && accountStatement.data && accountStatement.data.map((value, index) => {
  //   return StatementData && StatementData.data && StatementData?.data.map((value, index) => {
  //     let dateItem = new Date(value.date);

      

  //     const fetchPNLData = (providerId = 1) => {
  //       switch (providerId) {
  //         case 1: {
  //           user && actions
  //             .requestOrderListSuccess(user.memberCode, "settled", value.description.market)
  //             .then((data) => goToDetailsPage(data.data.result))
  //             .catch((e) => console.log("requestOrderListError = ", e));

  //           break;
  //         }

  //         case 7: {
  //           actions
  //             .requestBetHistorySuccess(value.description.market)
  //             .then(() => goToDetailsPage())
  //             .catch((e) => console.log("requestBetHistoryError = ", e));

  //           break;
  //         }
  //       }
  //     };

  //     const selectPNLItem = () => {
  //       fetchPNLData(value.providerId);
  //     };
  //     const goToDetailsPage = (data) => {
  //       console.log("value", data);
  //       history.push({
  //         pathname: "/account_statement_details",
  //         state: { itemData: data[0] },
  //       });
  //     };
  //     return (
  //       <div className="account-statement-page__list-row" key={index}>
  //         <div className="account-statement-page__content-group">
            
  //           <div className="account-statement-page__item">
  //             <div>
  //               {value.action === "TRANSFER_UP" || value.action === "TRANSFER_DOWN" || value.action === "Credit" || value.action === "OPEN"
  //                 ? <div className="account-statement-page__item__remark">{value.actionDisplayName}</div>
  //                 : <div className="account-statement-page__item__remark"
  //                   onClick={() => selectPNLItem()}>{value.description !== null && value.description.eventName}</div>}
  //               <div className="account-statement-page__item__right-side account-statement-page__item__balanse">
  //                 Bal. {getCurrencyFormat(value.balance)}
  //               </div>
  //             </div>
  //             <div>
  //               <div className="account-statement-page__item__title">Date</div>
  //               <div className="account-statement-page__item__right-side data">
  //                 <span className="date">
  //                   {`${("0" + dateItem.getDate()).slice(-2)}/${("0" + (dateItem.getMonth() + 1)).slice(-2)}/${dateItem.getFullYear()}`}
  //                 </span>
  //                 <span className="time">
  //                   {`${dateItem.getHours() < 10 ? ("0" + dateItem.getHours()).slice(-2) : dateItem.getHours()}:${dateItem.getMinutes() < 10 ? ("0" + dateItem.getMinutes()).slice(-2) : dateItem.getMinutes()}:${dateItem.getSeconds() < 10 ? ("0" + dateItem.getSeconds()).slice(-2) : dateItem.getSeconds()}`}
  //                 </span>
  //               </div>
  //             </div>

  //             <div>
  //               <div className="account-statement-page__item__title">Description</div>
  //               <div className="account-statement-page__item__right-side">
  //                 {value.actionDisplayName}
  //               </div>
  //             </div>
  //             <div>
  //               <div className="account-statement-page__item__title">P&L</div>
  //               <div className={`account-statement-page__item__right-side ${value.pnl >= 0 ? "green" : "red"}`}>
  //                 {value.pnl >= 0
  //                   ? <img loading={"lazy"} src={arrowGrean} />
  //                   : <img loading={"lazy"}  src={arrowRed} />}
  //                 {getCurrencyFormat(value.pnl)}
  //               </div>
  //             </div>

  //             <div>
  //               <div className="account-statement-page__item__title">Credit Limit</div>
  //               <div className="account-statement-page__item__right-side">
  //                 {value.creditLimit ? getCurrencyFormat(value.creditLimit) : "-"}
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   });
  // };


  useEffect(() => {
    if(selectedDateFrom && selectedDateTo) {
      requestStatement();
    }
  }, [])

  const requestStatement = () => {
    let sendData = {
      token: user?.verifytoken,
      filter: {
        username: user?.details?.username,
        action: { $in: ['BALANCE', 'AMOUNT', , 'COMMISION'] },
        deleted: false,
        createDate: {
          $gte: selectedDateFrom,
          $lte: selectedDateTo,
        },
      },
      sort: { _id: 1 },
    };

    Socket.emit('get-logs', sendData);
  }

  const submit = () => {
    if(selectedDateFrom && selectedDateTo) {
      requestStatement();
    }
  }


  const handleFilter = (e) => {
    const filterVal = e.target.value;
    setSelectedFilter(filterVal);

    if(accountStatement && accountStatement?.length >0) {
      filterStatements(filterVal);
    }
  }



  if (width < 1024) {
    return (
      <IonPage>
        <IonHeader>
          <Toolbar />
        </IonHeader>
        <IonContent className="account-statement-page" fullscreen>
          <div className="page-title-wrapper">
            <h2 className="page-title">Account Statement</h2>
          </div>
          
          <div className="account-statement-page__filter-content">
            
            {/* <div>
              <button onClick={() => {
                setfilterOpen((prevValue) => !prevValue)
                // setChoosenFrom(false)
                // setChoosenTo(false)
              }}
                className={`filter-button ${filterOpen && "filter-button--active"}`}>Filter
              </button>
            </div> */}

            {/* {filterOpen && <div className="account-statement-page__filter-data-piker">

              <IonItem>
                <IonLabel position="floating">Date from:</IonLabel>
                <IonDatetime displayFormat="MM /DD/YYYY" id="befg1" value={selectedDateFrom}
                             onIonChange={e => setSelectedDateFrom(e.detail.value)}></IonDatetime>
              </IonItem>

              <IonItem>
                <IonLabel position="floating">Date to:</IonLabel>
                <IonDatetime displayFormat="MM/DD/YYYY" id="befg2" value={selectedDateTo}
                             onIonChange={e => setSelectedDateTo(e.detail.value)}></IonDatetime>
              </IonItem>

              <IonButton size="large" onClick={() => setfilterOpen((prevValue) => !prevValue)}>
                Apply
              </IonButton>
            </div>} */}


            {/* {filterOpen && */}
              <div className="account-statement-page__filter-content">
                {/* <div className="account-statement-page__filters-date-input"
                  // style={{marginRight: "15px"}}
                  onClick={() => {
                    // setChoosenFrom(true)
                  }}> */}
                  {/* <p style={choosenFrom? { fontSize: '12px', transition: 'linear 0.2s' }: {}}></p> */}
                    {/* <div className="custom-date-picker">
                      <DatePicker
                        onChange={setSelectedDateFrom}
                        value={selectedDateFrom}
                        locale={'en-US'}
                        showLeadingZeros={true}
                        clearIcon={null}
                        // calendarIcon={null}
                        calendarIcon={<span role="img" aria-label="calendar">📅</span>}
                        format={'dd/MM/yyyy'}
                        dayPlaceholder={getDate(todayDate)}
                        monthPlaceholder={getMonth(todayDate)}
                        yearPlaceholder={todayDate.getFullYear()}
                        // className="react-date-picker__wrapper"
                      />
                    </div> */}
                {/* </div> */}

                
                <div className="custom-date-picker">
                  <DatePicker
                    onChange={setSelectedDateFrom}
                    value={selectedDateFrom}
                    showLeadingZeros={true}
                    locale={'en-US'}
                    format={'dd/MM/yyyy'}
                    calendarIcon={<span role="img" aria-label="calendar">📅</span>}
                    clearIcon={null}
                    className="react-date-picker"
                    dayPlaceholder={getDate(todayDate)}
                    monthPlaceholder={getMonth(todayDate)}
                    yearPlaceholder={todayDate.getFullYear()}
                  />
                </div>

                
                {/* <div className="account-statement-page__filters-date-input"
                  // style={{marginRight: "15px"}}
                  onClick={() => {
                    // setChoosenTo(true)
                  }}>
                  <p style={choosenTo? { fontSize: '12px', transition: 'linear 0.2s' }: {}}></p>
                  <div>
                    <DatePicker
                      onChange={setSelectedDateTo}
                      value={selectedDateTo}
                      locale={'en-US'}
                      showLeadingZeros={true}
                      clearIcon={null}
                      calendarIcon={null}
                      format={'dd/MM/yyyy'}
                      dayPlaceholder={getDate(todayDate)}
                      monthPlaceholder={getMonth(todayDate)}
                      yearPlaceholder={todayDate.getFullYear()}
                    />
                  </div>
                </div> */}
                
                <div className="custom-date-picker">
                  <DatePicker
                    onChange={setSelectedDateTo}
                    value={selectedDateTo}
                    showLeadingZeros={true}
                    locale={'en-US'}
                    format={'dd/MM/yyyy'}
                    calendarIcon={<span role="img" aria-label="calendar">📅</span>}
                    clearIcon={null}
                    className="react-date-picker"
                    dayPlaceholder={getDate(todayDate)}
                    monthPlaceholder={getMonth(todayDate)}
                    yearPlaceholder={todayDate.getFullYear()}
                  />
                </div>


                <select 
                  formcontrolname="event_type_id" 
                  name="reportType" 
                  className="filter-select"
                  style={{padding: '7px'}}
                  value={selectedFilter}
                  onChange={handleFilter}
                >
                  <option value={'All'}>All</option>
                  {(BettingEventsData.length>0)&&
                    BettingEventsData.map((item) => {
                      return(
                        <option 
                          key={item.eventTypeId} 
                          value={item.eventTypeId}
                        >{item.name}
                        </option>
                      );
                    })
                  }
                </select>


                <button className="account-statement-page__date-search-button"
                  // disabled={!(selectedDateFrom && selectedDateTo)}
                  onClick={() => {submit();}}
                >
                  <span>Apply</span>
                </button>
                {validationError && (<div className="account-statement-page__filter-content-error">{validationError}</div>)}
              </div>
            {/* } */}


          </div>

          {/* {
            (accountStatement && !accountStatement.data) ?
              <div style={{ marginLeft: '25px' }} className="account-statement-page__content">
                <div className="account-statement-page__content--empty">
                  No data to display
                </div>
              </div>
              :
              (!filterOpen && <div className="account-statement-page__content">
                {displayAccountStatement()}
              </div>)
          } */}
          {
            (statementData.length == 0) ?
              <div style={{ marginLeft: '25px' }} className="account-statement-page__content">
                <div className="account-statement-page__content--empty">
                  No data to display
                </div>
              </div>
              :
              (<div className="account-statement-page__content">
                  {selectPNLItem && (
                    <AccountStatementDetailsTable
                      // accountStatement={accountStatement}
                      accountStatement={statementData}
                      selectPNLItem={selectPNLItem}
                      detailsPnlData={detailsPnlData}
                      totalPnL={totalPL}
                      goBack={() => setSelectPNLItem(null)}
                    />)
                  }
                  {!selectPNLItem&&
                    <AccountStatementTable
                      // accountStatement={accountStatement}
                      accountStatement={statementData}
                      setSelectPNLItem={item => setSelectPNLItem(item)}
                      totalPnL={totalPL}
                      showTotalPL={true}
                    />
                  }
                </div>
              )
          }
          {/*{width < 1024 && <FooterDesktop />}*/}
        </IonContent>
      </IonPage>
    );
  }
  return (
    // <IonPage>
    //   <IonHeader>
    //     <Toolbar />
    //   </IonHeader>
    <div className="account-statement-page" fullscreen>
      <div className="account-statement-page-wrapper">
        <div className="page-title-wrapper account-statement-page-title-wrapper">
          <h2 className="page-title account-statement-page-title">Account Statement</h2>
        </div>
        <div className="account-statement-page__filter-content">
          <div className="account-statement-page__filters-date-input" style={{ marginRight: "15px" }}>
            <p>From</p>
            <div>
              <DatePicker
                onChange={setSelectedDateFrom}
                value={selectedDateFrom}
                locale={'en-US'}
                showLeadingZeros={true}
                clearIcon={null}
                calendarIcon={null}
                format={'dd/MM/yyyy'}
                dayPlaceholder={getDate(todayDate)}
                monthPlaceholder={getMonth(todayDate)}
                yearPlaceholder={todayDate.getFullYear()}
              />
            </div>
          </div>
          <div className="account-statement-page__filters-date-input" style={{ marginRight: "15px" }}>
            <p>To</p>
            <div>
              <DatePicker
                onChange={setSelectedDateTo}
                value={selectedDateTo}
                locale={'en-US'}
                showLeadingZeros={true}
                clearIcon={null}
                calendarIcon={null}
                format={'dd/MM/yyyy'}
                dayPlaceholder={getDate(todayDate)}
                monthPlaceholder={getMonth(todayDate)}
                yearPlaceholder={todayDate.getFullYear()}
              />
            </div>
          </div>
          <div className="account-statement-page__filters-date-input" style={{ marginRight: "15px" }}>
            <p>Type</p>
            <div>
              <select 
                formcontrolname="event_type_id" 
                name="reportType" 
                className="react-date-picker__wrapper filter-select"
                style={{padding: '7px'}}
                value={selectedFilter}
                onChange={handleFilter}
              >
                <option value={'All'}>All</option>
                {(BettingEventsData.length>0)&&
                  BettingEventsData.map((item) => {
                    return(
                      <option 
                        key={item.eventTypeId} 
                        value={item.eventTypeId}
                      >{item.name}
                      </option>
                    );
                  })
                }
                {/* <option value="All">All </option>
                <option value="Cricket">Cricket </option>
                <option value="Tennis">Tennis </option>
                <option value="Football">Football </option>
                <option value="Casino">Casino </option> */}
              </select>
            </div>
          </div>
          <button className="account-statement-page__date-search-button"
            // disabled={!(selectedDateFrom && selectedDateTo)}
            onClick={() => {submit();}}
          >
            <span>
              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.8023 11.8409L9.59762 8.623C10.4216 7.67732 10.8731 6.48745 10.8731 5.24874C10.8731 2.35463 8.4342 0 5.43654 0C2.43888 0 0 2.35463 0 5.24874C0 8.14286 2.43888 10.4975 5.43654 10.4975C6.5619 10.4975 7.63432 10.1698 8.5512 9.5477L11.7803 12.7901C11.9152 12.9254 12.0968 13 12.2913 13C12.4754 13 12.6501 12.9322 12.7827 12.809C13.0645 12.5472 13.0735 12.1132 12.8023 11.8409ZM5.43654 1.36924C7.65229 1.36924 9.45485 3.10954 9.45485 5.24874C9.45485 7.38795 7.65229 9.12825 5.43654 9.12825C3.2208 9.12825 1.41823 7.38795 1.41823 5.24874C1.41823 3.10954 3.2208 1.36924 5.43654 1.36924Z"
                  fill="white" />
              </svg>
            </span>
            <span>Search</span>
          </button>
          {validationError && (<div className="account-statement-page__filter-content-error">{validationError}</div>)}
        </div>
        <div className="account-statement-page__content">
          {
            selectPNLItem && (
              <AccountStatementDetailsTable
                // accountStatement={accountStatement}
                accountStatement={statementData}
                selectPNLItem={selectPNLItem}
                detailsPnlData={detailsPnlData}
                totalPnL={totalPL}
                goBack={() => setSelectPNLItem(null)}
              />)
          }
          {
            !selectPNLItem &&
            <AccountStatementTable
              // accountStatement={accountStatement}
              accountStatement={statementData}
              setSelectPNLItem={item => setSelectPNLItem(item)}
              showTotalPL={true}
              totalPnL={totalPL}
            />
          }
        </div>
      </div>
    </div>
    // </IonPage>
  );
};

export default connectAccountStatementPage()(AccountStatementPage);
