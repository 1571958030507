import './promotion.scss'
import React from 'react'
import { IonItemGroup } from '@ionic/react';
import TopBaner from '../TopBaner/TopBaner';
import QuickButtons from './QuickButtons/QuickButtons';
import { connectPromotions } from './Promotion.connect';
import { useEffect } from 'react';
import { quickButtonData, sportsButtonData, promotionBanner } from '../../constants/promotionData';
import SportsButtons from './SportButtons/SportsButtons'

const Promotion = ({ inplayPopular, promotions, actions, auth }) => {
  useEffect(() => {
    actions.requestPromotions()
  }, [])

  // console.log("Promotions: ", promotions);


  return (
    <>
        {(promotions || true)? 
          <IonItemGroup className="promotion-content">
            {promotionBanner?.length >= 1 ? <TopBaner inPlaySlidingBanners={promotionBanner} /> : ''}
            {sportsButtonData?.length >=1 ? <SportsButtons sportsButtons={sportsButtonData}/>  : "" }
            {quickButtonData?.length >= 1 ? <QuickButtons quickButtons={quickButtonData} auth={auth} /> : ''}
            
            
            {/* {promotions.inPlaySlidingBanners && promotions.inPlaySlidingBanners.length >= 1 ? <TopBaner inPlaySlidingBanners={promotions.inPlaySlidingBanners} /> : ''}
            {promotions.sportsButtons && promotions.sportsButtons.length >=1 ? <SportsButtons sportsButtons={promotions.sportsButtons}/>  : "" }
            {promotions.quickButtons && promotions.quickButtons.length >= 1 ? <QuickButtons quickButtons={promotions.quickButtons} auth={auth} /> : ''} */}
          </IonItemGroup> 
          : 
          ''
        }
    </>
  );
}

export default connectPromotions()(Promotion)
