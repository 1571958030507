export const VirtualData = {
    eventTypeId: '4',
    eventName: '🎮 Virtual',
    actualEventTypeId: 'v9',
    marketType: 'virtual',
    runners: [
        {
            "selectionId": 'v9_1',
            "runnerName": 'v9__1',
            "handicap": '',
            "sortPriority": 1
        },
        {
            "selectionId": 'v9_2',
            "runnerName": "v9__2",
            "handicap": '',
            "sortPriority": 2
        }
    ],
    marketBook: {
        "status": "OPEN",
        "inplay": true,
        "runners": [
            {
                "selectionId": 'v9_1',
                "status": "ACTIVE",
                "runnerName": "v9__1",
                "availableToBack": {
                    "price": "",
                    "size": 0
                },
                "availableToLay": {
                    "price": "1.99",
                    "size": 0
                }
            },
            {
                "selectionId": 'v9_2',
                "status": "ACTIVE",
                "runnerName": "v9__2",
                "availableToBack": {
                    "price": "",
                    "size": 0
                },
                "availableToLay": {
                    "price": "",
                    "size": 0
                }
            }
        ]
    },
}

export const BallByBallData = {
    eventTypeId: '4',
    eventName: '🎮 Ball By Ball',
    actualEventTypeId: 'b9',
    marketType: 'virtual',
    runners: [
        {
            "selectionId": 'b9_1',
            "runnerName": 'b9__1',
            "handicap": 0,
            "sortPriority": 1
        },
        {
            "selectionId": 'b9_2',
            "runnerName": "b9__2",
            "handicap": 0,
            "sortPriority": 2
        }
    ],
    marketBook: {
        "status": "OPEN",
        "inplay": true,
        "runners": [
            {
                "selectionId": 'b9_1',
                "status": "ACTIVE",
                "runnerName": "b9__1",
                "availableToBack": {
                    "price": "0",
                    "size": 0
                },
                "availableToLay": {
                    "price": "1.99",
                    "size": 0
                }
            },
            {
                "selectionId": 'b9_2',
                "status": "ACTIVE",
                "runnerName": "b9__2",
                "availableToBack": {
                    "price": "0",
                    "size": 0
                },
                "availableToLay": {
                    "price": "0",
                    "size": 0
                }
            }
        ]
    },
}

const some = {
    "runners": [
        {
            "selectionId": 15233,
            "runnerName": "Test1 ",
            "handicap": 0,
            "sortPriority": 1
        },
        {
            "selectionId": 11047,
            "runnerName": "Test2",
            "handicap": 0,
            "sortPriority": 2
        }
    ],
    "istenniscricket": 0,
    "_id": "66b07e7730c8e372f316aaad",
    "eventTypeId": "4",
    "eventTypeName": "Cricket",
    "competitionId": "101480",
    "competitionName": "Indian Premier League",
    "eventId": "643059",
    "eventName": "Test 1 vs Test 2",
    "openDate": "2024-08-05T07:25:00.000Z",
    "marketName": "Match Odds",
    "marketType": "MATCH_ODDS",
    "marketBook": {
        "status": "OPEN",
        "inplay": true,
        "runners": [
            {
                "selectionId": 15233,
                "status": "ACTIVE",
                "runnerName": "Test1 ",
                "availableToBack": {
                    "price": "7.99",
                    "size": 0
                },
                "availableToLay": {
                    "price": "1.99",
                    "size": 0
                }
            },
            {
                "selectionId": 11047,
                "status": "ACTIVE",
                "runnerName": "Test2",
                "availableToBack": {
                    "price": "8.99",
                    "size": 0
                },
                "availableToLay": {
                    "price": "1.99",
                    "size": 0
                }
            }
        ]
    },
    "createdBy": "65be3e2e9bc2a7610b7511a5"
}