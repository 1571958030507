import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react'
// import { IonItem } from "@ionic/react";
import './SportEvent.scss';
// import '../SportMarketTabs/SportMarketTabs.scss'
import { connectSportEvent } from './SportEvent.connect'
import SportMarketStandard from '../../components/SportMarketStandard/SportMarketStandard'
import SportMarketTabs from '../../components/SportMarketTabs/SportMarketTabs'
import LadderPopover from '../../components/LadderPopover/LadderPopover'

import RulesPopUpSportEvents from '../ExchangeGame/helpers/Rules/RulesPopUpSportEvents'
// import { EventPageData } from '../../constants/EventPageData'
import { useSelector } from 'react-redux'



const SportEvent = ({
  // sportData,
  ladderShow,
  betPlacing,
  rulesPopUp,
  actions,
  elementsParams
}) => {
  // const sportData = EventPageData;
  const sportData = useSelector((state) => state.sportEvent.sportData)



  const processGroup = (group, index) => {
    const tabGroup = {
      tabGroupId: group[0]?.marketType==="SESSION"? index + 1 : null,
      tabs: {}
    };
  
    let itemIndex = 0;
    for(let item of group) {
      if(item?.marketType==="SESSION") {
        const tab = {
          name: item.marketType==="SESSION"? 'Fancy' : item.marketName,
          marketType: item.marketType,
          marketName: item.marketType==="SESSION"? 'Fancy' : item.marketName,
          items: group
        };
        tabGroup.tabs[itemIndex] = tab;
        itemIndex++;
      }else {
        return item;
      }
  
      break;
    }
  
    return tabGroup;
  };

  const renderMarketGroups = useMemo(() => {
    let groups = [];

    if (Array.isArray(sportData)) {
      sportData.forEach((group, index) => {
        if(Array.isArray(group[0])) {
          group.forEach((subGroup, subIndex) => {
            groups.push(processGroup(subGroup, groups.length));
          })
        }else {
          groups.push(processGroup(group, groups.length));
        }
      });
    }


    if (groups.length === 0)
      return <div className="SportEvent__message">No market data available</div>

    // console.log(groups);

    return groups.map((item) =>
      (!item.tabGroupId) ? (
        <SportMarketStandard
          data={item}
          key={item._id}
          betPlacing={betPlacing}
        />
      ) : (
        <SportMarketTabs
          data={item}
          key={`TGID${item.tabGroupId}`}
          betPlacing={betPlacing}
        />
      )
    )

    

  }, [sportData, betPlacing])

  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth

  return (
    <div className="SportEvent">
      {width > 1024 ? (
        <div className={`favourites-header__rules-button-wrapper`}>
          <div
            className={`favourites-header__rules-button`}
            onClick={() => {
              actions.setRulesPopUp(true)
            }}
          >
            <div className={`favourites-header__rules-button-icon`}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 0C3.1402 0 0 3.14024 0 7.00004C0 10.8598 3.1402 14 7 14C10.8598 14 14 10.8598 14 7.00004C14 3.14024 10.8598 0 7 0ZM7 12.7273C3.84194 12.7273 1.27273 10.1581 1.27273 7.00004C1.27273 3.84202 3.84194 1.27273 7 1.27273C10.1581 1.27273 12.7273 3.84202 12.7273 7.00004C12.7273 10.1581 10.158 12.7273 7 12.7273Z"
                  fill="black"
                />
                <path
                  d="M7.00017 2.96875C6.5324 2.96875 6.15186 3.34955 6.15186 3.81762C6.15186 4.28526 6.5324 4.66572 7.00017 4.66572C7.46794 4.66572 7.84849 4.28526 7.84849 3.81762C7.84849 3.34955 7.46794 2.96875 7.00017 2.96875Z"
                  fill="black"
                />
                <path
                  d="M7.00013 5.93945C6.64869 5.93945 6.36377 6.22437 6.36377 6.57582V10.394C6.36377 10.7454 6.64869 11.0304 7.00013 11.0304C7.35158 11.0304 7.6365 10.7454 7.6365 10.394V6.57582C7.6365 6.22437 7.35158 5.93945 7.00013 5.93945Z"
                  fill="black"
                />
              </svg>
            </div>
            {width > 1024 ? (
              <span className={`favourites-header__rules-button-text`}>
                Rules
              </span>
            ) : null}
          </div>
          {rulesPopUp && <RulesPopUpSportEvents />}
        </div>
      ) : null}

      {renderMarketGroups}
      <LadderPopover open={ladderShow} />
    </div>
  )
}
export default connectSportEvent()(SportEvent)
