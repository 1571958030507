import React, { useState, useEffect } from 'react'
import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonHeader,
} from '@ionic/react'
import './Settings.scss'
import Toolbar from '../../components/Toolbar/Toolbar'
import OneClickToggle from '../../components/OneClickToggle';

import { connectSettings } from './Settings.connect'

const Settings = ({ user, actions, elementsParams, stakes, ...props }) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const [checked, setChecked] = useState(user?.settings?.oneClickSettings?.active || false);
  const [activeStakeSelected, setActiveStakeSelected] = useState(user?.settings?.oneClickSettings?.selected || false);
  const [editOneclick, setEditOneclick] = useState(false)
  // const [firstOneclick, setFirstOneclick] = useState(user?.settings?.oneClickSettings?.settings[0] || false)
  const [firstOneclick, setFirstOneclick] = useState(false)
  // const [secondOneclick, setSecondOneclick] = useState(user?.settings?.oneClickSettings?.settings[1] || false)
  const [secondOneclick, setSecondOneclick] = useState(false)
  // const [thirdOneclick, setThirdOneclick] = useState(user?.settings?.oneClickSettings?.settings[2] || false)
  const [thirdOneclick, setThirdOneclick] = useState(false)

  //intial three fileds for casino stake
  // const [casinoStake, setCasinoStake] = useState([...user.settings.presetStakeSettings.mobileStakesExchangeGameSettings] || [false, false, false]);
  const [casinoStake, setCasinoStake] = useState([false, false, false]);
  //intial three fileds for classic stake
  // const [stake, setStake] = useState([...user.settings.presetStakeSettings.mobileStakesSettings] || [false, false, false]);
  const [stake, setStake] = useState([false, false, false]);
  const [stakeButtons, setStakeButtons] = useState([]);
  const [loading, setLoading] = useState(false);

  // const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth

  const saveOneClickSettings = (withApi = false) => {
    const settings = {
      active: checked,
      selected: activeStakeSelected,
      settings: [parseInt(firstOneclick), parseInt(secondOneclick), parseInt(thirdOneclick)]
    }
    actions.requestSaveOneClickSettings(user.memberCode, settings, withApi);
  }

  const saveMobileStakesExchangeGameSettings = () => {
    actions.requestSaveMobileStakesExchangeGameSettings(user.memberCode, casinoStake);
  }

  const saveMobileStakesSettings = () => {
    actions.requestSaveMobileStakesSettings(user.memberCode, stake);
  }
  const [editCasino, setEditCasino] = useState(false)
  const [editStakes, setEditStakes] = useState(false)

  const toggleOneclick = (e) => {
    setChecked(!user.settings.oneClickSettings.active)
    setEditOneclick(false);
  }
  const activeStakeClick = (item) => {
    if (!editOneclick && checked) {
      setActiveStakeSelected(item);
    }
  }

  const handleInputCasino = (e, index) => {
    e.preventDefault();
    const val = Number(e.target.value)
    Number.isInteger(val) && setCasinoStake(() => {
      const newStake = [...casinoStake];
      newStake[index] = val;
      return newStake;
    })
  }

  const handleInputStake = (e, index) => {
    e.preventDefault();
    const val = Number(e.target.value)
    Number.isInteger(val) && setStake(() => {
      const newStake = [...stake];
      newStake[index] = val;
      return newStake;
    })
  }

  useEffect(() => {
    saveOneClickSettings();
  }, [checked]);

  useEffect(() => {
    if (activeStakeSelected !== user.settings.oneClickSettings.selected) saveOneClickSettings(true);
  }, [activeStakeSelected]);


  useEffect(() => {
    if(stakes?.length <= 0) {
      actions.requestStakeButtons()
    }
  }, []);

  useEffect(() => {
    if(stakes?.length && !stakeButtons.length) {
      setStakeButtons(stakes);
    }
  }, [stakes, stakeButtons]);


  const handleStakeChange = (e, index) => {
    e.preventDefault();
    const numVal = Number(e.target.value);
    if(Number.isInteger(numVal)) {
      setStakeButtons((prev) =>
        prev.map((item, i) => {
          if (i === index) {
            const labelKey = `label${index + 1}`;
            const priceKey = `price${index + 1}`;
            return {
              ...item,
              [priceKey]: numVal,
              [labelKey]: e.target.value,
            };
          }
          return item;
        })
      );
    }
  }


  const handleStakeUpdate = () => {
    setLoading(true);

    const requiredData = stakeButtons.reduce((acc, obj) => {
      Object.keys(obj).forEach(key => {
          acc[key] = obj[key];
      });
      return acc;
    }, {});

    actions.requestUpdateStakeButtons(requiredData).then(() => {
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    })
  }


  return (
    <IonPage>
      <IonHeader>
        <Toolbar />
      </IonHeader>
      <IonContent className="Settings" fullscreen>
        {/* <h2 className="page-title">Settings</h2> */}

        {/* <div className="Settings__item">
          <h2 className="page-title">
            One Click Settings
            <span className="apl-icon-info-sign" onClick={() => setShowTooltip(!showTooltip)}></span>
          </h2>
          {
            showTooltip && <div className="Settings__tooltip">
              <div className="info-content">
                <p>Using 1 Click Bet is easy.</p>
                <ul>
                  <li>Enable 1 Click Betting.</li>
                  <li>Edit your 1 Click stake values below.</li>
                  <li>Choose your active stake.</li>
                  <li>When 1 Click Betting is activated, choose different stakes by clicking on the red bar.</li>
                  <li>When done with 1 Click Betting, turn off by tapping the toggle to off.</li>
                </ul>
              </div>
            </div>
          }
          <IonItem className="Settings__toggle">
            <IonLabel className="Settings__toggle__hint">Tap to turn on one click betting</IonLabel>
            <OneClickToggle className="Settings__toggle__button" checked={user.settings.oneClickSettings.active} onIonChange={toggleOneclick} />
          </IonItem>
          <div className={`Settings__fields ${editOneclick ? 'on-edit' : ''}`}>
            <IonInput
              className={`Settings__input ${activeStakeSelected == 1 ? 'active-stake' : ''}`}
              placeholder="0"
              name="firstOneclick"
              disabled={!editOneclick}
              type="text"
              value={firstOneclick}
              onClick={() => activeStakeClick(1)}
              onIonChange={(e) => {
                const val = Number(e.target.value)
                if (!Number.isInteger(val)) return;
                setFirstOneclick(val)
              }}
            />
            <IonInput
              className={`Settings__input ${activeStakeSelected == 2 ? 'active-stake' : ''}`}
              placeholder="0"
              name="secondOneclick"
              disabled={!editOneclick}
              type="text"
              value={secondOneclick}
              onClick={() => activeStakeClick(2)}
              onIonChange={(e) => {
                const val = Number(e.target.value)
                if (!Number.isInteger(val)) return;
                setSecondOneclick(val)
              }}
            />
            <IonInput
              className={`Settings__input ${activeStakeSelected == 3 ? 'active-stake' : ''}`}
              placeholder="0"
              name="thirdOneclick"
              disabled={!editOneclick}
              type="text"
              value={thirdOneclick}
              onClick={() => activeStakeClick(3)}
              onIonChange={(e) => {
                const val = Number(e.target.value)
                if (!Number.isInteger(val)) return;
                setThirdOneclick(val)
              }}
            />
          </div>
          {
            editOneclick
              ? <IonButton
                onClick={() => {
                  saveOneClickSettings(true);
                  setEditOneclick(false);
                }}
                class="Settings__btn"
                disabled={false}
                mode="md"
              >
                Save
              </IonButton>
              : <IonButton
                onClick={() => setEditOneclick(true)}
                class="Settings__btn"
                disabled={!checked}
                mode="md"
              >
                Edit
              </IonButton>
          }
        </div> */}

        {/* <div className="Settings__item">
          <h2 className="page-title">Edit Casino Stakes</h2>
          <div className={`Settings__fields ${editCasino ? 'on-edit' : ''}`}>
            <IonInput
              className="Settings__input"
              placeholder="0"
              name="firstOneclick"
              disabled={!editCasino}
              type="text"
              pattern="[0-9]*"
              value={casinoStake[0]}
              onIonChange={(e) => handleInputCasino(e, 0)}
            />
            <IonInput
              className="Settings__input"
              placeholder="0"
              name="secondOneclick"
              disabled={!editCasino}
              type="text"
              pattern="[0-9]*"
              value={casinoStake[1]}
              onIonChange={(e) => handleInputCasino(e, 1)}
            />
            <IonInput
              className="Settings__input"
              placeholder="0"
              name="thirdOneclick"
              disabled={!editCasino}
              type="text"
              pattern="[0-9]*"
              value={casinoStake[2]}
              onIonChange={(e) => handleInputCasino(e, 2)}
            />
          </div>
          {
            editCasino
              ? <IonButton
                onClick={() => {
                  saveMobileStakesExchangeGameSettings();
                  setEditCasino(false)
                }}
                class="Settings__btn"
                disabled={false}
                mode="md"
              >
                Save
              </IonButton>
              : <IonButton
                onClick={() => setEditCasino(true)}
                class="Settings__btn"
                mode="md"
              >
                Edit
              </IonButton>
          }
        </div> */}

        <div className="Settings__item" style={{marginBottom: '80px'}}>
          <h2 className="page-title">Edit Stakes</h2>
          <div className={`Settings__fields ${editStakes ? 'on-edit' : ''}`}>
            {(stakeButtons?.length>0)&&
              stakeButtons.map((item, index) => {
                return(
                  <IonInput
                    key={index}
                    className="Settings__input"
                    placeholder="0"
                    name={`firstOneclick${index}`}
                    // disabled={!editStakes}
                    type="text"
                    value={item[`price${index+1}`]}
                    onIonChange={(e) => {handleStakeChange(e, index)}}
                  />
                );
              })
            }

            {/* <IonInput
              className="Settings__input"
              placeholder="0"
              name="firstOneclick"
              disabled={!editStakes}
              type="text"
              value={stake[0]}
              onIonChange={(e) => handleInputStake(e, 0)}
            />
            <IonInput
              className="Settings__input"
              placeholder="0"
              name="secondOneclick"
              disabled={!editStakes}
              type="text"
              value={stake[1]}
              onIonChange={(e) => handleInputStake(e, 1)}
            />
            <IonInput
              className="Settings__input"
              placeholder="0"
              name="thirdOneclick"
              disabled={!editStakes}
              type="text"
              value={stake[2]}
              onIonChange={(e) => handleInputStake(e, 2)}
            /> */}
          </div>
          
          <IonButton
            onClick={() => {handleStakeUpdate();}}
            disabled={loading}
            class="Settings__btn"
            mode="md"
          >
            Edit
          </IonButton>

          {/* {
            editStakes
              ? <IonButton
                onClick={() => {
                  saveMobileStakesSettings();
                  setEditStakes(false)
                }}
                class="Settings__btn"
                disabled={false}
                mode="md"
              >
                Save
              </IonButton>
              : <IonButton
                onClick={() => setEditStakes(true)}
                class="Settings__btn"
                mode="md"
              >
                Edit
              </IonButton>
          } */}
        </div>
      </IonContent>
    </IonPage>
  )
}

export default connectSettings()(Settings)
