import React from 'react'
import { useEffect, useState } from 'react'
import './DesktopOpenBetsItem.scss'
import { tsToDate } from '../../helpers/date.helper'
import { useHistory } from 'react-router-dom'
import { getFormattedDate } from '../../helpers/date.helper'
import { useDispatch, useSelector } from 'react-redux'
import { setInfo } from '../../store/openBets/openBets.slice'
import { IonInput } from '@ionic/react'
import { setResetUnmatched, setUnmatchedChanged } from '../../store/openBetsList/openBetsList.slice'
import { getCurrencyFormatRoundedOff } from '../../helpers/getCurrencyFormat.helper'

const DesktopOpenBetsItem = ({
  timeSettings,
  bet,
  isEvent,
  isUnmatched = false,
  cancel,
  redirect = false,
  consolidate,
  stake,
  isAverage,
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const setInfoDispatch = useSelector((state) => state.openBetsList.detailed)
  const [isRemovingBet, setIsRemovingBet] = useState(false);

  // console.log(stake);

  // console.log(bet);

  // const isInfo = useSelector((state) => state)

  const [unmatchedOdd, setUnmatchedOdd] = useState(bet?.price || 0)
  const [unmatchedStake, setUnmatchedStake] = useState(bet?.stake || 0)
  const resetUnmatched = useSelector((state) => state.openBetsList.reset)

  const ripple = (event) => {
    const elem = event.currentTarget

    var rect = event.target.getBoundingClientRect()
    let x = event.clientX - elem.offsetLeft
    let y = rect.height - rect.bottom + event.clientY
    let rippleElement = document.createElement('span')
    rippleElement.style.left = x + 'px'
    rippleElement.style.top = y + 'px'
    elem.appendChild(rippleElement)
    setTimeout(function () {
      rippleElement.remove()
    }, 500)
  }

  const changeOddRipple = (event, mod, isChanging = 1) => {
    const ev = { ...event }
    // ripple(event)
    changeOdd(mod, isChanging)
  }

  useEffect(() => {
    if (resetUnmatched && isUnmatched) {
      setUnmatchedOdd(bet?.price)
      setUnmatchedStake(bet?.stake)
      dispatch(setResetUnmatched(false))
    }
  }, [resetUnmatched])

  useEffect(() => {
    if (isUnmatched && unmatchedStake !== bet?.stake || unmatchedOdd !== bet?.price) {
      dispatch(setUnmatchedChanged(true))
    }
    
  }, [unmatchedStake, unmatchedOdd]);


  const changeOdd = (mod, isChanging = 1) => {
    let step, dec
    const odd = Number(unmatchedOdd)
    switch (true) {
      case odd < 2: {
        step = 0.01
        dec = 2
        break
      }
      case odd < 3: {
        step = 0.02
        dec = 2
        break
      }
      case odd < 4: {
        step = 0.05
        dec = 2
        break
      }
      case odd < 6: {
        step = 0.1
        dec = 2
        break
      }
      case odd < 10: {
        step = 0.2
        dec = 2
        break
      }
      case odd < 20: {
        step = 0.5
        dec = 1
        break
      }
      case odd < 30: {
        step = 1
        dec = 0
        break
      }
      case odd < 50: {
        step = 2
        dec = 0
        break
      }
      case odd < 100: {
        step = 5
        dec = 0
        break
      }
      default: {
        step = 10
        dec = 0
      }
    }
    let newVal = +(odd + step * mod * isChanging).toFixed(2)
    if (newVal < 1.01) newVal = 1.01
    else if (newVal > 1000) newVal = 1000
    else if (!Number.isInteger(newVal / step))
      newVal = newVal - (newVal % step) + step
    newVal = Number(newVal.toFixed(dec))
    setUnmatchedOdd(newVal)
  }

  // useEffect(() => {
  //   console.log(consolidate, '2222')
  // }, [consolidate])

  const getPriseFor_SB = (marketId, price) => {
    return marketId && marketId.slice(marketId.length - 3) === '_SB'
      ? ((price - 1) * 100).toFixed(2).replace('.00', '')
      : price
  }

  const getTitle = () => {
    const isBack = bet.type == 'Back'
    const isFancy = bet.marketType == 'SESSION';
    const text = isFancy
      ? `${bet.line} runs - ${isBack ? 'Yes' : 'No'}`
      : bet.side
    const profit = isFancy
      ? ((bet.userRate * bet.stake)).toFixed(2)
      : (bet.userRate * bet.stake - bet.stake).toFixed(2)
    //TODO: maybe change averagePrice to price ?
    const averagePrice = getPriseFor_SB(bet.marketId, bet.averagePrice)
    return profit
  }

  const getName = () => {
    if (bet.eventName.indexOf('${HH:mm}') != -1) {
      const formattedOpenDate = getFormattedDate(
        bet.marketTime,
        timeSettings.timeZoneName
      )
      return bet.eventName.replace(/\${HH:mm}/, formattedOpenDate.time)
    } else {
      return bet.eventName
    }
  }
  return !isUnmatched ? (
    !isAverage ? (
      <div
        className={
          bet.side === 'Back'
            ? 'DesktopOpenBetsItem'
            : 'DesktopOpenBetsItem lay'
        }
        onClick={() => {
          if (redirect)
            history.push(`/event-page/${bet.eventTypeId}/${bet.eventId}`)
        }}
      >
        <div className="DesktopOpenBetsItem__info">
          {!consolidate ? (
            <span
              className={'DesktopOpenBetsItem__info-market'}
              style={
                bet.marketType === 'SESSION'
                  ? { textAlign: 'center', paddingRight: '9px', width: '28%' }
                  : {}
              }
            >
              {bet.marketType == 'SESSION' ? 'Session' : bet.selectionName}
            </span>
          ) : (
            bet.marketType === 'SESSION' && (
              <span
                className={'DesktopOpenBetsItem__info-market'}
                style={{
                  textAlign: 'center',
                  paddingRight: '9px',
                  width: '28%',
                }}
              >
                {bet.selectionName}
              </span>
            )
          )}
          <span className="DesktopOpenBetsItem__info-odds">{bet.userRate}</span>
          <span className="DesktopOpenBetsItem__info-stake">{getCurrencyFormatRoundedOff(bet.stake)}</span>
          <span className="DesktopOpenBetsItem__info-profit">{getTitle()}</span>
        </div>
        <div className="DesktopOpenBetsItem__detailedInfo">
          {setInfoDispatch && (
            <div className="DesktopOpenBetsItem__detailedInfo-title">
              <span className="DesktopOpenBetsItem__detailedInfo-title-bold">
                Ref
              </span>
              :{bet.apolloBetId}{' '}
              <span className="DesktopOpenBetsItem__detailedInfo-title-bold">
                |
              </span>
              <span className="DesktopOpenBetsItem__detailedInfo-title-bold">
                Placed
              </span>
              :{tsToDate(bet.betPlacedDate, timeSettings.timeZoneName)}
            </div>
          )}
        </div>
      </div>
    ) : (
      <>
        {stake[0].name ? (
          <div style={{ marginTop: '10px' }}>
            <span>{stake[0].name}</span>
            <div
              className="DesktopOpenBetsItem__info"
              style={{ fontSize: '9px', fontWeight: '500' }}
            >
              {stake[0].marketType === 'INNINGS_RUNS' && (
                <span
                  className={'DesktopOpenBetsItem__info-market'}
                  style={{
                    textAlign: 'center',
                    paddingRight: '9px',
                    width: '28%',
                  }}
                >
                  betline
                </span>
              )}
              <span className="DesktopOpenBetsItem__info-odds">Odds</span>
              <span className="DesktopOpenBetsItem__info-stake">Stake</span>
              <span className="DesktopOpenBetsItem__info-profit">Profit</span>
            </div>
            {stake[0].odds ? (
              <div className={'DesktopOpenBetsItem'}>
                <div className="DesktopOpenBetsItem__info">
                  <span className="DesktopOpenBetsItem__info-odds">
                    {stake[0].odds}
                  </span>
                  <span className="DesktopOpenBetsItem__info-stake">
                    {getCurrencyFormatRoundedOff(stake[0].stake)}
                  </span>
                  <span className="DesktopOpenBetsItem__info-profit">
                    {getCurrencyFormatRoundedOff(stake[0].profit)}
                  </span>
                </div>
              </div>
            ) : null}
            {stake[1].odds ? (
              <div className={'DesktopOpenBetsItem lay'}>
                <div className="DesktopOpenBetsItem__info">
                  <span className="DesktopOpenBetsItem__info-odds">
                    {stake[1].odds}
                  </span>
                  <span className="DesktopOpenBetsItem__info-stake">
                    {getCurrencyFormatRoundedOff(stake[1].stake)}
                  </span>
                  <span className="DesktopOpenBetsItem__info-profit">
                    {getCurrencyFormatRoundedOff(stake[1].profit)}
                  </span>
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          stake.map((item) => {
            return (
              <div style={{ marginTop: '10px' }}>
                <span style={{ marginTop: '10px' }}>{item.marketName}</span>
                <div
                  className="DesktopOpenBetsItem__info"
                  style={{ fontSize: '9px', fontWeight: '500' }}
                >
                  <span
                    className={'DesktopOpenBetsItem__info-market'}
                    style={{
                      textAlign: 'center',
                      paddingRight: '9px',
                      width: '28%',
                    }}
                  >
                    Runs
                  </span>
                  <span className="DesktopOpenBetsItem__info-odds">Odds</span>
                  <span className="DesktopOpenBetsItem__info-stake">Stake</span>
                  <span className="DesktopOpenBetsItem__info-profit">
                    Profit
                  </span>
                </div>
                <div
                  className={
                    item.side === 'Back'
                      ? 'DesktopOpenBetsItem'
                      : 'DesktopOpenBetsItem lay'
                  }
                >
                  <div className="DesktopOpenBetsItem__info">
                    <span
                      className={'DesktopOpenBetsItem__info-market'}
                      style={{
                        textAlign: 'center',
                        paddingRight: '9px',
                        width: '28%',
                      }}
                    >
                      {item.line}
                    </span>
                    <span className="DesktopOpenBetsItem__info-odds">
                      {getCurrencyFormatRoundedOff(item.price)}
                    </span>
                    <span className="DesktopOpenBetsItem__info-stake">
                      {getCurrencyFormatRoundedOff(item.stake)}
                    </span>
                    <span className="DesktopOpenBetsItem__info-profit">
                      {((item.size * item.price) / 100).toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>
            )
          })
        )}
      </>
    )
  ) : (
    <div>
      <div className="DesktopOpenBetsItem__unmatchedTitles">
        <div className="DesktopOpenBetsItem__unmatchedTitles-marketName">
          {bet.marketName}
        </div>
        <div
          className="DesktopOpenBetsItem__unmatchedTitles-cancel"
          onClick={() => {
            cancel(bet.apolloBetId)
            setIsRemovingBet(true)  
          }}
        >
          Cancel
        </div>
      </div>
      <div style={{ fontSize: '10px', fontWeight: '500', margin: '5px 0' }}>
        {bet.selectionName}
      </div>
      <div
        className={
          bet.side === 'Back'
            ? 'DesktopOpenBetsItem'
            : 'DesktopOpenBetsItem lay'
        }
      >
        {isRemovingBet && <div className='DesktopOpenBetsItem__removingHover'>Removing this bet...</div>}
        <div className="DesktopOpenBetsItem__row">
          <div
            className={`DesktopOpenBetsItem__col ${
              bet.mtype == 'INNINGS_RUNS' && bet.btype == 'LINE'
                ? 'DesktopOpenBetsItem__col--small'
                : ''
            }`}
          >
            Odds
            <div className="DesktopOpenBetsItem__row">
              <div className="DesktopOpenBetsItem__odd">
                <IonInput
                  type="number"
                  autocomplete="false"
                  autoCorrect="false"
                  className="DesktopOpenBetsItem__input DesktopOpenBetsItem__odd__input"
                  value={unmatchedOdd}
                  // onIonChange={(e) => actions.setPriceActual(e.detail.value)}
                  onBlur={() => changeOdd(1, 0)}
                ></IonInput>
                <div className="DesktopOpenBetsItem__changeodd-buttonsCol">
                  <button
                    className={`DesktopOpenBetsItem__changeodd DesktopOpenBetsItem__changeodd-buttonsCol-plus ${
                      bet.side === 'Back' ? '' : 'lay'
                    }`}
                    onClick={(event) => changeOddRipple(event, 1)}
                    mode="md"
                  >
                    {/* <div className="DesktopOpenBetsItem__changeodd__text">-</div> */}
                  </button>
                  <button
                    className={`DesktopOpenBetsItem__changeodd DesktopOpenBetsItem__changeodd-buttonsCol-minus ${
                      bet.side === 'Back' ? '' : 'lay'
                    }`}
                    onClick={(event) => changeOddRipple(event, -1)}
                    mode="md"
                  >
                    {/* <div className="DesktopOpenBetsItem__changeodd__text">-</div> */}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`DesktopOpenBetsItem__stake ${
              (bet.mtype == 'INNINGS_RUNS' && bet.btype == 'LINE') || true
                ? 'DesktopOpenBetsItem__col--large'
                : ''
            }`}
          >
            <div className="DesktopOpenBetsItem__row f10">
              <div style={{ marginBottom: '1px' }}>Stake</div>
            </div>
            <div className="DesktopOpenBetsItem__row">
              <div className="DesktopOpenBetsItem__stake">
                <IonInput
                  type="number"
                  inputmode="decimal"
                  className={`DesktopOpenBetsItem__input DesktopOpenBetsItem__stake__input DesktopOpenBetsItem__stake__input--rounded`}
                  style={
                    Number(bet.stake) <= 0
                      ? { border: '2px solid #B2493E' }
                      : {}
                  }
                  placeholder={`Max: ${bet.maxBet || ''}`}
                  value={+unmatchedStake}
                  onIonChange={(e) => {
                    // setUnmatchedStake(
                    //   String(e.target.value).replace(
                    //     /^([0-9]*[.,][0-9]{2})([0-9]+)$/,
                    //     '$1'
                    //   )
                    // )
                    setUnmatchedStake(e.target.value)
                  }}
                ></IonInput>
              </div>
            </div>
          </div>
          <div
            className={`DesktopOpenBetsItem__col ${
              bet.mtype == 'INNINGS_RUNS' && bet.btype == 'LINE'
                ? 'DesktopOpenBetsItem__col--small'
                : ''
            }`}
          >
            <div className="DesktopOpenBetsItem__row f10">
              <div>{bet.side === 'Back' ? 'Profit' : 'Liability'}</div>
            </div>
            <div className="DesktopOpenBetsItem__row">
              <div className="DesktopOpenBetsItem__odd">
                <div className="DesktopOpenBetsItem__odd__text">
                  {getTitle()}
                </div>
              </div>
            </div>
          </div>
          <div className="DesktopOpenBetsItem__col-delete">
            <div
              className="DesktopOpenBetsItem__col-delete-btn"
              onClick={() => {
                cancel(bet.apolloBetId)
                setIsRemovingBet(true)
                setTimeout(() => {
                  setIsRemovingBet(false)
                }, 2500)  
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DesktopOpenBetsItem

// <div className="DesktopOpenBetsItem" onClick={() => { if (redirect) history.push(`/event-page/${bet.eventTypeId}/${bet.eventId}`) }}>
//     {getTitle()}
//     <div className='DesktopOpenBetsItem__matchName'>{bet.competitionName}</div>
//     <div className="DesktopOpenBetsItem__info">
//       {!isEvent &&
//         <div>
//           <strong>{getName()}</strong>
//         </div>
//       }
//       <div>
//         <strong>{bet.marketName}</strong>
//       </div>
//       {setInfoDetailed && <div>
//         <strong>Placed</strong>
//         : {tsToDate(bet.betPlacedDate, timeSettings.timeZoneName)}
//       </div>}
//     </div>
//     {isUnmatched && <button className="DesktopOpenBetsItem__button" onClick={() => cancel(bet.apolloBetId)}>Cancel</button>}
//   </div>
