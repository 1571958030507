import httpAuth from '../../services/httpAuth'
import httpNotAuth from '../../services/httpNotAuth'

import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getMenuError, getMenuSuccess } from './menu.slice'
import { Socket } from '../../services/socket'
import { useEffect } from 'react'

export const requestMenu = (data) => async (dispatch, getState) => {
  // const checkAuth = getState().auth;
  // const cricetId = '4';
  // const tennisId = '2';
  // const soccerId = '1';

  // console.log("Check Auth2: ", checkAuth);
  // console.log("Request Menu Data: ", data);


  // if(data?.step == '1') {
    let senddata = {
      filter: {
        managers: "OSGCLUB",
        eventTypeId: { $nin: ["t9", "4321"] },
        visible: true,
        deleted: false,
        marketType: { $in: ["MATCH_ODDS", "TOURNAMENT_WINNER"] },
        "marketBook.status": { $ne: "CLOSED" },
      },
      sort: { openDate: 1 },
    };
  
    Socket.emit("get-free-home-allmarkets", senddata);
  // }

  const GetHomeMarketSuccess = async(...args) => {
    // console.log("Args: ", args);
    

    try {
      const result = args[0].map(innerArray => {
        return innerArray.reduce((acc, item) => {
          const { competitionName } = item;
          const existingIndex = acc.findIndex(obj => obj.competitionName === competitionName);
          
          if (existingIndex === -1) {
            acc.push({
              competitionName,
              data: [item]
            });
          } else {
            acc[existingIndex].data.push(item);
          }
          
          return acc;
        }, []);
      });
    
      // console.log("Result: ", result);
      dispatch(getMenuSuccess(result));
    } catch (error) {
      dispatch(getMenuError());
    }


    // try {
    //   const result = args[0].reduce((acc, curr) => {
    //     curr.forEach(item => {
    //       const { competitionName } = item;
    //       const existingIndex = acc.findIndex(obj => obj.competitionName === competitionName);
      
    //       if (existingIndex === -1) {
    //         acc.push({
    //           competitionName,
    //           data: [item]
    //         });
    //       } else {
    //         acc[existingIndex].data.push(item);
    //       }
    //     });
    //     return acc;
    //   }, []);

    //   console.log("Result: ", result);
    //   dispatch(getMenuSuccess(result));
    // }catch (e) {
    //   dispatch(getMenuError());
    //   //  const errorMsg = getErrorMessage(e)
    //   //  throw errorMsg
    // }

    // console.log("Result: ", result);

  }

  // Socket.on("get-freehomemarkets-success", GetHomeMarketSuccess)
  Socket.on("get-freehomeallmarkets-success", GetHomeMarketSuccess)
  




  
  // if(checkAuth) {
  //   try {
  //     const response = await httpAuth.post(`/series/series`, data)

  //     if (response?.data?.status) {
  //       let res = response?.data?.data
  //       dispatch(getMenuSuccess(res))
  //       // const menu = await response.data.result.filter((item, index, self) =>
  //       //   index === self.findIndex((t) => t.id === item.id)
  //       // )
  //     }
  //     return response
  //   } catch (e) {
  //     dispatch(getMenuError())
  //     //  const errorMsg = getErrorMessage(e)
  //     //  throw errorMsg
  //   }
  // } else {
  //   try {
  //     const response = await httpNotAuth.post(`/series/series`, data)
  //     if (response?.data?.status) {
  //       let res = response?.data?.data
  //       dispatch(getMenuSuccess(res))
  //       // const menu = await response.data.result.filter((item, index, self) =>
  //       //   index === self.findIndex((t) => t.id === item.id)
  //       // )
  //     }
  //     return response
  //   } catch (e) {
  //     dispatch(getMenuError())
  //     // const errorMsg = getErrorMessage(e)
  //     // throw errorMsg
  //   }
  // }
}
